import { useContext, useEffect, useState } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import { LoadingSpinner } from '@fpc/common';
import { loadingSpinnerBig } from '@fpc/common/Styles';
import { initializeStripe } from '@fpc/api/stripe/Stripe';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { GuestPayment } from './GuestPayment';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';

interface GuestCheckoutProps {
  isPreAuth: boolean;
  paymentIntentClientSecret?: string;
}

export function StripeGuestCheckout(props: GuestCheckoutProps) {
  const [stripe, updateStripe] = useState<Stripe | null>(null);
  const { transaction, errorDispatch } = useContext(StripeCheckoutContext);

  useEffect(() => {
    const platformPaymentMethods = transaction.paymentMethodTypes.filter(
      (paymentMethod) =>
        !transaction.paymentMethodTypesExcluded?.includes(paymentMethod)
    );
    initializeStripe(
      (transaction as StripeTransactionDetails).merchantAccountId,
      platformPaymentMethods
    )
      .then((result) => {
        updateStripe(result);
      })
      .catch((err) => {
        console.error('Error loading payment processor:', err);
        errorDispatch(ErrorCondition.Present);
      });
  }, []);

  let paymentForm;
  if (stripe) {
    paymentForm = (
      <GuestPayment
        isPreAuth={props.isPreAuth}
        stripe={stripe}
        paymentIntentClientSecret={props.paymentIntentClientSecret!}
      />
    );
  } else {
    paymentForm = <LoadingSpinner style={loadingSpinnerBig} />;
  }

  return <div data-testid="guest-checkout-container">{paymentForm}</div>;
}
