import { Dispatch } from 'react';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { localizeStripeError } from '@fpc/api/stripe/StripeErrors';
import i18next from '@fpc/common/i18n';
import {
  dispatchAuthorizationFailureEvent,
  DispatchEventTarget
} from '@fpc/utils/dispatchEvent';
import { STRIPE_GENERIC_DECLINE } from '@fpc/common';

export interface RejectError {
  unrecoverable?: boolean;
  declineCode?: string;
  stripeErrorCode?: string;
}

export function handleRejectError(
  reject: RejectError,
  errorMessage: string,
  errorDispatch: Dispatch<ErrorCondition>,
  eventTarget: DispatchEventTarget
): string {
  if (reject.unrecoverable) {
    dispatchAuthorizationFailureEvent(eventTarget);
    errorDispatch(ErrorCondition.Unrecoverable);
  } else if (reject.declineCode) {
    errorMessage = localizeStripeError(reject.declineCode, i18next.language);
    if (!errorMessage) {
      errorMessage = localizeStripeError(
        STRIPE_GENERIC_DECLINE,
        i18next.language
      );
    }
  } else if (reject.stripeErrorCode) {
    errorMessage =
      localizeStripeError(reject.stripeErrorCode, i18next.language) ??
      errorMessage;
  }
  return i18next.t<string>(errorMessage);
}
