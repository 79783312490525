import {
  PaymentMethodAchAcssDebit,
  PaymentMethodCard,
  PaymentMethodInfo,
  PaymentMethodResponse,
  PaymentMethodUsBank
} from '../paymentapp';
import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';
import { Psp } from '@fpc/common/Psp';
import { statusReject } from '../statusReject';
import { SEPA_PAY_METHOD } from 'checkout';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export async function getPaymentMethods(
  bearerToken: string | null,
  customerAuthToken: string | null,
  customerAuthTokenType: CustomerAuthTokenType,
  bffBaseUrl: string | null,
  merchantAccountId?: string | null,
  psp: Psp = Psp.Stripe
): Promise<{
  paymentMethods: PaymentMethodInfo[];
  pspCustomerId: string;
  fordCustomerId: string;
}> {
  return fetch(
    getRequest(
      bearerToken,
      customerAuthToken,
      customerAuthTokenType,
      bffBaseUrl,
      merchantAccountId,
      psp
    )
  )
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          const paymentMethods: PaymentMethodInfo[] = data.paymentMethods
            .filter((pm: PaymentMethodResponse) => pm.type != SEPA_PAY_METHOD)
            .map((paymentMethod: PaymentMethodResponse) => {
              return {
                isDefault: paymentMethod.isDefault,
                type: paymentMethod.type,
                paymentMethodId: paymentMethod.paymentMethodId,
                paymentMethod: getPaymentMethod(paymentMethod),
                merchantAccountId: paymentMethod.merchantAccountId
              };
            });
          return {
            paymentMethods,
            pspCustomerId: data.pspCustomerId,
            fordCustomerId: data.fordCustomerId
          };
        });
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/* To get right payment method */
export function getPaymentMethod(
  paymentMethod: PaymentMethodResponse
):
  | PaymentMethodCard
  | PaymentMethodAchAcssDebit
  | PaymentMethodUsBank
  | undefined {
  if (paymentMethod.type === 'us_bank_account') {
    return paymentMethod.usBankAccount;
  } else if (paymentMethod.type === 'acss_debit') {
    return paymentMethod.acssDebit;
  } else {
    return paymentMethod.card;
  }
}

function getRequest(
  bearerToken: string | null,
  customerAuthToken: string | null,
  customerAuthTokenType: CustomerAuthTokenType,
  bffBaseUrl: string | null,
  merchantAccountId?: string | null,
  psp: Psp = Psp.Stripe
): Request {
  let request;

  let requestUrl = bffBaseUrl
    ? `${bffBaseUrl}${process.env.CUSTOMER_APP_APIGEE_BASE_PATH}/v2/customers/payment-methods`
    : `${process.env.CUSTOMER_APP_SERVER}/v1/customers/payment-methods`;
  if (merchantAccountId) {
    requestUrl = `${requestUrl}?merchantAccountId=${merchantAccountId}`;
  }
  request = new Request(requestUrl, {
    method: 'GET',
    headers: getHeaders(
      bearerToken,
      customerAuthToken,
      customerAuthTokenType,
      bffBaseUrl,
      psp
    )
  });
  return request;
}

function getHeaders(
  bearerToken: string | null,
  customerAuthToken: string | null,
  customerAuthTokenType: CustomerAuthTokenType,
  bffBaseUrl: string | null,
  psp: Psp = Psp.Stripe
): Headers {
  let headers: Headers = HeadersBuilder();
  headers.set('x-psp', psp);

  if (
    bearerToken &&
    customerAuthTokenType &&
    customerAuthToken &&
    !bffBaseUrl
  ) {
    headers.set('x-token', customerAuthToken);
    headers.set('x-token-type', customerAuthTokenType);
    headers.set('Authorization', `Bearer ${bearerToken}`);
  }
  return headers;
}
