/** Test if web component input attributes have a value. Some
 *  input situations result in getting the string 'undefined',
 *  so we need more complicated logic than just checking if truthy,
 *  and it's repeated often enough that it's worth making a function. */
export function attributeHasValue(
  input: string | null | undefined
): input is string {
  if (input === 'undefined') {
    return false;
  } else {
    return !!input;
  }
}
