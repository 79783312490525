import { statusReject } from '../statusReject';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export function detachPaymentMethod(
  bearerToken: string | null,
  paymentMethodId: string,
  bffBaseUrl: string | null,
  merchantAccountId?: string
): Promise<void> {
  const request = getRequest(
    bearerToken,
    paymentMethodId,
    bffBaseUrl,
    merchantAccountId === '' ? undefined : merchantAccountId
  );
  return fetch(request)
    .then((res) => {
      if (res.ok) {
        return;
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      console.warn('Error detaching payment method:', err);
      return Promise.reject(err);
    });
}

function getRequest(
  bearerToken: string | null,
  paymentMethodId: string,
  bffBaseUrl: string | null,
  merchantAccountId?: string
): Request {
  let request;

  const requestUrl = bffBaseUrl
    ? `${bffBaseUrl}${process.env.CUSTOMER_APP_APIGEE_BASE_PATH}/v2/customers/payment-methods/detach`
    : `${process.env.CUSTOMER_APP_SERVER}/v1/customers/payment-methods/detach`;

  request = new Request(`${requestUrl}`, {
    method: 'POST',
    headers: getHeaders(bearerToken, bffBaseUrl),
    body: JSON.stringify({ paymentMethodId, merchantAccountId })
  });
  return request;
}

function getHeaders(
  bearerToken: string | null,
  bffBaseUrl: string | null
): Headers {
  let headers: Headers = HeadersBuilder();
  if (bearerToken && !bffBaseUrl) {
    headers.set('Authorization', `Bearer ${bearerToken}`);
  }
  return headers;
}
