import { Dispatch, useEffect, useRef } from 'react';
import { errorWaitTime } from '@fpc/common';
import { ErrorCondition } from '@fpc/common/ErrorHandler';

export function useStripeLoadTimeout(
  errorDispatch: Dispatch<ErrorCondition>,
  errorCallback?: Function
) {
  let errorTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    errorTimeoutRef.current = setTimeout(() => {
      errorDispatch(ErrorCondition.Present);
      console.error('Error loading payment processor');
      if (errorCallback) {
        errorCallback();
      }
    }, errorWaitTime);
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, []);

  return errorTimeoutRef;
}
