import en from './localized/en';
import { StripeError } from '@stripe/stripe-js';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '@fpc/common';
import cs from './localized/cs';
import da from './localized/da';
import de from './localized/de';
import el from './localized/el';
import enGB from './localized/en-GB';
import es from './localized/es';
import fi from './localized/fi';
import fr from './localized/fr';
import frCA from './localized/fr-CA';
import hu from './localized/hu';
import it from './localized/it';
import nl from './localized/nl';
import noNO from './localized/no-NO';
import pl from './localized/pl';
import pt from './localized/pt';
import ro from './localized/ro';
import sv from './localized/sv';
import th from './localized/th';

// NOTE:  if adding additional localizations, update the .json file to a
// .ts file following the pattern of en.ts and fr-CA.ts and add to the
// translations object.

interface Translations {
  [key: string]: typeof en;
}

const translations: Translations = {
  en,
  cs,
  da,
  de,
  el,
  'en-GB': enGB,
  es,
  fi,
  fr,
  'fr-CA': frCA,
  hu,
  it,
  nl,
  'no-NO': noNO,
  pl,
  pt,
  ro,
  sv,
  th
};

export function localizeStripeError(
  declineCode: string,
  locale: string
): string {
  let codes: any;
  if (Object.keys(translations).includes(locale)) {
    codes = translations[locale];
  } else if (Object.keys(translations).includes(locale.slice(0, 2))) {
    codes = translations[locale.slice(0, 2)];
  } else {
    codes = translations.en;
  }
  return (
    codes[`errors.declines.${declineCode}`] ||
    codes[`errors.code.${declineCode}`]
  );
}

// This needs to be a function and not a constant because otherwise it will
// capture the value of the translation key when first initialized instead of
// using the current selected language.
export function getGeneralStripeError(): StripeError {
  return {
    message: i18next.t(translationKeys.common.technicalErrorPayment),
    type: 'api_connection_error'
  };
}

export function instanceOfStripeError(
  object: any
): object is StripeErrorWithRequestInfo {
  return 'type' in object;
}

interface StripeErrorWithRequestInfo extends StripeError {
  request_log_url?: string;
  shouldRetry?: boolean;
}
