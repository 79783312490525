export default {
  'errors.code.expired_card': 'A kártyája lejárt.',
  'errors.code.incorrect_cvc': 'A kártyája biztonsági kódja helytelen.',
  'errors.code.incorrect_number': 'A kártyaszáma helytelen.',
  'errors.code.incorrect_zip': 'A kártyaszáma és az irányítószáma nem egyezik.',
  'errors.code.invalid_cvc': 'A kártyája biztonsági kódja érvénytelen.',
  'errors.code.invalid_expiry_month': 'A kártyája lejárati dátuma érvénytelen.',
  'errors.code.invalid_expiry_month_past': 'A kártyája lejárati dátuma elmúlt.',
  'errors.code.invalid_expiry_year': 'A kártyája lejárati éve érvénytelen.',
  'errors.code.invalid_expiry_year_past': 'A kártyája lejárati éve elmúlt.',
  'errors.code.invalid_number': 'A kártyaszáma érvénytelen.',
  'errors.code.processing_error':
    'Hiba történt a kártyája feldolgozásakor. Kis idő elteltével próbálkozzon újból.',
  'errors.declines.call_issuer':
    'A kártyáját elutasítottuk. Részletekért felhívhatja a bankját.',
  'errors.declines.card_not_supported': 'A kártyája nem támogatott.',
  'errors.declines.card_velocity_exceeded':
    'A kártyáját elutasítottuk, mivel túl gyakran történt ismétlődő próbálkozás.',
  'errors.declines.currency_not_supported':
    'A kártyája nem támogatott ehhez a pénznemhez.',
  'errors.declines.generic_decline': 'A kártyáját elutasítottuk.',
  'errors.declines.incorrect_pin': 'Helytelen PIN-kód.',
  'errors.declines.insufficient_funds': 'A kártyáján nincs elegendő fedezet.',
  'errors.declines.invalid_account': 'Érvénytelen fiók.',
  'errors.declines.invalid_amount': 'Érvénytelen összeg.',
  'errors.declines.not_permitted':
    'A kártyatag nincs regisztrálva/nem engedélyezett',
  'errors.declines.pin_try_exceeded':
    'Túllépte a PIN-kód megadásához engedélyezett próbálkozások számát.',
  'errors.declines.transaction_not_allowed':
    'A kártyája nem támogatja az ilyen típusú vásárlást.',
  'errors.code.processing_error_intransient':
    'Hiba történt. Kérjük, próbálkozzon újból később.',
  'errors.declines.generic_decline_link': 'Fizetési módja elutasításra került.',
  'errors.declines.generic_decline_non_cards': 'Fizetése elutasításra került.'
};
