import { enUS, TRANSLATION_MISSING } from './en-US';

export const roRO: typeof enUS = {
  common: {
    default: 'implicit',
    endingIn: 'expir\u0103 \u00een',
    close: '\u00cenchide',
    makeDefaultCard: 'Seteaz\u0103 cardul ca implicit',
    makeDefaultInfoHover:
      'Acesta va deveni automat cardul t\u0103u implicit, deoarece este primul card din portofelul t\u0103u.',
    processing: '\u00cen curs de procesare\u2026',
    saveCardCheck: 'Salveaz\u0103 cardul pentru tranzac\u021bii viitoare',
    saveCardSuccess: 'Cardul a fost ad\u0103ugat \u00een portofelul Ford.com.',
    technicalErrorPayment:
      'Ne pare r\u0103u! A ap\u0103rut o problem\u0103 tehnic\u0103, iar plata ta nu a fost procesat\u0103. Te rug\u0103m s\u0103 \u00eencerci din nou.',
    timeoutSorry: 'Ne pare r\u0103u\u2026',
    timeoutError:
      'Momentan \u00eent\u00e2mpin\u0103m dificult\u0103\u021bi tehnice.',
    timeoutTryAgain: '\u00cencearc\u0103 din nou',
    savePaymentMethodCheck:
      'Salveaz\u0103 modalitatea de plat\u0103 pentru tranzac\u021bii viitoare',
    makeDefaultPaymentMethod:
      'Stabile\u0219te ca modalitate de plat\u0103 implicit\u0103',
    savePaymentMethodButton: 'Salva\u021bi metoda de plat\u0103',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Aceasta va deveni automat metoda dvs. de plat\u0103 implicit\u0103, deoarece este prima metod\u0103 de plat\u0103 din portofel.',
    savePaymentMethodSuccess:
      'Metoda de plat\u0103 a fost ad\u0103ugat\u0103 \u00een Wallet.'
  },
  checkout: {
    payButton: 'Pl\u0103te\u0219te {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Ne pare r\u0103u! Am \u00eent\u00e2mpinat o problem\u0103 tehnic\u0103, iar plata ta nu a fost procesat\u0103. Te rug\u0103m s\u0103 re\u00eencarci pagina \u0219i s\u0103 \u00eencerci din nou. ',
    technicalErrorPaymentDetachFail:
      'Ne pare r\u0103u! A ap\u0103rut o problem\u0103 tehnic\u0103, iar plata ta nu a fost procesat\u0103. Cardul t\u0103u a fost salvat \u00een portofel. Te rug\u0103m s\u0103 \u00eencerci din nou.',
    tosTextBefore:
      'D\u00e2nd clic pe butonul \u201ePl\u0103te\u0219te\u201d de mai jos, e\u0219ti de acord cu ',
    tosLinkText:
      'Termeni \u0219i condi\u021bii de plat\u0103 \u0219i Politica de confiden\u021bialitate',
    tosTextAfter: '.',
    useNewCard: 'Utilizeaz\u0103 un card nou',
    useNewCardDropdownText: 'Adaug\u0103 card nou',
    useNewCardTextBefore: 'Finalizeaz\u0103 plata folosind cardul salvat sau ',
    useNewCardLinkText: 'utilizeaz\u0103 noua modalitate de plat\u0103',
    useNewCardTextAfter: '.',
    cardSelect: 'Selectare Card de credit',
    checkoutFaster: 'Finalizeaz\u0103 plata mai repede cu FordPay.',
    checkout: 'Finalizarea pl\u0103\u021bii',
    nameOnCard: 'Numele de pe card',
    fullName: 'Numele complet',
    cardNumber: 'Num\u0103r card',
    expirationDate: 'Expir\u0103 la data',
    securityCode: 'Cod de securitate',
    invalidCCNMessage: 'Num\u0103rul cardului nu este valabil.',
    invalidCVVMessage: 'Codul de securitate nu este valabil.',
    invalidExpDateMessage: 'Data expir\u0103rii nu este valabil\u0103.',
    emptyCCNMessage: 'Nu ai introdus num\u0103rul cardului.',
    emptyCVVMessage: 'Nu ai introdus codul de securitate.',
    emptyExpDateMessage: 'Nu ai introdus data expir\u0103rii.',
    unsupportedCardMessage:
      'Num\u0103rul cardului t\u0103u nu este acceptat. Te rug\u0103m s\u0103 introduci un card VISA, MasterCard sau AMEX.',
    paymentNotProcessed: 'Plata ta nu a putut fi procesat\u0103',
    redirectStatusFailure:
      'Plata ta nu a putut fi procesat\u0103. Te rug\u0103m s\u0103 \u00eencerci din nou.\u00a0',
    continue: 'Continua {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'D\u00e2nd clic pe butonul \u201eContinua\u201d de mai jos, e\u0219ti de acord cu ',
    authCheckoutStatement:
      'Verifica\u021bi cu cardul salvat, utiliza\u021bi o nou\u0103 metod\u0103 de plat\u0103 sau utiliza\u021bi Pl\u0103ti\u021bi prin banc\u0103.',
    useNewCardTextBeforePayByBank: 'Verifica\u021bi cu cardul salvat, ',
    usePayByBank: ', sau utiliza\u021bi ',
    payByBankLinkText: 'Pl\u0103ti\u021bi prin banc\u0103.',
    payByBankHeader: 'Utiliza\u021bi Pl\u0103ti\u021bi prin banc\u0103.',
    use: 'Utiliza\u021bi ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Utilizeaz\u0103 noua modalitate de plat\u0103',
    useNewPaymentDropdownText: 'utilizeaz\u0103 noua modalitate de plat\u0103',
    useNewPaymentTextBefore:
      'Verifica\u021bi cu metoda de plat\u0103 salvat\u0103 sau ',
    useNewPaymentLinkText: 'utilizeaz\u0103 noua modalitate de plat\u0103',
    useNewPaymentTextBeforePayByBank:
      'Verifica\u021bi cu metoda de plat\u0103 salvat\u0103, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      '\u00cen prezent nu ave\u021bi niciun mod de plat\u0103 \u00een portofelul dvs. \u00cencepe\u021bi prin ad\u0103ugarea primului mod de plat\u0103 \u00een portofelul dvs.',
    addPaymentMethod: 'Adaug\u0103 metod\u0103 de plat\u0103',
    makeDefault: 'Seteaz\u0103 ca implicit',
    changeDefaultPMSuccess:
      'Metoda dvs. de plat\u0103 implicit\u0103 a fost schimbat\u0103.',
    makeDefaultSuccessPM:
      'Metoda de plat\u0103 ad\u0103ugat\u0103 ca implicit\u0103.',
    makeDefaultErrorPM:
      'Eroare la setarea metodei de plat\u0103 ca implicit\u0103.',
    removePMSuccess:
      'Metoda de plat\u0103 eliminat\u0103 din portofelul Ford.com',
    removePMError: 'Eroare la eliminarea metodei de plat\u0103.',
    removePM: 'Elimina\u021bi metoda de plat\u0103',
    removePMQuestion: 'Elimina\u021bi metoda de plat\u0103?',
    removePMText:
      'Sunte\u021bi sigur c\u0103 dori\u021bi s\u0103 elimina\u021bi metoda de plat\u0103 {{cardNumber}} din portofelul dvs.? Toate abonamentele active asociate cu aceast\u0103 metod\u0103 de plat\u0103 vor e\u0219ua dac\u0103 metoda de plat\u0103 este eliminat\u0103.',
    defaultCard: 'Card implicit',
    defaultPM: 'Metoda de plat\u0103 implicit\u0103',
    defaultCardQuestion: 'Card implicit?',
    defaultPMQuestion: 'Metoda de plat\u0103 implicit\u0103?',
    defaultCardText:
      'Sigur dore\u0219ti s\u0103 \u00ee\u021bi schimbi cardul implicit cu {{cardNumber}}?',
    defaultPMText:
      'Sunte\u021bi sigur c\u0103 dori\u021bi s\u0103 schimba\u021bi metoda dvs. de plat\u0103 implicit\u0103 \u00een {{cardNumber}}?',
    navigationLabel: 'Navigare pentru gestionarea contului',
    removeDefaultPMSuccess:
      'Metoda de plat\u0103 implicit\u0103 eliminat\u0103 din portofelul Ford.com',
    removeDefaultCardError: 'Eroare la eliminarea cardului implicit.',
    removeDefaultPMError:
      'Eroare la eliminarea metodei de plat\u0103 implicit\u0103.\u00a0',
    removeDefaultCard: 'Elimin\u0103 cardul t\u0103u implicit',
    removeDefaultPM:
      'Eroare la eliminarea metodei de plat\u0103 implicit\u0103.\u00a0',
    removeDefaultPMText:
      'Metoda de plat\u0103 {{cardNumber}} este metoda dvs. de plat\u0103 implicit\u0103. V\u0103 rug\u0103m s\u0103 selecta\u021bi o nou\u0103 metod\u0103 de plat\u0103 implicit\u0103 mai jos. Toate abonamentele active asociate cu aceast\u0103 metod\u0103 de plat\u0103 vor e\u0219ua dac\u0103 metoda de plat\u0103 este eliminat\u0103.',
    removeDefaultPMButton:
      'Elimina\u021bi metoda de plat\u0103 \u0219i actualiza\u021bi implicit',
    removeDefaultSelectPM:
      'Selecta\u021bi o nou\u0103 metod\u0103 de plat\u0103 implicit\u0103',
    title: 'Portofel',
    expiration: 'Exp.\u00a0{{date}}',
    expires: 'Expir\u0103 \u00een {{date}}',
    stripeDefaultError:
      'A ap\u0103rut o problem\u0103 tehnic\u0103. Te rug\u0103m s\u0103 \u00eencerci din nou.\u00a0',
    paymentMethod: 'Modalitate de plat\u0103',
    tosTextBefore:
      'D\u00e2nd clic pe butonul \u201eSalveaz\u0103 cardul\u201d de mai jos, e\u0219ti de acord cu ',
    tosTextBeforePM:
      'Prin ap\u0103sarea butonului "Salva\u021bi metoda de plat\u0103" de mai jos, sunte\u021bi de acord cu',
    tosLinkText:
      '\nTermeni \u0219i condi\u021bii de plat\u0103 \u0219i Politica de confiden\u021bialitate',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Toate',
    amount: 'Sum\u0103',
    date: 'Dat\u0103',
    description: 'Descriere',
    displayingAll: 'Se afi\u0219eaz\u0103 toate tranzac\u021biile.',
    displayingDays:
      'Se afi\u0219eaz\u0103 tranzac\u021biile din ultimele {{numberOfDays}} (de) zile.',
    displayingYear:
      'Se afi\u0219eaz\u0103 tranzac\u021biile din anul {{year}}.',
    filter: 'Ultimele {{numberOfDays}} de zile',
    loadAll: '\u00cencarc\u0103 toate',
    loadMore: 'Mai \u00eencarc\u0103 {{x}}',
    noTransactions: 'Nicio tranzac\u021bie de afi\u0219at.',
    paymentMethod: 'Metod\u0103 de plat\u0103',
    refund: 'Ramburs',
    searchBar: 'Caut\u0103 dup\u0103 descriere',
    timeFilter:
      'Selecteaz\u0103 intervalul de timp pentru istoricul tranzac\u021biilor',
    title: 'Tranzac\u021bii',
    pending: 'In asteptarea'
  },
  mit: {
    confirmPurchase: 'Confirma achizi\u021bia',
    paymentSuccessful: 'Plat\u0103 efectuat\u0103 cu succes',
    saveCardAlert:
      'Ca s\u0103 utilizezi acest card pentru pl\u0103\u021bi viitoare, trebuie s\u0103 \u00eel salvezi \u00een portofelul t\u0103u Ford.com.',
    title: 'Selectare metod\u0103 de plat\u0103',
    tosTextBefore:
      'D\u00e2nd clic pe butonul {{paymentButtonText}} de mai jos, e\u0219ti de acord cu ',
    tosLinkText:
      'Termeni \u0219i condi\u021bii de plat\u0103 \u0219i Politica de confiden\u021bialitate',
    tosTextAfter: '.',
    useNewCard: 'Utilizeaz\u0103 un card nou',
    useNewCardDropDownItem: 'Utilizeaz\u0103 un card nou',
    useNewCardTextBefore: 'Selecteaz\u0103 cardul salvat sau ',
    useNewCardLinkText: 'utilizeaz\u0103 un card nou',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Utilizeaz\u0103 noua modalitate de plat\u0103',
    useNewPaymentDropdownText: 'utilizeaz\u0103 noua modalitate de plat\u0103',
    useNewPaymentTextBefore:
      'Verifica\u021bi cu metoda de plat\u0103 salvat\u0103 sau ',
    useNewPaymentLinkText: 'utilizeaz\u0103 noua modalitate de plat\u0103',
    successful: 'De succes',
    savePaymentMethodAlert:
      'Pentru a utiliza aceast\u0103 metod\u0103 de plat\u0103 pentru pl\u0103\u021bi viitoare, trebuie s\u0103 fie salvat\u0103 \u00een portofel.'
  }
};
