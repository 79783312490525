export enum ErrorCondition {
  Present,
  Unrecoverable,
  Reset
}

export interface ErrorState {
  present: boolean;
  unrecoverable: boolean;
}

export const initialErrorState: ErrorState = {
  present: false,
  unrecoverable: false
};

export const errorReducer = (
  state: ErrorState,
  type: ErrorCondition
): ErrorState => {
  switch (type) {
    case ErrorCondition.Present:
      return { ...state, present: true };
    case ErrorCondition.Unrecoverable:
      return { present: true, unrecoverable: true };
    case ErrorCondition.Reset:
      return { present: false, unrecoverable: false };
  }
};
