import { BankIconTile } from './BankIconTile';
import { PaymentMethodPumaBank } from '@fpc/api/paymentapp';
import styled from 'styled-components/macro';
import {
  Button,
  DropDown,
  DropDownItem,
  LoadingSpinner,
  mobileWidth,
  ModalDisplay,
  translationKeys
} from '@fpc/common';
import { useContext, useEffect, useState } from 'react';
import {
  CHECKOUT_ELEMENT,
  PumaCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import { initiatePumaBankPayment } from '@fpc/api/paymentapp/InitiatePumaBankPayment';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import i18next from '@fpc/common/i18n';
import { loadingSpinnerBig, Style } from '@fpc/common/Styles';
import {
  displayedBankPaymentMethodsGB,
  displayedBankPaymentMethodsGBPROD
} from './displayedBankPaymentMethods';
import { JPMorganDisclaimerGB } from './disclaimers/JPMorganDisclaimerGB';
import { isNotProd } from '../../flags';

interface PumaGuestCheckoutProps {
  banks: PaymentMethodPumaBank[];
}

const DISCLAIMER_TITLE = 'Disclaimer';

export function PumaGuestCheckout(props: PumaGuestCheckoutProps) {
  const { tokens, country, redirectUrl, errorDispatch } =
    useContext(PumaCheckoutContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMobileScreenSize] = useState<boolean>(window.innerWidth < 580);
  const dropDownItems: DropDownItem<PaymentMethodPumaBank>[] = props.banks.map(
    (bank) => {
      return {
        label: bank.name,
        ariaLabel: bank.name,
        value: bank
      };
    }
  );
  dropDownItems.splice(0, 0, {
    label: '',
    ariaLabel: '',
    value: {
      name: '',
      providerCode: ''
    },
    isDisabled: true
  });
  const [openDisclaimerModal, setOpenDisclaimerModal] =
    useState<boolean>(false);
  const [selectedBank, setSelectedBank] =
    useState<PaymentMethodPumaBank | null>(null);
  function handleBankIconClick(selectedBank: PaymentMethodPumaBank) {
    setSelectedBank(selectedBank);
  }

  useEffect(() => {
    if (selectedBank) {
      handleShowModal(true);
    }
  }, [selectedBank]);

  function handleShowModal(showModal: boolean) {
    setOpenDisclaimerModal(showModal);
    if (!showModal) {
      setSelectedBank(null);
    }
  }
  function handleConfirmPaymentClick() {
    setIsLoading(true);
    setOpenDisclaimerModal(false);
    initiatePumaBankPayment(
      country,
      tokens.paymentInfoToken,
      selectedBank!,
      redirectUrl
    )
      .then((result) => {
        window.location.href = result.bankSiteRedirectUrl;
      })
      .catch(async (reject) => {
        if (reject.unrecoverable) {
          errorDispatch(ErrorCondition.Unrecoverable);
          dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
        } else if (reject.pspErrorCode) {
          setErrorMessage(
            i18next.t<string>(translationKeys.checkout.paymentNotProcessed)
          );
        } else {
          setErrorMessage(
            i18next.t<string>(translationKeys.common.technicalErrorPayment)
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const displayBanks = isNotProd()
    ? displayedBankPaymentMethodsGB
    : displayedBankPaymentMethodsGBPROD;
  const filteredBankList = props.banks.filter((bank) =>
    displayBanks.includes(bank.name)
  );

  return (
    <div
      data-testid="puma-guest-checkout"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {isLoading ? (
        <LoadingSpinner style={loadingSpinnerBig} />
      ) : (
        <>
          <h2>Please choose your bank</h2>
          <DropDown
            ariaLabel={'bank-dropdown'}
            items={dropDownItems}
            valueCallback={setSelectedBank}
          />
          <PumaBankPaymentMethodContainer>
            {filteredBankList.map((bank) => {
              return (
                <BankIconTile
                  key={bank.providerCode}
                  bank={bank}
                  onClick={() => handleBankIconClick(bank)}
                />
              );
            })}
          </PumaBankPaymentMethodContainer>
          <div
            role={'alert'}
            style={{ color: Style.color.error, paddingTop: '0.5em' }}
          >
            {errorMessage}
          </div>
          <div id={'disclaimer-modal'} />
          <ModalDisplay
            open={openDisclaimerModal}
            setOpen={handleShowModal}
            title={DISCLAIMER_TITLE}
            width={'1000px'}
            divId={'disclaimer-modal'}
            centerAlignTitle={true}
            titleFontSize={1.7}
            isMobile={isMobileScreenSize}
          >
            <JPMorganDisclaimerGB />
            <Button
              id="submit"
              data-testid={'puma-pay-button'}
              onClick={handleConfirmPaymentClick}
              style={{ marginTop: '1em' }}
            >
              Continue
            </Button>
          </ModalDisplay>
        </>
      )}
    </div>
  );
}

export const PumaBankPaymentMethodContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75em;
    padding: 2em 1.5em 2em 1.5em;
    justify-content: center;
    @media (max-width: ${mobileWidth}px) {
      gap: 1em;
    }
  }

  && > :hover,
  && > :focus {
    scale: 1.05;
    transition: 0.25s linear;
  }
`;
