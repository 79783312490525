import { ReactElement, useEffect, useMemo, useReducer, useState } from 'react';
import { CheckoutLoadWrapper } from '../CheckoutLoadWrapper';
import {
  ErrorCondition,
  errorReducer,
  initialErrorState
} from '@fpc/common/ErrorHandler';
import {
  CommonCheckoutProps,
  logMissingParamError,
  validateCheckout
} from '../CheckoutCommon';
import { isNotProd } from '../../flags';
import { CheckoutLoadError } from '../CheckoutLoadError';
import { TechnicalError } from '@fpc/common';
import { Psp } from '@fpc/common/Psp';
import { PumaGuestCheckout } from './PumaGuestCheckout';
import {
  CHECKOUT_ELEMENT,
  PumaCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import { getCountryFromLocale } from '@fpc/utils/getCountryFromLocale';
import { getPumaBanksByCountry } from '@fpc/api/paymentapp/GetBanks';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import { PaymentMethodPumaBank } from '@fpc/api/paymentapp';

export function PumaCheckoutWrapper(props: CommonCheckoutProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, errorDispatch] = useReducer(errorReducer, initialErrorState);
  const { isValidCheckout } = validateCheckout(props);
  const [banks, setBanks] = useState<PaymentMethodPumaBank[]>([]);
  const pumaContextMemo = useMemo(
    () => ({
      tokens: props,
      redirectUrl: props.redirectUrl,
      redirectStatus: '',
      errorDispatch,
      country: getCountryFromLocale(props.language!),
      transaction: props.transactionDetails
    }),
    []
  );

  useEffect(() => {
    errorDispatch(ErrorCondition.Reset);
    handleGetAllBanks();
  }, [props]);

  function handleGetAllBanks() {
    getPumaBanksByCountry(
      getCountryFromLocale(props.language!),
      props.paymentInfoToken
    )
      .then((result) => {
        // Prevents errors if user clicks off tab before payment methods are retrieved.
        setBanks(result.banks);
        errorDispatch(ErrorCondition.Reset);
      })
      .catch((err) => {
        if (err.unrecoverable) {
          dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
          errorDispatch(ErrorCondition.Unrecoverable);
        }
        console.warn('Error retrieving Puma bank list:', err);
        errorDispatch(ErrorCondition.Present);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function resetFromError() {
    errorDispatch(ErrorCondition.Reset);
    setIsLoading(true);
  }

  function getCheckout(): ReactElement {
    if (isValidCheckout) {
      return (
        <PumaCheckoutContext.Provider value={pumaContextMemo}>
          <PumaGuestCheckout banks={banks} />
        </PumaCheckoutContext.Provider>
      );
    } else {
      logMissingParamError(
        props.paymentInfoToken,
        props.redirectUrl,
        props.bearerToken!,
        Psp.Puma
      );
      if (isNotProd()) {
        return (
          <CheckoutLoadError
            redirectUrl={props.redirectUrl}
            bearerToken={null}
            paymentInfoToken={props.paymentInfoToken}
            customerAuthToken={null}
            customerAuthTokenType={props.customerAuthTokenType}
            isPreAuth={false}
            bffBaseUrl={props.bffBaseUrl}
            isValidAuthParams={true}
            language={props.language}
          />
        );
      } else {
        return (
          <TechnicalError
            resetFromError={resetFromError}
            unrecoverable={true}
          />
        );
      }
    }
  }

  return (
    <CheckoutLoadWrapper
      error={error}
      resetFromError={resetFromError}
      loading={isLoading}
      checkoutComponent={getCheckout()}
    />
  );
}
