import { PaymentMethodInfo } from '@fpc/api/paymentapp';
import { BlueSnapPaymentForm } from './BlueSnapPaymentForm';
import { makeBluesnapAuthPayment } from '@fpc/api/paymentapp/MakeBluesnapAuthPayment';
import { useContext, useState } from 'react';
import {
  BluesnapCheckoutContext,
  CHECKOUT_ELEMENT
} from '@fpc/reactutils/checkoutContextProvider';
import { createThreeDSObj } from '@fpc/utils/threeDS';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '@fpc/common';

interface BlueSnapAuthProps {
  bluesnapPaymentToken: string;
  cards: PaymentMethodInfo[];
  bluesnapCustomerId: number;
  fordCustomerId: string;
}

export function BlueSnapAuthCheckout(props: BlueSnapAuthProps) {
  const { tokens, redirectUrl, errorDispatch, bffBaseUrl, transaction } =
    useContext(BluesnapCheckoutContext);
  const threeDSecureObj = createThreeDSObj(transaction);
  const [errorMsg, setErrorMsg] = useState('');
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const hasSavedCards: boolean = props.cards.length > 0;

  function onSubmitPayment() {
    makeBluesnapAuthPayment(
      tokens.paymentInfoToken,
      props.bluesnapPaymentToken,
      props.fordCustomerId,
      props.bluesnapCustomerId,
      tokens.bearerToken,
      bffBaseUrl ? bffBaseUrl : ''
    )
      .then((res) => {
        const transactionId = res.transactionId;
        window.location.href = `${redirectUrl}?transactionId=${transactionId}`;
      })
      .catch(async (reject) => {
        if (reject.unrecoverable) {
          errorDispatch(ErrorCondition.Unrecoverable);
          dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
        } else if (reject.pspErrorCode) {
          setErrorMsg(
            i18next.t<string>(translationKeys.checkout.paymentNotProcessed)
          );
        } else {
          setErrorMsg(
            i18next.t<string>(translationKeys.common.technicalErrorPayment)
          );
        }
      });
  }

  let authCheckoutElement = <></>;
  if (!hasSavedCards) {
    authCheckoutElement = (
      <BlueSnapPaymentForm
        bluesnapPaymentToken={props.bluesnapPaymentToken}
        bluesnapThreeDS={threeDSecureObj}
        onSubmitPayment={onSubmitPayment}
        guestCheckout={false}
        fordCustomerId={props.fordCustomerId}
        bluesnapCustomerId={props.bluesnapCustomerId}
        setErrorMessage={setErrorMsg}
        errorMessage={errorMsg}
        isSubmitDisabled={isSubmitDisabled}
        setSubmitDisabled={setSubmitDisabled}
      />
    );
  }

  return (
    <div data-testid="bluesnap-auth-checkout-container">
      {authCheckoutElement}
    </div>
  );
}
