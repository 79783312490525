import { ReactComponent as InfoDotIcon } from '@fpc/common/icons/information-icon-24x24-primary.svg';
import styled from 'styled-components/macro';
import { CSSProperties } from 'react';
import { Style } from '../Styles';
import { Tooltip, TooltipDirection } from './Tooltip';

const StyleDot = styled(InfoDotIcon)<{ color: string }>`
  && {
    height: 1.2em;
  }
  && g {
    fill: ${(props) => props.color};
  }
`;

interface InfoDotProps {
  style?: CSSProperties;
  toolTip?: string;
  forDisabled: boolean;
  tooltipDirection?: TooltipDirection;
}

export function InfoDot(props: InfoDotProps) {
  const color = props.forDisabled ? Style.color.disabled : Style.color.primary;

  return (
    <div style={props.style} data-testid={'info-dot'}>
      <Tooltip
        direction={props.tooltipDirection ?? 'top'}
        content={props.toolTip ?? ''}
      >
        <StyleDot color={color} />
      </Tooltip>
    </div>
  );
}
