export default {
  'errors.code.expired_card': 'Karta straciła ważność.',
  'errors.code.incorrect_cvc': 'Kod zabezpieczający karty jest niepoprawny.',
  'errors.code.incorrect_number': 'Numer karty jest niepoprawny.',
  'errors.code.incorrect_zip': 'Numer karty i kod pocztowy są niezgodne.',
  'errors.code.invalid_cvc': 'Kod zabezpieczający karty jest nieprawidłowy.',
  'errors.code.invalid_expiry_month': 'Data ważności karty jest nieprawidłowa.',
  'errors.code.invalid_expiry_month_past': 'Data ważności karty już minęła.',
  'errors.code.invalid_expiry_year': 'Rok ważności karty jest nieprawidłowy.',
  'errors.code.invalid_expiry_year_past': 'Rok ważności karty już minął.',
  'errors.code.invalid_number': 'Numer karty jest nieprawidłowy.',
  'errors.code.processing_error':
    'Podczas przetwarzania danych karty wystąpił błąd. Spróbuj ponownie za jakiś czas.',
  'errors.declines.call_issuer':
    'Karta została odrzucona. Możesz zadzwonić do banku, aby uzyskać szczegółowe informacje.',
  'errors.declines.card_not_supported': 'Ta karta nie jest obsługiwana.',
  'errors.declines.card_velocity_exceeded':
    'W wyniku zbyt często ponawianych prób karta została odrzucona.',
  'errors.declines.currency_not_supported':
    'Ta karta nie jest obsługiwana w przypadku płatności tą walutą.',
  'errors.declines.generic_decline': 'Karta została odrzucona.',
  'errors.declines.incorrect_pin': 'Nieprawidłowy kod PIN.',
  'errors.declines.insufficient_funds': 'Za mało środków na karcie.',
  'errors.declines.invalid_account': 'Nieprawidłowe konto.',
  'errors.declines.invalid_amount': 'Nieprawidłowa kwota.',
  'errors.declines.not_permitted':
    'Niezarejestrowany/nieuprawniony użytkownik karty',
  'errors.declines.pin_try_exceeded':
    'Przekroczono dopuszczalną liczbę prób podania kodu PIN.',
  'errors.declines.transaction_not_allowed':
    'Karta nie umożliwia zakupów tego typu.',
  'errors.code.processing_error_intransient':
    'Coś poszło nie tak. Spróbuj ponownie później.',
  'errors.declines.generic_decline_link':
    'Twoja metoda płatności została odrzucona.',
  'errors.declines.generic_decline_non_cards':
    'Twoja płatność została odrzucona.'
};
