import { Dispatch, useEffect, useState } from 'react';
import { initializeStripe } from '@fpc/api/stripe/Stripe';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { Stripe, StripeElements } from '@stripe/stripe-js';

export function useInitializeStripe(
  errorDispatch: Dispatch<ErrorCondition>,
  errorCallback?: (err: unknown) => void,
  merchantAccountId?: string
) {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [elements, setElements] = useState<StripeElements | null>(null);

  useEffect(() => {
    if (!stripe) {
      initializeStripe(merchantAccountId)
        .then((result) => setStripe(result))
        .catch((err) => {
          console.warn('Error loading Stripe: ', err);
          errorDispatch(ErrorCondition.Present);
          if (errorCallback) {
            errorCallback(err);
          }
        });
    }
  }, [errorDispatch, stripe]);

  useEffect(() => {
    if (stripe !== null && elements == null) {
      setElements(stripe.elements());
    }
  }, [stripe, elements]);

  return { stripe, elements };
}
