import {
  PaymentMethodAchAcssDebit,
  PaymentMethodCard,
  PaymentMethodInfo,
  PaymentMethodUsBank
} from '@fpc/api/paymentapp';
import { DropDownItem } from '../components';
import { makeJSXFromPaymentMethod } from './makeJSXFromPaymentMethod';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '../i18n/en-US';

export function paymentMethodSelect(
  paymentMethods: PaymentMethodInfo[]
): DropDownItem<PaymentMethodInfo>[] {
  return paymentMethods.map((paymentMethodInfo) => {
    let lastFourDigits = '';
    let paymentMethodType = '';
    let bankAccountName = '';

    if (paymentMethodInfo.type === 'card') {
      const card = paymentMethodInfo.paymentMethod as PaymentMethodCard;
      lastFourDigits = card.lastFourDigits;
      paymentMethodType = card.type;
    } else if (paymentMethodInfo.type === 'us_bank_account') {
      const usBankAccount =
        paymentMethodInfo.paymentMethod as PaymentMethodUsBank;
      lastFourDigits = usBankAccount.lastFourDigits;
      paymentMethodType = 'us_bank_account';
      bankAccountName = usBankAccount.bankName;
    } else if (paymentMethodInfo.type === 'acss_debit') {
      const acssDebitAccount =
        paymentMethodInfo.paymentMethod as PaymentMethodAchAcssDebit;
      lastFourDigits = acssDebitAccount.lastFourDigits!;
      paymentMethodType = 'acss_debit';
      bankAccountName = acssDebitAccount.bankName!;
    }
    return {
      label: makeJSXFromPaymentMethod(
        paymentMethodType,
        paymentMethodInfo.isDefault,
        lastFourDigits,
        bankAccountName
      ),
      ariaLabel: `${paymentMethodType} ${bankAccountName} ${i18next.t<string>(
        translationKeys.common.endingIn
      )} ${lastFourDigits}`,
      value: paymentMethodInfo
    };
  });
}
