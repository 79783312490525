import { statusReject } from '../statusReject';
import { PaymentMethodPumaBank } from '@fpc/api/paymentapp/index';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export async function getPumaBanksByCountry(
  country: string,
  signedPaymentInfoToken: string
): Promise<{
  banks: PaymentMethodPumaBank[];
}> {
  return fetch(getRequest(country, signedPaymentInfoToken))
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          return {
            banks: data.providers.sort(
              (a: PaymentMethodPumaBank, b: PaymentMethodPumaBank) =>
                a.name.localeCompare(b.name)
            )
          };
        });
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getRequest(country: string, signedPaymentInfoToken: string): Request {
  let request;
  const requestUrl = process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER;

  const requestBody = {
    paymentInfo: JSON.parse(signedPaymentInfoToken).paymentInfo,
    countryCode: country
  };

  request = new Request(`${requestUrl}/api/dss/pay-by-bank-providers`, {
    headers: getHeaders(signedPaymentInfoToken),
    method: 'POST',
    body: JSON.stringify(requestBody)
  });
  return request;
}

function getHeaders(signedPaymentInfoToken: string): Headers {
  let headers: Headers = HeadersBuilder();
  headers.set('Authorization', JSON.parse(signedPaymentInfoToken).signature);
  return headers;
}
