import { createContext, Dispatch } from 'react';
import { Psp } from '@fpc/common/Psp';
import { CustomerAuthTokenType } from './accountContextProvider';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { DispatchEventTarget } from '@fpc/utils/dispatchEvent';
import {
  PaymentObjectType,
  PayUTransactionDetails,
  StripeTransactionDetails,
  TransactionDetails
} from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

export const CHECKOUT_ELEMENT: DispatchEventTarget = 'fordpay-checkout';
export const PAYMENT_INFO_TOKEN = 'payment-info-token';
export const REDIRECT_URL = 'redirect-url';
export const MISSING_PARAM_ERROR_MESSAGE = 'Missing required parameter:';

export interface CheckoutTokens {
  bearerToken?: string;
  paymentInfoToken: string;
  paymentObjectType: PaymentObjectType;
  customerAuthToken?: string;
  customerAuthTokenType: CustomerAuthTokenType;
  digitalSignature?: string;
}

export interface StripeCheckout {
  transaction: StripeTransactionDetails | MultiMerchantStripeTransactionDetails;
  tokens: CheckoutTokens;
  redirectUrl: string;
  stripeCustomerId?: string;
  errorDispatch: Dispatch<ErrorCondition>;
  isPreAuth: boolean;
  bffBaseUrl?: string | null;
  fordCustomerId?: string;
  redirectStatus: string | null;
  paymentMethodDisplayOrder: string | null;
  noCreditCards: boolean;
  blockedCardBrands?: string[] | null;
  achNoPay?: boolean;
}

export const StripeCheckoutContext = createContext<StripeCheckout>({
  transaction: {
    merchantAccountId: '',
    currency: 'USD',
    amount: 0,
    merchantCountry: 'US',
    paymentMethodTypes: [],
    alternativePaymentMethodTypes: [],
    psp: Psp.Stripe
  },
  tokens: {
    bearerToken: '',
    paymentInfoToken: '',
    paymentObjectType: 'JWT',
    customerAuthTokenType: 'CAT'
  },
  redirectUrl: '',
  errorDispatch: () => undefined,
  isPreAuth: false,
  bffBaseUrl: '',
  fordCustomerId: '',
  redirectStatus: '',
  paymentMethodDisplayOrder: '',
  noCreditCards: false,
  blockedCardBrands: [],
  achNoPay: false
});

export interface BluesnapCheckout {
  transaction: TransactionDetails;
  tokens: CheckoutTokens;
  redirectUrl: string;
  errorDispatch: Dispatch<ErrorCondition>;
  merchantCountry: string;
  bffBaseUrl: string | null;
}

export const BluesnapCheckoutContext = createContext<BluesnapCheckout>({
  transaction: {
    currency: 'USD',
    amount: 0,
    psp: Psp.Bluesnap
  },
  tokens: {
    bearerToken: '',
    paymentInfoToken: '',
    paymentObjectType: 'JWT',
    customerAuthTokenType: 'CAT'
  },
  redirectUrl: '',
  errorDispatch: () => undefined,
  merchantCountry: '',
  bffBaseUrl: ''
});

export interface PumaCheckout {
  transaction: TransactionDetails;
  tokens: CheckoutTokens;
  redirectUrl: string;
  errorDispatch: Dispatch<ErrorCondition>;
  redirectStatus: string | null;
  country: string;
}
export interface PayuCheckout {
  transaction: PayUTransactionDetails;
  tokens: CheckoutTokens;
  redirectUrl: string;
  errorDispatch: Dispatch<ErrorCondition>;
}

export const PayuCheckoutContext = createContext<PayuCheckout>({
  transaction: {
    currency: 'ZAR',
    amount: 0,
    psp: Psp.Payu,
    pspAuthKey: {
      publicKey: ''
    },
    merchantAccountId: ''
  },
  tokens: {
    bearerToken: '',
    paymentInfoToken: '',
    paymentObjectType: 'DIGITALSIGN',
    customerAuthTokenType: 'CAT'
  },
  redirectUrl: '',
  errorDispatch: () => undefined
});

export const PayuCurrencies = ['ZAR', 'VND'];

export const PumaCheckoutContext = createContext<PumaCheckout>({
  transaction: {
    currency: 'GBP',
    amount: 0,
    psp: Psp.Puma
  },
  tokens: {
    paymentInfoToken: '',
    paymentObjectType: 'DIGITALSIGN',
    customerAuthTokenType: 'CAT'
  },
  redirectUrl: '',
  errorDispatch: () => undefined,
  redirectStatus: '',
  country: ''
});
