import { Style } from '../Styles';
import styled from 'styled-components/macro';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '../i18n/en-US';

interface SpinnerProps {
  invert?: boolean;
  size?: string;
  style?: object;
  ariaLabel?: string;
}

const Line = styled.div<{
  diameter: string;
  lineColor: string;
  offset: string;
}>`
  && {
    --circle-diameter: ${(props) => props.diameter};
    --indicator-color: ${(props) => props.lineColor};
    --offset: ${(props) => props.offset};

    position: relative;
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    margin: auto;
  }

  &&::before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    margin-left: calc(var(--circle-diameter) / -2);
    border-radius: var(--circle-diameter);
    border-width: 0.1em;
    border-style: solid;
    border-color: var(--indicator-color);
  }
`;

const Spinner = styled.div<{
  diameter: string;
  activityLineColor: string;
  offset: string;
  loaderWeight: string;
}>`
  && {
    --activity-line-color: ${(props) => props.activityLineColor};
    --loader-weight: ${(props) => props.loaderWeight};
  }

  @keyframes inner-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  position: relative;

  &&::before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: calc(var(--circle-diameter) + var(--offset));
    height: calc(var(--circle-diameter) + var(--offset));
    left: 50%;
    top: calc(var(--offset) / -2);
    margin-left: calc((var(--circle-diameter) + var(--offset)) / -2);
    border-radius: var(--circle-diameter);
    border-style: solid;
    border-color: transparent;
    border-width: var(--loader-weight);
    border-top-color: var(--activity-line-color);
    animation: inner-spin 2.5s linear infinite;
  }
`;

export function LoadingSpinner(props: SpinnerProps) {
  const colors = props.invert
    ? {
        lineColor: 'rgba(255, 255, 255, 0.5)',
        activityLineColor: Style.color.white
      }
    : {
        lineColor: Style.color.gray2,
        activityLineColor: Style.color.primary
      };

  const diameter = props.size ?? '8em';

  return (
    <div
      data-testid="loading-spinner"
      style={{ ...props.style }}
      role={'status'}
      aria-label={
        props.ariaLabel ?? i18next.t<string>(translationKeys.common.processing)
      }
    >
      <Line diameter={diameter} lineColor={colors.lineColor} offset={'0.2em'}>
        <Spinner
          activityLineColor={colors.activityLineColor}
          diameter={diameter}
          offset={'0.2em'}
          loaderWeight={'0.3em'}
        />
      </Line>
    </div>
  );
}
