import { Fonts } from './Fonts';

function preload(url: string) {
  const preloadElement = document.createElement('link');
  preloadElement.rel = 'preload';
  preloadElement.as = 'font';
  preloadElement.href = url;
  preloadElement.crossOrigin = 'anonymous';
  document.head.appendChild(preloadElement);
}

export function fontLoader(preloadUrls: string[]) {
  for (const url of preloadUrls) {
    preload(url);
  }

  const style = document.createElement('style');
  style.innerHTML = Fonts;
  document.body.appendChild(style);
}
