import { ReactComponent as Amex } from '../icons/creditcards/amex.svg';
import { ReactComponent as Diners } from '../icons/creditcards/diners.svg';
import { ReactComponent as Discover } from '../icons/creditcards/discover.svg';
import { ReactComponent as JCB } from '../icons/creditcards/jcb.svg';
import { ReactComponent as Mastercard } from '../icons/creditcards/mastercard.svg';
import { ReactComponent as Visa } from '../icons/creditcards/visa.svg';
import { ReactComponent as GenericCard } from '../icons/creditcards/genericcard.svg';
import { ReactComponent as Unknown } from '../icons/creditcards/unknown.svg';
import { ReactComponent as USBank } from '../icons/creditcards/usbank.svg';
import { ReactComponent as PayByBankIcon } from '../icons/creditcards/paybybank.svg';
import { ReactComponent as BacsDebit } from '../icons/creditcards/bacsDebit.svg';
import { ReactComponent as SepaDebit } from '../icons/creditcards/sepaDebit.svg';
import { ReactComponent as AcssDebit } from '../icons/creditcards/acssDebit.svg';
import { ReactComponent as Napas } from '../icons/creditcards/napas.svg';
import { ReactComponent as Ideal } from '../icons/creditcards/ideal.svg';

export function getIcon(cardType: string) {
  switch (cardType.toLowerCase()) {
    case 'amex':
      return Amex;
    case 'diners':
      return Diners;
    case 'discover':
      return Discover;
    case 'jcb':
      return JCB;
    case 'mastercard':
      return Mastercard;
    case 'visa':
      return Visa;
    case 'us_bank_account':
      return USBank;
    case 'pay_by_bank':
      return PayByBankIcon;
    case 'bacs_debit':
      return BacsDebit;
    case 'sepa_debit':
      return SepaDebit;
    case 'card':
      return GenericCard;
    case 'acss_debit':
      return AcssDebit;
    case 'napas':
      return Napas;
    case 'ideal':
      return Ideal;
    default:
      return Unknown;
  }
}
