export default {
  'errors.code.expired_card': 'Your card has expired.',
  'errors.code.incorrect_cvc': "Your card's security code is incorrect.",
  'errors.code.incorrect_number': 'Your card number is incorrect.',
  'errors.code.incorrect_zip': 'Your card number and postal code do not match.',
  'errors.code.invalid_cvc': "Your card's security code is invalid.",
  'errors.code.invalid_expiry_month': "Your card's expiration date is invalid.",
  'errors.code.invalid_expiry_month_past':
    "Your card's expiration date is in the past.",
  'errors.code.invalid_expiry_year': "Your card's expiration year is invalid.",
  'errors.code.invalid_expiry_year_past':
    "Your card's expiration year is in the past.",
  'errors.code.invalid_number': 'Your card number is invalid.',
  'errors.code.processing_error':
    'An error occurred while processing your card. Try again in a little bit.',
  'errors.code.processing_error_intransient':
    'Something went wrong. Please try again later.',
  'errors.declines.call_issuer':
    'Your card was declined. You can call your bank for details.',
  'errors.declines.card_not_supported': 'Your card is not supported.',
  'errors.declines.card_velocity_exceeded':
    'Your card was declined for making repeated attempts too frequently.',
  'errors.declines.currency_not_supported':
    'Your card is not supported for this currency.',
  'errors.declines.generic_decline': 'Your card was declined.',
  'errors.declines.generic_decline_link': 'Your payment method was declined.',
  'errors.declines.generic_decline_non_cards': 'Your payment was declined.',
  'errors.declines.incorrect_pin': 'Incorrect PIN.',
  'errors.declines.insufficient_funds': 'Your card has insufficient funds.',
  'errors.declines.invalid_account': 'Invalid account.',
  'errors.declines.invalid_amount': 'Invalid amount.',
  'errors.declines.not_permitted': 'Cardmember not enrolled / not permitted',
  'errors.declines.pin_try_exceeded': 'Allowable number of PIN tries exceeded.',
  'errors.declines.transaction_not_allowed':
    'Your card does not support this type of purchase.'
};
