export function isValidJsonPaymentInfoForDigitalSign(
  paymentInfo: string | null
) {
  try {
    return !!(
      paymentInfo &&
      JSON.parse(paymentInfo).hasOwnProperty('paymentInfo') &&
      JSON.parse(paymentInfo).hasOwnProperty('signature')
    );
  } catch (e) {
    return false;
  }
}
