import { statusReject } from '../statusReject';
import { PAY_BY_BANK_METHOD } from '../../checkout';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export interface CreateCardSetupIntentParams {
  stripeCustomerId: string;
  bearerToken?: string | null;
  bffBaseUrl?: string;
  paymentMethodTypes?: string[];
  merchantAccountId?: string;
  consumerId?: string | null;
  metadata?: Map<string, string> | null;
}

export async function createCardSetupIntent(
  createCardSetupIntentRequestParams: CreateCardSetupIntentParams
): Promise<string> {
  const request = getRequest(createCardSetupIntentRequestParams);

  return fetch(request)
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          return data.client_secret;
        });
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      console.warn('Error submitting payment request: ', err);
      return Promise.reject(err);
    });
}

function getRequest(
  createCardSetupRequest: CreateCardSetupIntentParams
): Request {
  let request;
  let paymentMethodTypes = createCardSetupRequest.paymentMethodTypes
    ? createCardSetupRequest.paymentMethodTypes
    : ['CARD'];

  if (paymentMethodTypes.includes(PAY_BY_BANK_METHOD.toUpperCase())) {
    paymentMethodTypes = paymentMethodTypes.filter(
      (pm) => pm !== PAY_BY_BANK_METHOD.toUpperCase()
    );
  }

  const requestUrl = createCardSetupRequest.bffBaseUrl
    ? `${createCardSetupRequest.bffBaseUrl}${process.env.CUSTOMER_APP_APIGEE_BASE_PATH}/v2/customers/setup-intent`
    : `${process.env.CUSTOMER_APP_SERVER}/v1/customers/setup-intent`;
  request = new Request(`${requestUrl}`, {
    method: 'POST',
    headers: getHeaders(createCardSetupRequest),
    body: JSON.stringify({
      stripeCustomerId: createCardSetupRequest.stripeCustomerId,
      paymentMethodTypes: paymentMethodTypes.map((pm) => pm.toUpperCase()),
      merchantAccountId: createCardSetupRequest.merchantAccountId,
      consumerId: createCardSetupRequest.consumerId,
      metadata: createCardSetupRequest.metadata
    })
  });
  return request;
}

function getHeaders(
  createCardSetupRequest: CreateCardSetupIntentParams
): Headers {
  let headers: Headers = HeadersBuilder();
  if (
    createCardSetupRequest.bearerToken &&
    !createCardSetupRequest.bffBaseUrl
  ) {
    headers.set(
      'Authorization',
      `Bearer ${createCardSetupRequest.bearerToken}`
    );
  }
  return headers;
}
