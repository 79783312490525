import { getIcon, translationKeys } from '@fpc/common';

import { FordStyles } from '@fpc/common/FordStyles';
import i18n from '@fpc/common/i18n';

interface PayByAcssDebitLinkProps {
  togglePayByAcssDebit: (value: boolean) => void;
}

export const PayByAcssDebitLink = (props: PayByAcssDebitLinkProps) => {
  const PayByAcssDebitIcon = getIcon('acss_debit');
  function onCheck() {
    props.togglePayByAcssDebit(true);
  }
  return (
    <span>
      <div>
        <input
          type={'radio'}
          data-testid={'acss-link-radio'}
          style={{ marginRight: '5px', paddingRight: '10px' }}
          onChange={() => onCheck()}
        />

        <PayByAcssDebitIcon
          style={{
            height: '1em',
            marginRight: '15px',
            marginTop: '2px',
            paddingLeft: '5px'
          }}
        />

        <a
          href="#"
          role="link"
          data-testid={'acss-link'}
          style={{
            cursor: 'pointer',
            color: FordStyles.color.primary,
            fontFamily: FordStyles.fontFamilyBold,
            fontSize: '12px',
            lineHeight: '12px',
            paddingBottom: '10px',
            textDecoration: 'none'
          }}
          onClick={() => {
            onCheck();
          }}
        >
          <b>{i18n.t<string>(translationKeys.checkout.preAuthorizedDebit)}</b>
        </a>
      </div>
    </span>
  );
};
