export const displayedBankPaymentMethodsGB = [
  'Bank of Scotland',
  'Barclays',
  'Danske Bank',
  'First Direct',
  'Halifax',
  'HSBC',
  'HSBC (Sandbox)',
  'Lloyds Bank',
  'Monzo',
  'NatWest',
  'Natwest (Sandbox)',
  'Nationwide',
  'Revolut',
  'Royal Bank of Scotland',
  'Santander',
  'TSB',
  'Ulster Bank'
];
export const displayedBankPaymentMethodsGBPROD = [
  'Bank of Scotland',
  'Barclays',
  'Danske Bank',
  'First Direct',
  'Halifax',
  'HSBC',
  'Lloyds Bank',
  'Monzo',
  'NatWest',
  'Nationwide',
  'Revolut',
  'Royal Bank of Scotland',
  'Santander',
  'TSB',
  'Ulster Bank'
];
