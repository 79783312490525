import { enUS, TRANSLATION_MISSING } from './en-US';

export const nlBE: typeof enUS = {
  common: {
    default: 'standaard',
    endingIn: 'eindigt in',
    close: 'Sluiten',
    makeDefaultCard: 'Standaard betaalkaart maken',
    makeDefaultInfoHover:
      'Dit wordt automatisch uw standaard betaalkaart omdat het de eerste kaart in uw portefeuille is.',
    processing: 'Aan het verwerken ...',
    saveCardCheck: 'Betaalkaart opslaan voor latere transacties',
    saveCardSuccess: 'Betaalkaart toegevoegd aan Ford.com portefeuille.',
    technicalErrorPayment:
      'Sorry! Er is een technische fout opgetreden en uw betaling is niet verwerkt. Probeer opnieuw.',
    timeoutSorry: 'Sorry ...',
    timeoutError: 'We ervaren momenteel technische storingen.',
    timeoutTryAgain: 'Probeer opnieuw.',
    savePaymentMethodCheck: 'Betaalwijze voor toekomstige transacties bewaren',
    makeDefaultPaymentMethod: 'Instellen als betaalwijze',
    savePaymentMethodButton: 'Betaalmethode opslaan',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Dit wordt automatisch uw standaard betaalmethode, omdat het de eerste betaalmethode in uw portemonnee is.',
    savePaymentMethodSuccess: 'Betaalmethode is toegevoegd aan Wallet.'
  },
  checkout: {
    payButton: 'Betaal {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Sorry! Er is een technische fout opgetreden en uw betaling is niet verwerkt. Vernieuw de pagina en probeer het opnieuw.\u00a0',
    technicalErrorPaymentDetachFail:
      'Sorry! Er is een technische fout opgetreden en uw betaling is niet verwerkt. Uw betaalkaart is opgeslagen in uw portefeuille. Probeer opnieuw.',
    tosTextBefore:
      "Door hieronder op de knop 'Betalen' te klikken, gaat u akkoord met de ",
    tosLinkText: 'betalingsvoorwaarden en het privacybeleid',
    tosTextAfter: '.',
    useNewCard: 'Nieuwe betaalkaart gebruiken',
    useNewCardDropdownText: 'Nieuwe kaart toevoegen',
    useNewCardTextBefore: 'Afrekenen met opgeslagen kaart of ',
    useNewCardLinkText: 'nieuwe betaalwijze gebruiken',
    useNewCardTextAfter: '.',
    cardSelect: 'Selecteer kredietkaart',
    checkoutFaster: 'Betaal sneller met FordPay.',
    checkout: 'Afrekenen',
    nameOnCard: 'Naam op kaart',
    fullName: 'Volledige naam',
    cardNumber: 'Kaartnummer',
    expirationDate: 'Verval- datum',
    securityCode: 'Beveiligingscode',
    invalidCCNMessage: 'Uw kaartnummer is niet geldig.',
    invalidCVVMessage: 'Uw beveiligingscode is niet geldig.',
    invalidExpDateMessage: 'Uw vervaldatum is niet geldig.',
    emptyCCNMessage: 'Uw kaartnummer is niet ingevuld.',
    emptyCVVMessage: 'Uw beveiligingscode is niet ingevuld.',
    emptyExpDateMessage: 'Uw vervaldatum is niet ingevuld.',
    unsupportedCardMessage:
      'Uw kaartnummer wordt niet ondersteund. Voer VISA, MasterCard of AMEX in.',
    paymentNotProcessed: 'Uw betaling kon niet worden verwerkt',
    redirectStatusFailure:
      'Uw betaling kon niet worden verwerkt. Probeer opnieuw.\u00a0',
    continue: 'Doorgaan {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      "Door hieronder op de knop 'Doorgaan ' te klikken, gaat u akkoord met de ",
    authCheckoutStatement:
      'Betaal met uw opgeslagen kaart, gebruik een nieuwe betaalmethode of gebruik Betalen per bank.',
    useNewCardTextBeforePayByBank: 'Betaal met uw opgeslagen kaart, ',
    usePayByBank: ', of gebruik ',
    payByBankLinkText: 'Betalen per bank',
    payByBankHeader: 'Gebruik Betalen per bank.',
    use: 'Gebruik ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Nieuwe betaalwijze gebruiken',
    useNewPaymentDropdownText: 'nieuwe betaalwijze gebruiken',
    useNewPaymentTextBefore: 'Afrekenen met opgeslagen betaalmethode of ',
    useNewPaymentLinkText: 'nieuwe betaalwijze gebruiken',
    useNewPaymentTextBeforePayByBank:
      'Afrekenen met opgeslagen betaalmethode, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'U hebt momenteel geen betaalmethoden in uw portemonnee. Begin door de eerste betaalmethode aan uw portemonnee toe te voegen.',
    addPaymentMethod: 'Betaalwijze toevoegen',
    makeDefault: 'Standaard maken',
    changeDefaultPMSuccess: 'Uw standaard betaalmethode is gewijzigd.',
    makeDefaultSuccessPM: 'Betalingsmethode toegevoegd als standaard.',
    makeDefaultErrorPM:
      'Fout bij het instellen van de betalingsmethode als standaard.',
    removePMSuccess: 'Betalingsmethode verwijderd uit Ford.com-portemonnee',
    removePMError: 'Fout bij het verwijderen van de betalingsmethode.',
    removePM: 'Verwijder betalingsmethode',
    removePMQuestion: 'Betalingsmethode verwijderen?\u00a0',
    removePMText:
      'Weet u zeker dat u betalingsmethode {{cardNumber}} uit uw portemonnee wilt verwijderen? Alle actieve abonnementen die aan deze betaalmethode zijn gekoppeld, mislukken als de betaalmethode wordt verwijderd.',
    defaultCard: 'Standaard betaalkaart',
    defaultPM: 'Standaard betalingsmethode\u00a0',
    defaultCardQuestion: 'Standaard betaalkaart?',
    defaultPMQuestion: 'Standaard betalingsmethode?\u00a0',
    defaultCardText:
      'Weet u zeker dat u uw standaard betaalkaart wilt wijzigen in {{cardNumber}}?',
    defaultPMText:
      'Weet u zeker dat u uw standaard betalingsmethode wilt wijzigen naar {{cardNumber}}?',
    navigationLabel: 'Navigatie accountbeheer',
    removeDefaultPMSuccess:
      'Standaard betalingsmethode verwijderd uit Ford.com-portemonnee',
    removeDefaultCardError:
      'Fout bij het verwijderen van de standaard betaalkaart.',
    removeDefaultPMError:
      'Fout bij het verwijderen van de standaard betalingsmethode.',
    removeDefaultCard: 'Verwijder uw standaard betaalkaart.',
    removeDefaultPM: 'Verwijder uw standaard betalingsmethode?',
    removeDefaultPMText:
      'Betalingsmethode {{cardNumber}} is uw standaard betalingsmethode. Kies hieronder een nieuwe standaard betalingsmethode. Alle actieve abonnementen die aan deze betaalmethode zijn gekoppeld, mislukken als de betaalmethode wordt verwijderd.',
    removeDefaultPMButton: 'Verwijder betalingsmethode en update standaard',
    removeDefaultSelectPM: 'Selecteer nieuwe standaard betalingsmethode',
    title: 'Portefeuille',
    expiration: 'Vervaldatum\u00a0{{date}}',
    expires: 'Verloopt op {{date}}',
    stripeDefaultError: 'Er was een technisch probleem. Probeer opnieuw.\u00a0',
    paymentMethod: 'Betalingswijze',
    tosTextBefore:
      "Door hieronder op de knop 'Kaart bewaren' te klikken, gaat u akkoord met de ",
    tosTextBeforePM:
      "Door op de knop 'Betalingsmethode opslaan' hieronder te klikken, gaat u akkoord met de",
    tosLinkText: 'betalingsvoorwaarden en het privacybeleid',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alle',
    amount: 'Bedrag',
    date: 'Datum',
    description: 'Beschrijving',
    displayingAll: 'Alle transacties weergeven.',
    displayingDays:
      'Transacties van de laatste {{numberOfDays}} dagen weergeven.',
    displayingYear: 'Transacties uit {{year}} weergeven.',
    filter: 'Afgelopen {{numberOfDays}} dagen',
    loadAll: 'Alle laden',
    loadMore: 'Extra {{x}} laden',
    noTransactions: 'Geen transacties om weer te geven.',
    paymentMethod: 'Betaalwijze',
    refund: 'Terugbetaling',
    searchBar: 'Zoeken op beschrijving',
    timeFilter: 'Selecteer periode voor transactiegeschiedenis.',
    title: 'Transacties',
    pending: 'In behandeling'
  },
  mit: {
    confirmPurchase: 'Bevestig aankoop',
    paymentSuccessful: 'Betaling gelukt',
    saveCardAlert:
      'Als u deze betaalkaart wilt gebruiken voor toekomstige betalingen, dient u ze op te slaan in uw portefeuille op Ford.com.',
    title: 'Selectie betaalwijze',
    tosTextBefore:
      'Door hieronder op de knop {{paymentButtonText}} te klikken, gaat u akkoord met de ',
    tosLinkText: 'betalingsvoorwaarden en het privacybeleid',
    tosTextAfter: '.',
    useNewCard: 'Nieuwe betaalkaart gebruiken',
    useNewCardDropDownItem: 'Nieuwe betaalkaart gebruiken',
    useNewCardTextBefore: 'Selecteer een opgeslagen betaalkaart of ',
    useNewCardLinkText: 'gebruik een nieuwe kaart',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Nieuwe betaalwijze gebruiken',
    useNewPaymentDropdownText: 'nieuwe betaalwijze gebruiken',
    useNewPaymentTextBefore: 'Afrekenen met opgeslagen betaalmethode of ',
    useNewPaymentLinkText: 'nieuwe betaalwijze gebruiken',
    successful: 'Succesvol',
    savePaymentMethodAlert:
      'Om deze betaalmethode voor toekomstige betalingen te gebruiken, moet deze in uw portemonnee worden opgeslagen.'
  }
};
