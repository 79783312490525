import styled from 'styled-components/macro';

export const BluesnapWalletContainer = styled.div`
  && {
    width: 40%;
    margin: auto;
  }
`;

export const BluesnapDivider = styled.div`
  && {
    height: 2px;
  }
`;
