export default {
  'errors.code.expired_card': 'Korttisi on vanhentunut.',
  'errors.code.incorrect_cvc': 'Kortin turvakoodi on virheellinen.',
  'errors.code.incorrect_number': 'Kortin numero on virheellinen.',
  'errors.code.incorrect_zip': 'Kortin numero ja postinumero eivät täsmää.',
  'errors.code.invalid_cvc': 'Kortin turvakoodi ei kelpaa.',
  'errors.code.invalid_expiry_month': 'Kortin voimassaoloaika ei kelpaa.',
  'errors.code.invalid_expiry_month_past':
    'Kortin voimassaoloaika on menneisyydessä.',
  'errors.code.invalid_expiry_year': 'Kortin voimassaolovuosi ei kelpaa.',
  'errors.code.invalid_expiry_year_past':
    'Kortin voimassaolovuosi on menneisyydessä.',
  'errors.code.invalid_number': 'Kortin numero ei kelpaa.',
  'errors.code.processing_error':
    'Korttia käsiteltäessä tapahtui virhe. Kokeile uudelleen hetken kuluttua.',
  'errors.declines.call_issuer':
    'Korttiasi ei hyväksytty. Pyydä lisätietoja pankiltasi.',
  'errors.declines.card_not_supported': 'Korttiasi ei tueta.',
  'errors.declines.card_velocity_exceeded':
    'Korttiasi ei hyväksytty, koska olet yrittänyt toistuvasti liian usein.',
  'errors.declines.currency_not_supported': 'Korttisi ei tule tätä valuuttaa.',
  'errors.declines.generic_decline': 'Korttiasi ei hyväksytty.',
  'errors.declines.incorrect_pin': 'Virheellinen PIN-koodi.',
  'errors.declines.insufficient_funds': 'Kortilla ei ole riittävästi katetta.',
  'errors.declines.invalid_account': 'Virheellinen tili.',
  'errors.declines.invalid_amount': 'Virheellinen summa.',
  'errors.declines.not_permitted':
    'Kortinhaltija ei ole rekisteröitynyt / häntä ei sallittu',
  'errors.declines.pin_try_exceeded':
    'PIN-koodia on yritetty liian monta kertaa.',
  'errors.declines.transaction_not_allowed':
    'Korttisi ei tue tämäntyyppistä ostosta.',
  'errors.code.processing_error_intransient':
    'Jokin meni pieleen. Yritä myöhemmin uudelleen.',
  'errors.declines.generic_decline_link': 'Maksutapasi on hylätty.',
  'errors.declines.generic_decline_non_cards': 'Maksusi hylättiin.'
};
