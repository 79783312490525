import { ReactElement, useReducer } from 'react';
import { StripeGuestCheckout } from './StripeGuestCheckout';
import { StripeAuthCheckout } from './StripeAuthCheckout';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import { TechnicalError } from '@fpc/common';
import { CheckoutLoadError } from '../CheckoutLoadError';
import { errorReducer, initialErrorState } from '@fpc/common/ErrorHandler';
import { isNotProd } from '../../flags';
import {
  CommonCheckoutProps,
  logMissingParamError,
  validateCheckout
} from '../CheckoutCommon';
import { useStripeCheckoutContext } from './UseStripeCheckoutContext';
import { CheckoutLoadWrapper } from '../CheckoutLoadWrapper';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

export interface StripeCheckoutProps extends CommonCheckoutProps {
  isPreAuth: boolean;
  transactionDetails:
    | StripeTransactionDetails
    | MultiMerchantStripeTransactionDetails;
  redirectStatus: string | null;
  paymentMethodDisplayOrder: string | null;
  achNoPay?: boolean;
}

export function StripeCheckoutWrapper(props: StripeCheckoutProps) {
  const [error, errorDispatch] = useReducer(errorReducer, initialErrorState);
  const { isValidCheckout, isAuthCheckout } = validateCheckout(props);

  const {
    loading,
    fordCustomerId,
    stripeCustomerId,
    cards,
    transaction,
    resetFromError
  } = useStripeCheckoutContext(props, errorDispatch);

  function guestCheckout(): ReactElement {
    if (transaction != null) {
      return (
        <StripeCheckoutContext.Provider
          value={{
            transaction,
            tokens: props,
            redirectUrl: props.redirectUrl,
            errorDispatch,
            isPreAuth: props.isPreAuth,
            bffBaseUrl: props.bffBaseUrl ?? '',
            fordCustomerId: fordCustomerId,
            redirectStatus: props.redirectStatus,
            paymentMethodDisplayOrder: props.paymentMethodDisplayOrder
              ? props.paymentMethodDisplayOrder
              : transaction.paymentMethodTypes.join(',').toLowerCase(),
            noCreditCards: props.noCreditCards,
            blockedCardBrands: props.transactionDetails.blockedCardBrands,
            achNoPay: props.achNoPay
          }}
        >
          <StripeGuestCheckout isPreAuth={props.isPreAuth} />
        </StripeCheckoutContext.Provider>
      );
    } else {
      return <></>;
    }
  }

  function authCheckout(): ReactElement {
    if (transaction != null && cards != null && stripeCustomerId != null) {
      return (
        <StripeCheckoutContext.Provider
          value={{
            transaction,
            tokens: props,
            redirectUrl: props.redirectUrl,
            stripeCustomerId,
            errorDispatch,
            isPreAuth: props.isPreAuth,
            fordCustomerId: fordCustomerId,
            bffBaseUrl: props.bffBaseUrl ?? '',
            redirectStatus: props.redirectStatus,
            paymentMethodDisplayOrder: props.paymentMethodDisplayOrder
              ? props.paymentMethodDisplayOrder
              : transaction.paymentMethodTypes.join(',').toLowerCase(),
            noCreditCards: props.noCreditCards,
            blockedCardBrands: props.transactionDetails.blockedCardBrands
          }}
        >
          <StripeAuthCheckout cards={cards} fordCustomerId={fordCustomerId} />
        </StripeCheckoutContext.Provider>
      );
    } else {
      return guestCheckout();
    }
  }

  function getCheckout(): ReactElement {
    if (isAuthCheckout) {
      return authCheckout();
    } else if (isValidCheckout) {
      return guestCheckout();
    } else {
      logMissingParamError(
        props.paymentInfoToken,
        props.redirectUrl,
        props.bearerToken!
      );
      if (isNotProd()) {
        return (
          <CheckoutLoadError
            redirectUrl={props.redirectUrl}
            bearerToken={props.bearerToken!}
            paymentInfoToken={props.paymentInfoToken}
            customerAuthToken={props.customerAuthToken}
            customerAuthTokenType={props.customerAuthTokenType}
            isPreAuth={props.isPreAuth}
            bffBaseUrl={props.bffBaseUrl}
            isValidAuthParams={true}
            language={props.language}
            redirectStatus={props.redirectStatus}
          />
        );
      } else {
        return (
          <TechnicalError
            resetFromError={resetFromError}
            unrecoverable={true}
          />
        );
      }
    }
  }

  return (
    <CheckoutLoadWrapper
      error={error}
      resetFromError={resetFromError}
      loading={loading}
      checkoutComponent={getCheckout()}
    />
  );
}
