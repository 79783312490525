export default {
  'errors.code.expired_card': 'Η κάρτα σας έχει λήξει.',
  'errors.code.incorrect_cvc':
    'Ο κωδικός ασφαλείας της κάρτας σας είναι λανθασμένος.',
  'errors.code.incorrect_number': 'Ο αριθμός της κάρτας σας είναι λανθασμένος.',
  'errors.code.incorrect_zip':
    'Ο αριθμός της κάρτας σας και ο ταχυδρομικός κώδικας δεν αντιστοιχούν.',
  'errors.code.invalid_cvc':
    'Ο κωδικός ασφαλείας της κάρτας σας δεν είναι έγκυρος.',
  'errors.code.invalid_expiry_month':
    'Η ημερομηνία λήξης της κάρτας σας δεν είναι έγκυρη.',
  'errors.code.invalid_expiry_month_past':
    'Η ημερομηνία λήξης της κάρτας σας έχει παρέλθει.',
  'errors.code.invalid_expiry_year':
    'Το έτος λήξης της κάρτας σας δεν είναι έγκυρο.',
  'errors.code.invalid_expiry_year_past':
    'Το έτος λήξης της κάρτας σας έχει παρέλθει.',
  'errors.code.invalid_number': 'Ο αριθμός της κάρτας σας δεν είναι έγκυρος.',
  'errors.code.processing_error':
    'Προέκυψε σφάλμα κατά την επεξεργασία της κάρτας σας. Προσπαθήστε ξανά σε λίγο.',
  'errors.declines.call_issuer':
    'Η κάρτα σας απορρίφθηκε. Μπορείτε να επικοινωνήσετε με την τράπεζά σας για λεπτομέρειες.',
  'errors.declines.card_not_supported': 'Η κάρτα σας δεν υποστηρίζεται.',
  'errors.declines.card_velocity_exceeded':
    'Η κάρτα σας απορρίφθηκε λόγω επανειλημμένων και πολύ συχνών προσπαθειών.',
  'errors.declines.currency_not_supported':
    'Η κάρτα σας δεν υποστηρίζεται για αυτό το νόμισμα.',
  'errors.declines.generic_decline': 'Η κάρτα σας απορρίφθηκε.',
  'errors.declines.incorrect_pin': 'Λανθασμένο PIN.',
  'errors.declines.insufficient_funds':
    'Η κάρτα σας δεν έχει επαρκές υπόλοιπο.',
  'errors.declines.invalid_account': 'Μη έγκυρος λογαριασμός.',
  'errors.declines.invalid_amount': 'Μη έγκυρο ποσό.',
  'errors.declines.not_permitted':
    'Ο κάτοχος της κάρτας δεν έχει εγγραφεί / δεν έχει άδεια',
  'errors.declines.pin_try_exceeded':
    'Υπέρβαση επιτρεπόμενου αριθμού προσπαθειών PIN.',
  'errors.declines.transaction_not_allowed':
    'Η κάρτα σας δεν υποστηρίζει αυτόν τον τύπο αγοράς.',
  'errors.code.processing_error_intransient':
    'Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξανά αργότερα.',
  'errors.declines.generic_decline_link': 'Η μέθοδος πληρωμής σας απορρίφθηκε.',
  'errors.declines.generic_decline_non_cards': 'Η πληρωμή σας απορρίφθηκε.'
};
