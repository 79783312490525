export async function loadDynatraceJsIfRequired() {
  const url = process.env.DYNATRACE_RUM_JS_URL;

  if (!!url && !window.dtrum) {
    loadScript(url);
  }
}

function loadScript(url: string) {
  const script = document.createElement('script');
  script.src = url;
  script.type = 'text/javascript';
  script.async = true;
  script.crossOrigin = 'anonymous';
  document.head.appendChild(script);
}
