export default {
  'errors.code.expired_card': 'Dit kort er udløbet.',
  'errors.code.incorrect_cvc': 'Dit korts sikkerhedskode er forkert.',
  'errors.code.incorrect_number': 'Kortnummeret er forkert.',
  'errors.code.incorrect_zip': 'Kortnummeret og postnummeret matcher ikke.',
  'errors.code.invalid_cvc': 'Dit korts sikkerhedskode er ugyldig.',
  'errors.code.invalid_expiry_month': 'Dit korts udløbsdato er ugyldig.',
  'errors.code.invalid_expiry_month_past': 'Dit korts udløbsdato er udløbet.',
  'errors.code.invalid_expiry_year': 'Kortets udløbsår er ugyldigt.',
  'errors.code.invalid_expiry_year_past': 'Kortets udløbsår er udløbet.',
  'errors.code.invalid_number': 'Kortnummeret er ugyldigt.',
  'errors.code.processing_error':
    'Der opstod en fejl under behandling af dit kort. Prøv igen om et øjeblik.',
  'errors.declines.call_issuer':
    'Dit kort blev afvist. Du kan ringe til din bank for at få flere oplysninger.',
  'errors.declines.card_not_supported': 'Dit kort understøttes ikke.',
  'errors.declines.card_velocity_exceeded':
    'Dit kort blev afvist, fordi der blev foretaget gentagne forsøg for ofte.',
  'errors.declines.currency_not_supported':
    'Dit kort understøtter ikke denne valuta.',
  'errors.declines.generic_decline': 'Dit kort blev afvist.',
  'errors.declines.incorrect_pin': 'Forkert PIN.',
  'errors.declines.insufficient_funds': 'Ikke penge nok på kortets konto.',
  'errors.declines.invalid_account': 'Ugyldig konto.',
  'errors.declines.invalid_amount': 'Ugyldigt beløb.',
  'errors.declines.not_permitted': 'Kortholder er ikke tilmeldt/ikke tilladt',
  'errors.declines.pin_try_exceeded':
    'Det tilladt antal PIN-forsøg er overskredet.',
  'errors.declines.transaction_not_allowed':
    'Dit kort understøtter ikke denne form for køb.',
  'errors.code.processing_error_intransient':
    'Noget gik galt. Prøv igen senere.',
  'errors.declines.generic_decline_link': 'Din betalingsmetode blev afvist.',
  'errors.declines.generic_decline_non_cards': 'Din betaling blev afvist.'
};
