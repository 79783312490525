import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElementConfirmEvent
} from '@stripe/stripe-js';
import { useContext } from 'react';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import { isLocal } from '../../flags';
import {
  ExpressCheckoutButton,
  ExpressCheckoutOptions
} from '@fpc/common/components/ExpressCheckoutButton';
import { PaymentMethodIdAndType } from './GuestPayment';

interface GuestPaymentRequestProps {
  elements: StripeElements;
  stripe: Stripe;
  isReadyHandler: (isReady: boolean) => void;
  setPaymentMethod: (paymentMethod: PaymentMethodIdAndType) => void;
}

export function GuestPaymentRequestButton(props: GuestPaymentRequestProps) {
  const { transaction } = useContext(StripeCheckoutContext);

  const onExpressPaymentCallback = async (
    ev: StripeExpressCheckoutElementConfirmEvent,
    elements: StripeElements
  ): Promise<void> => {
    props.isReadyHandler(false);

    const { paymentMethod, error } = await props.stripe.createPaymentMethod({
      elements: elements
    });

    if (paymentMethod) {
      props.setPaymentMethod({
        id: paymentMethod.id,
        type: paymentMethod.type
      });
    } else {
      if (isLocal) {
        console.warn(error);
      }
      ev.paymentFailed({ reason: 'fail' });
      props.isReadyHandler(true);
    }
  };

  const expressCheckoutOptions: ExpressCheckoutOptions = {
    amount: transaction.amount,
    currency: transaction.currency,
    googlePay: transaction.alternativePaymentMethodTypes.includes('GOOGLE_PAY'),
    applePay: transaction.alternativePaymentMethodTypes.includes('APPLE_PAY')
  };

  return (
    <ExpressCheckoutButton
      stripe={props.stripe}
      options={expressCheckoutOptions}
      onPaymentConfirmCallback={onExpressPaymentCallback}
    />
  );
}
