import { AuthTransaction } from './index';
import { getPaymentMethods } from '../customerapp';
import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import {
  filterPaymentMethodByCardBrand,
  filterPaymentMethodsByTypes,
  isAchEnabled
} from '@fpc/utils/paymentMethods';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

export type AuthTransactionRequestTransactionDetails =
  | StripeTransactionDetails
  | MultiMerchantStripeTransactionDetails;

export type AuthTransactionRequest = {
  bearerToken: string;
  customerAuthToken: string;
  customerAuthTokenType: CustomerAuthTokenType;
  bffBaseUrl: string | null;
  transactionDetails: AuthTransactionRequestTransactionDetails;
  isPreAuth: boolean;
  noCreditCards: boolean;
  merchantAccountId?: string;
};

export async function getAuthTransaction(
  request: AuthTransactionRequest
): Promise<AuthTransaction> {
  const fetchCards = getPaymentMethods(
    request.bearerToken,
    request.customerAuthToken,
    request.customerAuthTokenType,
    request.bffBaseUrl,
    request.merchantAccountId
  );
  return Promise.all([fetchCards]).then((result) => {
    let filterByPaymentMethodTypes = result[0].paymentMethods.filter(
      filterPaymentMethodsByTypes(
        request.transactionDetails.paymentMethodTypes,
        request.isPreAuth,
        request.noCreditCards,
        isAchEnabled(request.transactionDetails)
      )
    );
    if (request.transactionDetails.blockedCardBrands) {
      filterByPaymentMethodTypes = filterPaymentMethodByCardBrand(
        filterByPaymentMethodTypes,
        request.transactionDetails.blockedCardBrands
      );
    }

    return {
      paymentMethods: filterByPaymentMethodTypes,
      pspCustomerId: result[0].pspCustomerId,
      fordCustomerId: result[0].fordCustomerId
    };
  });
}
