import { Currency, DEFAULT_PAYMENT_BUTTON_TEXT } from '@fpc/common';
import { DispatchEventTarget } from '@fpc/utils/dispatchEvent';
import { createContext } from 'react';
import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';
import { PaymentObjectType } from '@fpc/common/transactionInterfaces';

export interface MitTokens {
  bearerToken: string | null;
  customerAuthToken: string | null;
  customerAuthTokenType: CustomerAuthTokenType;
  paymentInfoToken: string;
  paymentObjectType: PaymentObjectType;
}

export interface MitTransactionDetails {
  paymentMethodTypes: string[];
  amount: number;
  alternativePaymentMethods: string[];
}

export interface MerchantInitiatedTransaction {
  tokens: MitTokens;
  merchantCurrency: Currency;
  elementName: DispatchEventTarget;
  isPreAuth: boolean;
  paymentButtonText: string;
  bffBaseUrl: string | null;
  redirectStatus: string | null;
  paymentMethodDisplayOrder: string | null;
  noCreditCards: boolean;
}

export const MIT_ELEMENT_NAME = 'fordpay-merchant-initiated-transaction';

export const MitContext = createContext<MerchantInitiatedTransaction>({
  tokens: {
    bearerToken: '',
    customerAuthToken: '',
    customerAuthTokenType: 'CAT',
    paymentInfoToken: '',
    paymentObjectType: 'JWT'
  },
  merchantCurrency: 'USD',
  elementName: MIT_ELEMENT_NAME,
  isPreAuth: false,
  paymentButtonText: DEFAULT_PAYMENT_BUTTON_TEXT,
  bffBaseUrl: '',
  redirectStatus: '',
  paymentMethodDisplayOrder: '',
  noCreditCards: false
});
