import {
  PAYU_SUPPORTED_CARDS_BY_CURRENCY,
  SUPPORTED_CARDS_FOR_SA,
  SUPPORTED_CARDS_FOR_VN
} from './PayuCardConfig';
import { BlurEventType } from './PayuFormInputEventTypes';

const validateSupportedCards = (
  event: BlurEventType,
  currency: string
): boolean => {
  const supportedCards =
    PAYU_SUPPORTED_CARDS_BY_CURRENCY[currency.toUpperCase()];
  return (
    supportedCards !== undefined &&
    supportedCards.includes(event.brand?.toUpperCase())
  );
};

const isUnKnownBrand = (brand: string): boolean => {
  return brand === undefined;
};

const applyVNDValidation = (currency: string): boolean => {
  return currency.toUpperCase() === 'VND';
};

const isNapasCard = (cardNumber: string): boolean => {
  return cardNumber.startsWith('9704');
};

const getDefaultCardBrandIcons = (currency: string): string[] => {
  let cardBrands: string[] = [];
  if (currency.toUpperCase() === 'ZAR') {
    cardBrands = SUPPORTED_CARDS_FOR_SA.map((cardBrand) => {
      return cardBrand.toLowerCase();
    });
  } else if (currency.toUpperCase() === 'VND') {
    cardBrands = SUPPORTED_CARDS_FOR_VN.map((cardBrand) => {
      return cardBrand.toLowerCase();
    });
  }
  return cardBrands;
};

const getCardBrandIconByNumber = (cardNumber: string): string => {
  if (isNapasCard(cardNumber)) {
    return 'napas';
  }
  return '';
};

export {
  validateSupportedCards,
  getDefaultCardBrandIcons,
  getCardBrandIconByNumber
};
