import {
  isPayUTransaction,
  isStripeTransaction,
  PayUTransactionDetails,
  StripeTransactionDetails,
  TransactionDetails
} from '@fpc/common/transactionInterfaces';
import { CONNECT_PAYMENT_METHOD_TYPES } from 'checkout';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

export function parseTransactionDetails(
  data: any,
  isPreAuth?: boolean
): TransactionDetails | StripeTransactionDetails | PayUTransactionDetails {
  const transaction = {
    merchantAccountId: data.merchantAccountId,
    currency: data.currency,
    amount: data.amount,
    merchantCountry: data.merchantCountry,
    paymentMethodTypes: data.paymentMethodTypes,
    alternativePaymentMethodTypes: data.alternativePaymentMethods,
    psp: data.psp
  };
  if (isPayUTransaction(transaction)) {
    if (data.pspAuthKey) {
      transaction.pspAuthKey = {
        publicKey: data.pspAuthKey.publicKey
      };
    }
    return transaction;
  } else if (isStripeTransaction(transaction)) {
    let transactionDetails: StripeTransactionDetails = transaction;
    transactionDetails = prepareTransaction(
      transactionDetails,
      data,
      isPreAuth
    ) as StripeTransactionDetails;
    if (data.recurringPaymentRequest) {
      transactionDetails.recurringPaymentRequest = {
        paymentDescription: data.recurringPaymentRequest.paymentDescription,
        managementURL: data.recurringPaymentRequest.managementUrl,
        regularBilling: {
          amount: Number(data.recurringPaymentRequest.regularBilling.amount),
          label: data.recurringPaymentRequest.regularBilling.label,
          startDate: new Date(
            data.recurringPaymentRequest.regularBilling.startDate
          ),
          endDate: new Date(
            data.recurringPaymentRequest.regularBilling.endDate
          ),
          intervalUnit:
            data.recurringPaymentRequest.regularBilling.intervalUnit,
          intervalCount: Number(
            data.recurringPaymentRequest.regularBilling.intervalCount
          )
        }
      };
    }
    return transactionDetails;
  } else {
    return {
      amount: transaction.amount,
      currency: transaction.currency,
      psp: transaction.psp
    };
  }
}

function prepareTransaction(
  transaction: StripeTransactionDetails | MultiMerchantStripeTransactionDetails,
  data: any,
  isPreAuth?: boolean
): StripeTransactionDetails | MultiMerchantStripeTransactionDetails {
  if (data.consumerId) {
    transaction.consumerId = data.consumerId;
  }

  if (data.metadata) {
    transaction.metadata = data.metadata;
  }

  if (data.blockedCardBrands) {
    transaction.blockedCardBrands = data.blockedCardBrands;
  }
  if (data.connectedPaymentMethodTypes) {
    transaction.paymentMethodTypesExcluded = data.connectedPaymentMethodTypes;
    if (isPreAuth) {
      transaction.paymentMethodTypesExcluded =
        transaction.paymentMethodTypesExcluded?.filter(
          (method) => !CONNECT_PAYMENT_METHOD_TYPES.indexOf(method)
        );
    }
  }
  if (data.description) {
    transaction.description = data.description;
  }
  if (data.orderId) {
    transaction.orderId = data.orderId;
  }
  return transaction;
}

export function parseTransactionDetailsForMultiMerchant(
  data: any,
  isPreAuth?: boolean
): MultiMerchantStripeTransactionDetails {
  const transaction = {
    merchantAccountId: data.merchantAccountId,
    currency: data.currency,
    amount: data.amount,
    merchantCountry: data.merchantCountry,
    paymentMethodTypes: data.paymentMethodTypes,
    alternativePaymentMethodTypes: data.alternativePaymentMethods,
    psp: data.psp,
    version: data.version
  };

  let transactionDetails: MultiMerchantStripeTransactionDetails =
    transaction as MultiMerchantStripeTransactionDetails;
  transactionDetails = prepareTransaction(
    transactionDetails,
    data,
    isPreAuth
  ) as MultiMerchantStripeTransactionDetails;
  return transactionDetails;
}
