import styled from 'styled-components/macro';
import { FordStyles } from '@fpc/common/FordStyles';
import { ReactComponent as InfoDotIcon } from '@fpc/common/icons/information-icon-24x24-primary.svg';

export const InfoBannerDiv = styled.div`
  background-color: ${FordStyles.color.lightBlue2};
  border-color: ${FordStyles.color.darkBlue};
  border-width: 1px;
  border-left-width: 10px;
  border-style: solid;
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 0 auto;
`;

export const InfoIconDiv = styled.div`
  float: left;
  display: table-cell;
  margin-right: 10px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 100%;
`;

export const BannerMessageDiv = styled.div`
  text-align: left;
  display: table-cell;
  font-size: 0.875em;
`;

export const InfoIconForBanner = styled(InfoDotIcon)`
  && g {
    fill: ${FordStyles.color.darkBlue};
  }
`;

export const TitleSpan = styled.div`
  display: block;
  font-family: ${FordStyles.fontFamilyBold};
  margin-bottom: 0.4em;
`;
