import { Button, paymentButtonProcessing, translationKeys } from '@fpc/common';
import i18next from '@fpc/common/i18n';
import { FormEventHandler } from 'react';

interface PayButtonProps {
  isFormComplete: boolean;
  isSubmitProcessing: boolean;
  paymentButtonText: string;
  onClick: FormEventHandler;
  paymentSucceeded?: boolean;
}
export function PayButton(props: PayButtonProps) {
  function getButtonText() {
    if (props.isSubmitProcessing) {
      return props.paymentSucceeded
        ? paymentButtonProcessing(
            i18next.t<string>(translationKeys.mit.successful)
          )
        : paymentButtonProcessing();
    }
    return props.paymentButtonText;
  }
  function disableSubmit() {
    return !props.isFormComplete || props.isSubmitProcessing;
  }
  return (
    <Button
      data-testid="cta-button"
      disabled={disableSubmit()}
      onClick={props.onClick}
      id="submit"
    >
      {getButtonText()}
    </Button>
  );
}
