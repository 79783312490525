export default {
  'errors.code.expired_card': 'La tua carta è scaduta.',
  'errors.code.incorrect_cvc':
    'Il codice di sicurezza della tua carta non è corretto.',
  'errors.code.incorrect_number': 'Il numero della tua carta non è corretto.',
  'errors.code.incorrect_zip':
    'Il numero della carta e il CAP non corrispondono.',
  'errors.code.invalid_cvc':
    'Il codice di sicurezza della tua carta non è valido.',
  'errors.code.invalid_expiry_month':
    'La data di scadenza della tua carta non è valida.',
  'errors.code.invalid_expiry_month_past':
    'La data di scadenza della tua carta è passata.',
  'errors.code.invalid_expiry_year':
    "L'anno di scadenza della carta non è valido.",
  'errors.code.invalid_expiry_year_past':
    "L'anno di scadenza della carta è passato.",
  'errors.code.invalid_number': 'Il numero della carta non è valido.',
  'errors.code.processing_error':
    "Si è verificato un errore durante l'elaborazione della tua carta. Riprova tra poco.",
  'errors.declines.call_issuer':
    'La tua carta è stata rifiutata. Contatta la tua banca per maggiori dettagli.',
  'errors.declines.card_not_supported': 'La tua carta non è supportata.',
  'errors.declines.card_velocity_exceeded':
    'La tua carta è stata rifiutata a causa di tentativi ripetuti e troppo frequenti.',
  'errors.declines.currency_not_supported':
    'La tua carta non supporta questa valuta.',
  'errors.declines.generic_decline': 'La tua carta è stata rifiutata.',
  'errors.declines.incorrect_pin': 'PIN errato.',
  'errors.declines.insufficient_funds':
    'Il saldo sulla tua carta è insufficiente.',
  'errors.declines.invalid_account': 'Conto non valido.',
  'errors.declines.invalid_amount': 'Importo non valido.',
  'errors.declines.not_permitted':
    'Titolare di carta non iscritto/non autorizzato',
  'errors.declines.pin_try_exceeded':
    'Numero massimo consentito di tentativi PIN superato.',
  'errors.declines.transaction_not_allowed':
    'La tua carta non supporta questo tipo di acquisto.',
  'errors.code.processing_error_intransient':
    'Si è verificato un problema. Riprova più tardi.',
  'errors.declines.generic_decline_link':
    'La tua modalità di pagamento è stata rifiutata.',
  'errors.declines.generic_decline_non_cards':
    'Il tuo pagamento è stato rifiutato.'
};
