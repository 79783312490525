export default {
  'errors.code.expired_card': 'Je kaart is vervallen.',
  'errors.code.incorrect_cvc': 'De beveiligingscode van je kaart is onjuist.',
  'errors.code.incorrect_number': 'Je kaartnummer is onjuist.',
  'errors.code.incorrect_zip': 'Je kaartnummer en postcode komen niet overeen.',
  'errors.code.invalid_cvc': 'De beveiligingscode van je kaart is ongeldig.',
  'errors.code.invalid_expiry_month':
    'De vervaldatum van je kaart is ongeldig.',
  'errors.code.invalid_expiry_month_past':
    'De vervaldatum van je kaart ligt in het verleden.',
  'errors.code.invalid_expiry_year': 'Het vervaljaar van je kaart is ongeldig.',
  'errors.code.invalid_expiry_year_past':
    'Het vervaljaar van je kaart ligt in het verleden.',
  'errors.code.invalid_number': 'Je kaartnummer is ongeldig.',
  'errors.code.processing_error':
    'Er is een fout opgetreden bij de verwerking van je kaart. Probeer het later opnieuw.',
  'errors.declines.call_issuer':
    'Je kaart is geweigerd. Neem contact op met je bank voor meer informatie.',
  'errors.declines.card_not_supported': 'Je kaart wordt niet ondersteund.',
  'errors.declines.card_velocity_exceeded':
    'Je kaart is geweigerd vanwege te veel herhaalde pogingen.',
  'errors.declines.currency_not_supported':
    'Je kaart wordt niet ondersteund voor deze valuta.',
  'errors.declines.generic_decline': 'Je kaart is geweigerd.',
  'errors.declines.incorrect_pin': 'Onjuiste pincode.',
  'errors.declines.insufficient_funds': 'Je kaart heeft onvoldoende saldo.',
  'errors.declines.invalid_account': 'Ongeldige rekening.',
  'errors.declines.invalid_amount': 'Ongeldig bedrag.',
  'errors.declines.not_permitted': 'Kaartlid niet ingeschreven/niet toegestaan',
  'errors.declines.pin_try_exceeded':
    'Het toegestane aantal pogingen voor de pincode is overschreden.',
  'errors.declines.transaction_not_allowed':
    'Dit type aankoop wordt niet door je kaart ondersteund.',
  'errors.code.processing_error_intransient':
    'Er is iets misgegaan. Probeer het later nog eens.',
  'errors.declines.generic_decline_link': 'Je betaalmethode is geweigerd.',
  'errors.declines.generic_decline_non_cards': 'Je betaling is geweigerd.'
};
