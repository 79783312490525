import { translationKeys, UnstyledButtonWithFocus } from '@fpc/common';
import i18next from '@fpc/common/i18n';
import { Style } from '@fpc/common/Styles';
import { ReactComponent as CloseIcon } from '@fpc/common/icons/close_16x16.svg';

interface CanCloseButtonProps {
  handleClose: (value: void) => void;
}
export function CanCloseButton(props: CanCloseButtonProps) {
  return (
    <UnstyledButtonWithFocus
      aria-label={i18next.t<string>(translationKeys.common.close)}
      onClick={() => props.handleClose()}
      focusColor={Style.color.secondary}
      type={'button'}
    >
      <CloseIcon
        data-testid={'close-new-payment-method'}
        style={{
          alignSelf: 'center',
          width: '24px',
          height: '24px',
          marginRight: '1.5em',
          flexBasis: 'content'
        }}
      />
    </UnstyledButtonWithFocus>
  );
}
