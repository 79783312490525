export default {
  'errors.code.expired_card': 'บัตรของคุณหมดอายุแล้ว',
  'errors.code.incorrect_cvc': 'รหัสความปลอดภัยของบัตรไม่ถูกต้อง',
  'errors.code.incorrect_number': 'หมายเลขบัตรของคุณไม่ถูกต้อง',
  'errors.code.incorrect_zip': 'หมายเลขบัตรของคุณกับรหัสไปรษณีย์ไม่ตรงกัน',
  'errors.code.invalid_cvc': 'รหัสความปลอดภัยของบัตรไม่ถูกต้อง',
  'errors.code.invalid_expiry_month': 'วันที่หมดอายุของบัตรไม่ถูกต้อง',
  'errors.code.invalid_expiry_month_past':
    'วันที่หมดอายุของบัตรเป็นวันที่ผ่านมาแล้ว',
  'errors.code.invalid_expiry_year': 'ปีหมดอายุของบัตรไม่ถูกต้อง',
  'errors.code.invalid_expiry_year_past': 'ปีหมดอายุของบัตรเป็นปีที่ผ่านมาแล้ว',
  'errors.code.invalid_number': 'หมายเลขบัตรของคุณไม่ถูกต้อง',
  'errors.code.processing_error':
    'เกิดข้อผิดพลาดขณะประมวลผลบัตรของคุณ โปรดลองอีกครั้งในอีกสักครู่',
  'errors.declines.call_issuer':
    'บัตรของคุณถูกปฏิเสธ คุณสามารถโทรติดต่อธนาคารเพื่อขอทราบรายละเอียดได้',
  'errors.declines.card_not_supported': 'ระบบไม่รองรับบัตรของคุณ',
  'errors.declines.card_velocity_exceeded':
    'บัตรของคุณถูกปฏิเสธเนื่องจากมีการพยายามใช้ซ้ำๆ บ่อยครั้งเกินไป',
  'errors.declines.currency_not_supported':
    'ระบบไม่รองรับบัตรของคุณสำหรับสกุลเงินนี้',
  'errors.declines.generic_decline': 'บัตรของคุณถูกปฏิเสธ',
  'errors.declines.incorrect_pin': 'PIN ไม่ถูกต้อง',
  'errors.declines.insufficient_funds': 'บัตรของคุณมีเงินไม่เพียงพอ',
  'errors.declines.invalid_account': 'บัญชีไม่ถูกต้อง',
  'errors.declines.invalid_amount': 'จำนวนเงินไม่ถูกต้อง',
  'errors.declines.not_permitted':
    'สมาชิกบัตรที่ไม่ได้ลงทะเบียน / ไม่ได้รับอนุญาต',
  'errors.declines.pin_try_exceeded': 'ป้อน PIN เกินจำนวนครั้งที่อนุญาตแล้ว',
  'errors.declines.transaction_not_allowed':
    'บัตรของคุณไม่รองรับการซื้อประเภทนี้',
  'errors.code.processing_error_intransient':
    'เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้งในภายหลัง',
  'errors.declines.generic_decline_link': 'การชำระเงินของคุณถูกปฏิเสธ',
  'errors.declines.generic_decline_non_cards': 'การชำระเงินของคุณถูกปฏิเสธ'
};
