import {
  loadStripe,
  Stripe,
  StripeElementLocale,
  StripeElements
} from '@stripe/stripe-js';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '@fpc/common';
import { ACSS_DEBIT } from 'checkout';

export function initializeStripe(
  merchantAccountId?: string,
  transactionTypes?: string[]
): Promise<Stripe | null> {
  const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY ?? '';
  if (merchantAccountId === '') {
    console.warn('No account ID');
    return Promise.resolve(null);
  }

  const locale = getStripeElementLocale(i18next.language);

  let betas = [
    'pay_by_bank_beta_1',
    'elements_enable_deferred_intent_beta_1',
    'blocked_card_brands_beta_2'
  ];
  if (transactionTypes?.includes(ACSS_DEBIT)) {
    betas = [
      'payment_element_vertical_layout_beta_1',
      'pay_by_bank_beta_1',
      'pay_button_element_beta_1',
      'elements_enable_deferred_intent_beta_1',
      'blocked_card_brands_beta_2'
    ];
  }
  return loadStripe(publishableKey, {
    stripeAccount: merchantAccountId,
    betas: betas,
    locale: locale
  });
}

export async function handleStripeConfirmSetup(
  stripe: Stripe,
  elements: StripeElements,
  errorCallback?: (error: unknown) => void
) {
  return await stripe
    .confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: 'if_required'
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
      return {
        setupIntent: null,
        error: {
          message: i18next.t<string>(
            translationKeys.common.technicalErrorPayment
          )
        }
      };
    });
}

export function isStripeElementLocale(
  localeString: string
): localeString is StripeElementLocale {
  const stripeElementLocales = [
    'auto',
    'ar',
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'en-AU',
    'en-CA',
    'en-NZ',
    'en-GB',
    'es',
    'es-ES',
    'es-419',
    'et',
    'fi',
    'fr',
    'fr-FR',
    'he',
    'hu',
    'id',
    'it',
    'it-IT',
    'ja',
    'ko',
    'lt',
    'lv',
    'ms',
    'mt',
    'nb',
    'nl',
    'no',
    'pl',
    'pt',
    'pt-BR',
    'ro',
    'ru',
    'sk',
    'sl',
    'sv',
    'th',
    'tr',
    'vi',
    'zh',
    'zh-HK',
    'zh-TW'
  ];
  return stripeElementLocales.includes(localeString);
}

export function getStripeElementLocale(
  localeString: string
): StripeElementLocale {
  const firstTwo = localeString.substring(0, 2);
  if (isStripeElementLocale(localeString)) {
    return localeString;
  } else if (isStripeElementLocale(firstTwo)) {
    return firstTwo;
  } else {
    return localeString as StripeElementLocale;
  }
}
