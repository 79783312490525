import { enUS, TRANSLATION_MISSING } from './en-US';

export const enTH: typeof enUS = {
  common: {
    default: 'default',
    endingIn: 'ending in',
    close: 'Close',
    makeDefaultCard: 'Make default card',
    makeDefaultInfoHover:
      "This will automatically become your default card, because it's the first card in your wallet.",
    processing: 'Processing ...',
    saveCardCheck: 'Save card for future transactions',
    saveCardSuccess: 'Card Added to Ford.com Wallet.',
    technicalErrorPayment:
      'Your transaction has failed. Please ensure that your card is 3DS enabled. Please check with your card issuer for more details.',
    timeoutSorry: 'Sorry ...',
    timeoutError: "We're experiencing technical difficulties right now.",
    timeoutTryAgain: 'Try Again',
    savePaymentMethodCheck: 'Save payment method for future transactions',
    makeDefaultPaymentMethod: 'Make default payment method',
    savePaymentMethodButton: 'Save Payment Method',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'This will automatically become your default payment method, because it is the first payment method in your wallet.',
    savePaymentMethodSuccess: 'Payment Method Added to Wallet.'
  },
  checkout: {
    payButton: 'Pay {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Sorry! We experienced a technical problem, and your payment did not process. Please refresh the page and try again.',
    technicalErrorPaymentDetachFail:
      'Sorry! We experienced a technical problem, and your payment did not process. Your card was saved to your wallet. Please refresh the page and try again.',
    tosTextBefore: "By clicking the 'Pay' button below, you agree to the ",
    tosLinkText: 'Payment Terms and Conditions',
    tosTextAfter: '.',
    useNewCard: 'Use New Payment Method',
    useNewCardDropdownText: 'Use new payment method',
    useNewCardTextBefore: 'Check out with saved payment method or ',
    useNewCardLinkText: 'use new payment method',
    useNewCardTextAfter: '.',
    cardSelect: 'Credit Card Select',
    checkoutFaster: 'Check out faster with FordPay.',
    checkout: 'Checkout',
    nameOnCard: 'Name on Card',
    fullName: 'Full Name',
    cardNumber: 'Card Number',
    expirationDate: 'Exp. Date',
    securityCode: 'Security Code',
    invalidCCNMessage: 'Your card number is not valid.',
    invalidCVVMessage: 'Your security code is not valid.',
    invalidExpDateMessage: 'Your expiration date is not valid.',
    emptyCCNMessage: 'Your card number is empty.',
    emptyCVVMessage: 'Your security code is empty.',
    emptyExpDateMessage: 'Your expiration date is empty.',
    unsupportedCardMessage:
      'Your card number is not supported. Please enter VISA, MasterCard or AMEX.',
    paymentNotProcessed: 'Your payment could not be processed',
    redirectStatusFailure:
      'Your payment could not be processed.  Please try again.',
    continue: 'Continue {{formattedCurrency}}',
    disclaimer: 'Disclaimer',
    tosTextBeforeContinue:
      "By clicking the 'Continue' button below, you agree to the ",
    authCheckoutStatement:
      'Check out with saved card, use new payment method, or use Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Check out with saved card, ',
    usePayByBank: ', or use ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Use Pay By Bank',
    use: 'Use ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Use new payment method',
    useNewPaymentDropdownText: 'use new payment method',
    useNewPaymentTextBefore: 'Check out with saved payment method or ',
    useNewPaymentLinkText: 'use new payment method',
    useNewPaymentTextBeforePayByBank: 'Check out with saved payment method, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText: TRANSLATION_MISSING,
    addPaymentMethod: TRANSLATION_MISSING,
    makeDefault: TRANSLATION_MISSING,
    changeDefaultPMSuccess: TRANSLATION_MISSING,
    makeDefaultSuccessPM: TRANSLATION_MISSING,
    makeDefaultErrorPM: TRANSLATION_MISSING,
    removePMSuccess: TRANSLATION_MISSING,
    removePMError: TRANSLATION_MISSING,
    removePM: TRANSLATION_MISSING,
    removePMQuestion: TRANSLATION_MISSING,
    removePMText: TRANSLATION_MISSING,
    defaultCard: TRANSLATION_MISSING,
    defaultPM: TRANSLATION_MISSING,
    defaultCardQuestion: TRANSLATION_MISSING,
    defaultPMQuestion: TRANSLATION_MISSING,
    defaultCardText: TRANSLATION_MISSING,
    defaultPMText: TRANSLATION_MISSING,
    navigationLabel: TRANSLATION_MISSING,
    removeDefaultPMSuccess: TRANSLATION_MISSING,
    removeDefaultCardError: TRANSLATION_MISSING,
    removeDefaultPMError: TRANSLATION_MISSING,
    removeDefaultCard: TRANSLATION_MISSING,
    removeDefaultPM: TRANSLATION_MISSING,
    removeDefaultPMText: TRANSLATION_MISSING,
    removeDefaultPMButton: TRANSLATION_MISSING,
    removeDefaultSelectPM: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    expiration: TRANSLATION_MISSING,
    expires: TRANSLATION_MISSING,
    stripeDefaultError: TRANSLATION_MISSING,
    paymentMethod: TRANSLATION_MISSING,
    tosTextBefore: TRANSLATION_MISSING,
    tosTextBeforePM: TRANSLATION_MISSING,
    tosLinkText: TRANSLATION_MISSING,
    tosTextAfter: TRANSLATION_MISSING
  },
  transaction: {
    all: TRANSLATION_MISSING,
    amount: TRANSLATION_MISSING,
    date: TRANSLATION_MISSING,
    description: TRANSLATION_MISSING,
    displayingAll: TRANSLATION_MISSING,
    displayingDays: TRANSLATION_MISSING,
    displayingYear: TRANSLATION_MISSING,
    filter: TRANSLATION_MISSING,
    loadAll: TRANSLATION_MISSING,
    loadMore: TRANSLATION_MISSING,
    noTransactions: TRANSLATION_MISSING,
    paymentMethod: TRANSLATION_MISSING,
    refund: TRANSLATION_MISSING,
    searchBar: TRANSLATION_MISSING,
    timeFilter: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    pending: TRANSLATION_MISSING
  },
  mit: {
    confirmPurchase: TRANSLATION_MISSING,
    paymentSuccessful: TRANSLATION_MISSING,
    saveCardAlert: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    tosTextBefore: TRANSLATION_MISSING,
    tosLinkText: TRANSLATION_MISSING,
    tosTextAfter: TRANSLATION_MISSING,
    useNewCard: TRANSLATION_MISSING,
    useNewCardDropDownItem: TRANSLATION_MISSING,
    useNewCardTextBefore: TRANSLATION_MISSING,
    useNewCardLinkText: TRANSLATION_MISSING,
    useNewCardTextAfter: TRANSLATION_MISSING,
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: TRANSLATION_MISSING,
    useNewPaymentDropdownText: TRANSLATION_MISSING,
    useNewPaymentTextBefore: TRANSLATION_MISSING,
    useNewPaymentLinkText: TRANSLATION_MISSING,
    successful: TRANSLATION_MISSING,
    savePaymentMethodAlert: TRANSLATION_MISSING
  }
};
