/**
 * Do not use directly - import Style from Styles.tsx to
 * simplify switching Ford/Lincoln when we need to support
 * Lincoln branding.
 */
export const FordStyles = {
  color: {
    primary: '#00095b',
    primary5: 'rgba(0, 9, 91, 0.05)',
    secondary: '#1700f4',
    tertiary: '#00142e',
    quaternary: '#f4f4f4',
    lightBlue: '#529EFE',
    lightBlue2: '#E6F1FD',
    darkBlue: '#066FEF',
    white: '#ffffff',
    gray2: '#6e6e6e',
    gray3: '#4d4d4d',
    gray4: '#919191',
    disabled: '#6e6e6e',
    alert: '#ba4e00',
    error: '#af0000',
    success: '#008200',
    text: '#ffffff',
    hover: '#0275EB'
  },

  baseFontSize: '16px',
  fontFamily: `FordAntenna, Arial, Helvetica, sans-serif`,
  fontFamilyBold: `FordAntenna-bold, "Arial Bold", Arial, Helvetica, sans-serif`,
  fontFamilyCondMedium: `FordAntenna-cond-medium, "Arial Narrow", "Arial Condensed", Arial, Helvetica, sans-serif`,
  fontFamilyCondRegular: `FordAntenna-cond-regular, "Arial Narrow", "Arial Condensed", Arial, Helvetica, sans-serif`,
  fontFamilyCondLight: `FordAntenna-cond-light, "Arial Narrow", "Arial Condensed Light", Arial, Helvetica, sans-serif`,

  boxShadow1: `0 1em 1em 0 rgba(0, 0, 0, 0.1)`,
  boxShadow2: `0 1em 1em 0 rgba(0, 0, 0, 0.1), 0 2em 2em 0 rgba(0, 0, 0, 0.1), 0 3em 3em 0 rgba(0, 0, 0, 0.15)`,
  boxShadow3: `0 1.1em 1.1em 0 rgba(0, 0, 0, 0.1), 0 2.1em 2.1em 0 rgba(0, 0, 0, 0.1), 0 3.2em`
};
