import { Style } from '../Styles';
import { CSSObjectWithLabel, StylesConfig } from 'react-select';

export const commonDropDownStyles = {
  color: Style.color.primary,
  fontSize: '1em',
  height: '50px'
};

export const dropDownContainerStyles = {
  color: 'rgb(0,0,0,0)',
  maxWidth: '500px',
  width: '100%',
  cursor: 'pointer',
  zIndex: '51'
};

export const dropDownValueContainerStyles: CSSObjectWithLabel = {
  ...commonDropDownStyles,
  justifyItems: 'start',
  flexShrink: '3',
  overflow: 'hidden',
  input: { position: 'absolute' }
};

export const dropDownOptionStyles: CSSObjectWithLabel = {
  ...commonDropDownStyles,
  textAlign: 'start',
  display: 'flex',
  alignItems: 'center',
  color: Style.color.primary,
  cursor: 'pointer'
};

export const dropDownControlStyles: CSSObjectWithLabel = {
  ...commonDropDownStyles,
  cursor: 'pointer',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  '&:hover': {
    borderColor: Style.color.hover
  },
  position: 'initial'
};

export const dropDownSingleValueStyles: CSSObjectWithLabel = {
  ...commonDropDownStyles,
  display: 'flex',
  alignItems: 'center'
};

export const dropDownPlaceholderStyles: CSSObjectWithLabel = {
  ...commonDropDownStyles,
  color: Style.color.primary,
  display: 'flex',
  paddingLeft: '10px',
  alignItems: 'center'
};

export const dropDownMenuStyles: CSSObjectWithLabel = {
  marginTop: '0px',
  borderTopRightRadius: '0px',
  borderTopLeftRadius: '0px',
  border: `1px ${Style.color.gray4} solid`,
  borderTop: '0px',
  boxShadow: 'inherit',
  zIndex: '100'
};

export const dropDownIndicatorsContainerStyles: CSSObjectWithLabel = {
  height: '100%',
  flexShrink: '0',
  pointerEvents: 'none'
};

export const dropDownStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided,
    ...dropDownContainerStyles,
    boxShadow: state.isFocused ? Style.boxShadow3 : 'none'
  }),
  valueContainer: () => ({
    ...dropDownValueContainerStyles
  }),
  option: (provided, state) => ({
    ...provided,
    ...dropDownOptionStyles,
    backgroundColor: state.isFocused ? `${Style.color.lightBlue}50` : 'white',
    '&:hover': {
      backgroundColor: `${Style.color.lightBlue}50`
    }
  }),
  control: (provided, state) => ({
    ...provided,
    ...dropDownControlStyles,
    borderBottomRightRadius: state.menuIsOpen ? '0px' : '4px',
    borderBottomLeftRadius: state.menuIsOpen ? '0px' : '4px',
    border:
      state.isFocused && !state.menuIsOpen
        ? `2px ${Style.color.hover} solid`
        : `1px ${Style.color.gray4} solid`
  }),
  singleValue: (provided) => ({
    ...provided,
    ...dropDownSingleValueStyles,
    paddingLeft: '10px'
  }),
  placeholder: (provided) => ({
    ...provided,
    ...dropDownPlaceholderStyles
  }),
  menu: (provided) => ({
    ...provided,
    ...dropDownMenuStyles
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided) => ({
    ...provided,
    ...dropDownIndicatorsContainerStyles,
    padding: '0.5em'
  })
};
