import styled from 'styled-components/macro';
import { Style } from '../Styles';
import { Button } from './Button';

export const ButtonOutline = styled(Button)`
  && {
    color: ${Style.color.primary};
  }

  &&:hover {
    background-color: ${Style.color.white};
    color: ${Style.color.secondary};
    border-color: ${Style.color.secondary};
  }

  &&:hover::after {
    background-color: ${Style.color.white};
    color: ${Style.color.secondary};
    border-color: ${Style.color.secondary};
  }

  &&::after {
    background-color: ${Style.color.white};
    color: ${Style.color.primary};
    border-color: ${Style.color.primary};
  }

  &&:disabled::after {
    background-color: ${Style.color.white};
    color: ${Style.color.disabled};
    border-color: ${Style.color.disabled};
    box-shadow: none;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
`;
