import ReactModal from 'react-modal';
import { ReactNode, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../icons/close_16x16.svg';
import styled from 'styled-components/macro';
import { Style } from '../Styles';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '../i18n/en-US';
import { UnstyledButtonWithFocus } from './UnstyledButton';

interface ModalProps {
  open: boolean;
  children: ReactNode;
  setOpen: (open: boolean) => void;
  divId: string;
  title?: string;
  width?: string;
  centerAlignTitle?: boolean;
  titleFontSize?: number;
  isMobile?: boolean;
}

const ModalBody = styled.div`
  && {
    font-family: ${Style.fontFamily};
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }

  && h1,
  p {
    font-family: ${Style.fontFamily};
    color: ${Style.color.primary};
  }

  && p {
    font-size: 1.2em;
    margin: 0 1em 2em 1em;
  }
`;

const ModalHeaderBar = styled.div`
  && {
    display: flex;
    flex-direction: row;
  }
`;

const ModalHeader = styled.h2<{
  centerAlignTitle?: boolean;
  titleFontSize?: number;
}>`
  && {
    color: ${Style.color.primary};
    font-size: ${(props) =>
      props.titleFontSize ? `${props.titleFontSize}em` : '1.3em'};
    letter-spacing: 0;
    text-align: ${(props) => (props.centerAlignTitle ? 'center' : 'left')};
    font-weight: normal;
    width: 100%;
  }
`;

export function ModalDisplay(props: ModalProps) {
  const modalContainerStyle: ReactModal.Styles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
      zIndex: '10000',
      overflowY: 'auto'
    },
    content: {
      inset: '0',
      position: 'relative',
      margin: 'auto',
      color: Style.color.gray3,
      width: props.width,
      zIndex: '10000',
      overflow: 'visible',
      padding: 'none',
      paddingBlockStart: '1em',
      paddingBlockEnd: '1.75em',
      paddingInline: '1.75em',
      maxWidth: '90vw',
      height: 'fit-content',
      fontSize: Style.baseFontSize
    }
  };

  useEffect(() => {
    ReactModal.setAppElement(`#${props.divId}`);
  }, []);

  function close() {
    props.setOpen(false);
  }

  return (
    <ReactModal
      isOpen={props.open}
      onRequestClose={close}
      style={modalContainerStyle}
      shouldCloseOnOverlayClick={true}
      contentLabel={'confirm selection'}
    >
      <ModalBody data-testid={'modal-body'}>
        <ModalHeaderBar>
          {props.title && (
            <ModalHeader
              data-testid={'modal-title'}
              centerAlignTitle={props.centerAlignTitle}
              titleFontSize={props.titleFontSize}
            >
              {props.title}
            </ModalHeader>
          )}
          <span style={{ flexGrow: '1' }} />
          <UnstyledButtonWithFocus
            onClick={close}
            aria-label={i18next.t<string>(translationKeys.common.close)}
            focusColor={Style.color.secondary}
          >
            <CloseIcon
              data-testid={'modal-close'}
              style={{
                alignSelf: 'center',
                width: '24px',
                height: '24px',
                flexBasis: 'content',
                cursor: 'pointer'
              }}
            />
          </UnstyledButtonWithFocus>
        </ModalHeaderBar>
        <div>{props.children}</div>
      </ModalBody>
    </ReactModal>
  );
}
