export const supportedLanguages = [
  'en-US',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'el-GR',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-NZ',
  'es-ES',
  'es-US',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'hu-HU',
  'it-CH',
  'it-IT',
  'nl-BE',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-PT',
  'ro-RO',
  'sv-SE',
  'th-TH',
  'en-TH',
  'en-ZA',
  'vi-VN'
] as const;
export type Language = typeof supportedLanguages[number];
