import { WalletPaymentMethod } from '../../account/wallet/WalletPaymentMethod';
import styled from 'styled-components/macro';
import { PaymentMethodPumaBank } from '@fpc/api/paymentapp';

interface BankIconTileProps {
  bank: PaymentMethodPumaBank;
  onClick: () => void;
}

export function BankIconTile(props: BankIconTileProps) {
  function convertBankNameToFilePath(bankName: string): string {
    return bankName.replaceAll(/[()]/g, '').replaceAll(' ', '-');
  }

  function getFilePathForIcon(): string {
    return `${process.env.ASSETS}/icons/banks/uk/${convertBankNameToFilePath(
      props.bank.name
    )}.svg`;
  }

  return (
    <WalletPaymentMethod
      id={`payment-method-options-${props.bank.providerCode}`}
      type={'button'}
      data-testid={`payment-method-options-${props.bank.providerCode}`}
      isPumaBank
      onClick={props.onClick}
    >
      <PumaBankLogoImg src={getFilePathForIcon()} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '2.7em'
        }}
      >
        <span style={{ fontSize: '0.7em', color: 'black', marginTop: '2.5em' }}>
          {props.bank.name}
        </span>
      </div>
    </WalletPaymentMethod>
  );
}

const PumaBankLogoImg = styled.img<{}>`
  height: 4em;
  width: 4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 360px) {
    height: 5em;
    width: 5em;
  }
`;
