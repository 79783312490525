import { statusReject } from '../statusReject';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export async function createBluesnapPaymentToken(
  bearerToken?: string,
  bffBaseUrl?: string | null,
  digitalSignature?: string,
  paymentInfoToken?: string
): Promise<string> {
  let targetServer, authHeader;
  if (bffBaseUrl) {
    targetServer = `${bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}/api/v2/payment-token`;
    authHeader = '';
  } else {
    targetServer = `${process.env.PAYMENT_APP_SERVER}/api/payment-token`;
    authHeader = 'Bearer ' + bearerToken;
  }
  let headers = HeadersBuilder();
  if (digitalSignature && !bffBaseUrl) {
    headers.set('Authorization', `${digitalSignature}`);
    return fetch(
      `${process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER}/api/dss/payment-token`,
      {
        method: 'POST',
        headers: headers,
        body: paymentInfoToken
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            return data.paymentToken;
          });
        } else {
          return Promise.reject(statusReject(res));
        }
      })
      .catch((err) => {
        console.warn('Error submitting payment request: ', err);
        return Promise.reject(err);
      });
  } else {
    headers.set('Authorization', `${authHeader}`);
    return fetch(`${targetServer}`, {
      method: 'GET',
      headers: headers
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            return data.paymentToken;
          });
        } else {
          return Promise.reject(statusReject(res));
        }
      })
      .catch((err) => {
        console.warn('Error submitting payment request: ', err);
        return Promise.reject(err);
      });
  }
}
