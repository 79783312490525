import { statusReject } from '../statusReject';
import { PaymentMethodPumaBank } from '@fpc/api/paymentapp/index';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export interface InitiateBankPaymentResponse {
  bankSiteRedirectUrl: string;
}

export async function initiatePumaBankPayment(
  merchantCountryCode: string,
  signedPaymentInfoToken: string,
  selectedBank: PaymentMethodPumaBank,
  merchantRedirectUrl: string
): Promise<InitiateBankPaymentResponse> {
  return fetch(
    getRequest(
      merchantCountryCode,
      signedPaymentInfoToken,
      selectedBank,
      merchantRedirectUrl
    )
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getRequest(
  country: string,
  signedPaymentInfoToken: string,
  selectedBank: PaymentMethodPumaBank,
  merchantRedirectUrl: string
): Request {
  const requestUrl = process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER;

  const requestBody = {
    paymentInfo: JSON.parse(signedPaymentInfoToken).paymentInfo,
    merchantRedirectUrl: merchantRedirectUrl,
    merchantCountryCode: country,
    bankProvider: selectedBank
  };

  return new Request(`${requestUrl}/api/dss/initiate-bank-payment`, {
    headers: getHeaders(signedPaymentInfoToken),
    method: 'POST',
    body: JSON.stringify(requestBody)
  });
}

function getHeaders(signedPaymentInfoToken: string): Headers {
  let headers: Headers = HeadersBuilder();
  headers.set('Authorization', JSON.parse(signedPaymentInfoToken).signature);
  return headers;
}
