import { enUS, TRANSLATION_MISSING } from './en-US';

export const frCA: typeof enUS = {
  common: {
    default: 'par d\u00e9faut',
    endingIn: 'se terminant par',
    close: 'Fermer',
    makeDefaultCard: 'D\u00e9finir comme carte par d\u00e9faut',
    makeDefaultInfoHover:
      'Cette carte deviendra automatiquement votre carte par d\u00e9faut, puisqu\u2019il s\u2019agit de la premi\u00e8re carte de votre portefeuille.',
    processing: 'En cours de traitement...',
    saveCardCheck: 'Enregistrer la carte pour les transactions futures',
    saveCardSuccess: 'Carte ajout\u00e9e au portefeuille Ford.com.',
    technicalErrorPayment:
      'D\u00e9sol\u00e9s! Il y a eu un probl\u00e8me technique et votre paiement n\u2019a pu \u00eatre trait\u00e9. Veuillez essayer \u00e0 nouveau.',
    timeoutSorry: 'D\u00e9sol\u00e9s...',
    timeoutError:
      'Nous \u00e9prouvons actuellement des difficult\u00e9s techniques.',
    timeoutTryAgain: 'Essayez \u00e0 nouveau',
    savePaymentMethodCheck:
      'Enregistrer le mode de paiement pour les transactions futures',
    makeDefaultPaymentMethod:
      'D\u00e9finir comme mode de paiement par d\u00e9faut',
    savePaymentMethodButton: 'Enregistrer le mode de paiement',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      "Cela deviendra automatiquement votre moyen de paiement par d\u00e9faut, car il s'agit du premier moyen de paiement de votre portefeuille.",
    savePaymentMethodSuccess:
      'Le mode de paiement a \u00e9t\u00e9 ajout\u00e9 \u00e0 Wallet.'
  },
  checkout: {
    payButton: 'Payer {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'D\u00e9sol\u00e9s! Nous avons \u00e9prouv\u00e9 un probl\u00e8me technique et votre paiement n\u2019a pu \u00eatre trait\u00e9. Veuillez actualiser la page et essayer \u00e0 nouveau. ',
    technicalErrorPaymentDetachFail:
      'D\u00e9sol\u00e9s! Il y a eu un probl\u00e8me technique et votre paiement n\u2019a pu \u00eatre trait\u00e9. Votre carte a \u00e9t\u00e9 enregistr\u00e9e dans votre portefeuille. Veuillez essayer \u00e0 nouveau.',
    tosTextBefore:
      'En cliquant sur le bouton \u00ab\u00a0Payer\u00a0\u00bb ci-dessous, vous consentez aux ',
    tosLinkText: 'Conditions et modalit\u00e9s de paiement',
    tosTextAfter: '.',
    useNewCard: 'Utiliser une nouvelle carte',
    useNewCardDropdownText: 'Ajouter une nouvelle carte',
    useNewCardTextBefore:
      'Passer \u00e0 la caisse avec la carte enregistr\u00e9e ou ',
    useNewCardLinkText: 'utiliser un nouveau mode de paiement',
    useNewCardTextAfter: '.',
    cardSelect: 'S\u00e9lectionner la carte de cr\u00e9dit',
    checkoutFaster: 'Passer \u00e0 la caisse plus rapidement avec FordPay.',
    checkout: 'Passer \u00e0 la caisse',
    nameOnCard: 'Nom sur la carte',
    fullName: 'Nom au complet',
    cardNumber: 'Num\u00e9ro de carte',
    expirationDate: 'Exp. Date',
    securityCode: 'Code de s\u00e9curit\u00e9',
    invalidCCNMessage: "Votre num\u00e9ro de carte n'est pas valide.",
    invalidCVVMessage: "Votre code de s\u00e9curit\u00e9 n'est pas valide.",
    invalidExpDateMessage: "Votre date d'expiration n'est pas valide.",
    emptyCCNMessage: 'Votre num\u00e9ro de carte est vide.',
    emptyCVVMessage: 'Votre code de s\u00e9curit\u00e9 est vide.',
    emptyExpDateMessage: "Votre date d'expiration est vide.",
    unsupportedCardMessage:
      "Votre num\u00e9ro de carte n'est pas pris en charge. Veuillez utiliser une carte VISA, MasterCard ou AMEX.",
    paymentNotProcessed: "Nous n'avons pas pu traiter votre paiement",
    redirectStatusFailure:
      "Nous n'avons pas pu traiter votre paiement. Veuillez essayer \u00e0 nouveau.\u00a0",
    continue: 'Continuer {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'En cliquant sur le bouton \u00ab\u00a0Continuer\u00bb ci-dessous, vous consentez aux ',
    authCheckoutStatement:
      'Payez avec une carte enregistr\u00e9e, utilisez un nouveau mode de paiement ou utilisez Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Payez avec une carte enregistr\u00e9e, ',
    usePayByBank: ', ou utilisez ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Utilisez Pay By Bank.',
    use: 'Utiliser ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Utiliser un nouveau mode de paiement',
    useNewPaymentDropdownText: 'utiliser un nouveau mode de paiement',
    useNewPaymentTextBefore:
      'Payez avec le mode de paiement enregistr\u00e9 ou ',
    useNewPaymentLinkText: 'utiliser un nouveau mode de paiement',
    useNewPaymentTextBeforePayByBank:
      'Payez avec le mode de paiement enregistr\u00e9, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: 'Pr\u00e9l\u00e8vement pr\u00e9autoris\u00e9',
    card: 'Carte bancaire',
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      "Vous n'avez actuellement aucun mode de paiement dans votre portefeuille. Commencez en ajoutant le premier mode de paiement \u00e0 votre portefeuille.",
    addPaymentMethod: 'Ajouter un mode de paiement',
    makeDefault: 'D\u00e9finir par d\u00e9faut',
    changeDefaultPMSuccess:
      'Votre mode de paiement par d\u00e9faut a \u00e9t\u00e9 modifi\u00e9.',
    makeDefaultSuccessPM:
      'M\u00e9thode de paiement ajout\u00e9e en tant que d\u00e9faut.',
    makeDefaultErrorPM:
      'Erreur lors de la d\u00e9finition de la m\u00e9thode de paiement par d\u00e9faut.',
    removePMSuccess:
      'M\u00e9thode de paiement supprim\u00e9e du portefeuille Ford.com',
    removePMError:
      'Erreur lors de la suppression de la m\u00e9thode de paiement.',
    removePM: 'Supprimer la m\u00e9thode de paiement',
    removePMQuestion: 'Supprimer la m\u00e9thode de paiement?',
    removePMText:
      '\u00cates-vous s\u00fbr(e) de vouloir supprimer la m\u00e9thode de paiement {{cardNumber}} de votre portefeuille ? Tous les abonnements actifs associ\u00e9s \u00e0 ce mode de paiement \u00e9choueront si le mode de paiement est supprim\u00e9.',
    defaultCard: 'Carte par d\u00e9faut',
    defaultPM: 'M\u00e9thode de paiement par d\u00e9faut',
    defaultCardQuestion: 'Carte par d\u00e9faut?',
    defaultPMQuestion: 'M\u00e9thode de paiement par d\u00e9faut ?',
    defaultCardText:
      '\u00cates-vous certain de vouloir changer votre carte par d\u00e9faut pour la carte {{cardNumber}}?',
    defaultPMText:
      '\u00cates-vous s\u00fbr(e) de vouloir changer votre m\u00e9thode de paiement par d\u00e9faut en {{cardNumber}} ?',
    navigationLabel: 'Navigation dans la gestion de compte',
    removeDefaultPMSuccess:
      'M\u00e9thode de paiement par d\u00e9faut supprim\u00e9e du portefeuille Ford.com',
    removeDefaultCardError:
      'Une erreur est survenue lors du retrait de la carte par d\u00e9faut.',
    removeDefaultPMError:
      'Erreur lors de la suppression de la m\u00e9thode de paiement par d\u00e9faut.',
    removeDefaultCard: 'Retirer votre carte par d\u00e9faut',
    removeDefaultPM:
      'Supprimer votre m\u00e9thode de paiement par d\u00e9faut ?',
    removeDefaultPMText:
      'La m\u00e9thode de paiement {{cardNumber}} est votre m\u00e9thode de paiement par d\u00e9faut. Veuillez choisir une nouvelle m\u00e9thode de paiement par d\u00e9faut ci-dessous. Tous les abonnements actifs associ\u00e9s \u00e0 ce mode de paiement \u00e9choueront si le mode de paiement est supprim\u00e9.',
    removeDefaultPMButton:
      'Supprimer la m\u00e9thode de paiement et mettre \u00e0 jour le d\u00e9faut',
    removeDefaultSelectPM:
      'S\u00e9lectionner une nouvelle m\u00e9thode de paiement par d\u00e9faut',
    title: 'Portefeuille',
    expiration: 'Exp.\u00a0{{date}}',
    expires: 'Expire le {{date}}',
    stripeDefaultError:
      'Il y a eu un probl\u00e8me technique. Veuillez essayer \u00e0 nouveau.\u00a0',
    paymentMethod: 'Mode de paiement',
    tosTextBefore:
      'En cliquant sur le bouton \u00ab\u00a0Enregistrer la carte\u00a0\u00bb ci-dessous, vous consentez aux ',
    tosTextBeforePM:
      'S\u00e9lectionner une nouvelle m\u00e9thode de paiement par d\u00e9faut',
    tosLinkText: 'Conditions et modalit\u00e9s de paiement',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Toutes',
    amount: 'Montant',
    date: 'Date',
    description: 'Description',
    displayingAll: 'Afficher toutes les transactions.',
    displayingDays:
      'Afficher toutes les transactions depuis les {{numberOfDays}} derniers jours.',
    displayingYear: 'Afficher toutes les transactions depuis {{year}}.',
    filter: '{{numberOfDays}}\u00a0derniers jours',
    loadAll: 'Les voir toutes',
    loadMore: 'En voir {{x}} de plus',
    noTransactions: 'Aucune transaction \u00e0 afficher.',
    paymentMethod: 'Mode de paiement',
    refund: 'Remboursement',
    searchBar: 'Chercher par description',
    timeFilter:
      'S\u00e9lectionner une p\u00e9riode pour l\u2019historique des transactions',
    title: 'Transactions',
    pending: 'En attente'
  },
  mit: {
    confirmPurchase: "Confirmer l'achat",
    paymentSuccessful: 'Paiement r\u00e9ussi',
    saveCardAlert:
      'Pour utiliser cette carte pour les paiements futurs, elle doit \u00eatre enregistr\u00e9e dans votre portefeuille Ford.com.',
    title: 'S\u00e9lection du mode de paiement',
    tosTextBefore:
      'En cliquant sur le bouton {{paymentButtonText}} ci-dessous, vous consentez aux ',
    tosLinkText: 'Conditions et modalit\u00e9s de paiement',
    tosTextAfter: '.',
    useNewCard: 'Utiliser une nouvelle carte',
    useNewCardDropDownItem: 'Utiliser une nouvelle carte',
    useNewCardTextBefore: 'S\u00e9lectionner la carte enregistr\u00e9e ou ',
    useNewCardLinkText: 'utiliser une nouvelle carte',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Utiliser un nouveau mode de paiement',
    useNewPaymentDropdownText: 'utiliser un nouveau mode de paiement',
    useNewPaymentTextBefore:
      'Payez avec le mode de paiement enregistr\u00e9 ou ',
    useNewPaymentLinkText: 'utiliser un nouveau mode de paiement',
    successful: 'R\u00e9ussi',
    savePaymentMethodAlert:
      'Pour utiliser ce mode de paiement pour de futurs paiements, il doit \u00eatre enregistr\u00e9 dans votre portefeuille.'
  }
};
