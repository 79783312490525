export default {
  'errors.code.expired_card': 'Votre carte a expiré.',
  'errors.code.incorrect_cvc':
    'Le code de sécurité de votre carte est incorrect.',
  'errors.code.incorrect_number': 'Votre numéro de carte est incorrect.',
  'errors.code.incorrect_zip':
    'Votre numéro de carte et votre code postal ne correspondent pas.',
  'errors.code.invalid_cvc':
    "Le code de sécurité de votre carte n'est pas valide.",
  'errors.code.invalid_expiry_month':
    "La date d'expiration de votre carte n'est pas valide.",
  'errors.code.invalid_expiry_month_past':
    "La date d'expiration de votre carte est passée.",
  'errors.code.invalid_expiry_year':
    "L'année d'expiration de votre carte n'est pas valide.",
  'errors.code.invalid_expiry_year_past':
    "L'année d'expiration de votre carte est passée.",
  'errors.code.invalid_number': "Votre numéro de carte n'est pas valide.",
  'errors.code.processing_error':
    'Une erreur est survenue lors du traitement de votre carte. Merci de réessayer ultérieurement.',
  'errors.declines.call_issuer':
    'Votre carte a été refusée. Vous pouvez appeler votre institution financière pour en savoir plus.',
  'errors.declines.card_not_supported':
    'Votre carte n’est pas prise en charge.',
  'errors.declines.card_velocity_exceeded':
    "Votre carte a été refusée en raison d'un trop grand nombre de tentatives.",
  'errors.declines.currency_not_supported':
    "Votre carte n'est pas prise en charge pour cette devise.",
  'errors.declines.generic_decline': 'Votre carte a été refusée.',
  'errors.declines.incorrect_pin': 'NIP incorrect.',
  'errors.declines.insufficient_funds':
    'Votre carte ne dispose pas de fonds suffisants.',
  'errors.declines.invalid_account': 'Compte non valide.',
  'errors.declines.invalid_amount': 'Montant non valide.',
  'errors.declines.not_permitted':
    "Le titulaire de la carte n'est pas inscrit/autorisé",
  'errors.declines.pin_try_exceeded':
    'Nombre de tentatives de NIP autorisé dépassé.',
  'errors.declines.transaction_not_allowed':
    "Votre carte ne prend pas en charge ce type d'achat.",
  'errors.code.processing_error_intransient':
    'Un problème est survenu. Veuillez réessayer ultérieurement.',
  'errors.declines.generic_decline_link':
    'Votre moyen de paiement a été refusé.',
  'errors.declines.generic_decline_non_cards': 'Votre paiement a été refusé.'
};
