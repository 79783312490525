export default {
  'errors.code.expired_card': 'Cardul dvs. a expirat.',
  'errors.code.incorrect_cvc':
    'Codul de securitate al cardului dvs. este incorect.',
  'errors.code.incorrect_number': 'Numărul cardului dvs. este incorect.',
  'errors.code.incorrect_zip':
    'Numărul cardului dvs. și codul poștal nu coincid.',
  'errors.code.invalid_cvc':
    'Codul de securitate al cardului dvs. nu este valid.',
  'errors.code.invalid_expiry_month':
    'Data de expirare a cardului dvs. nu este validă.',
  'errors.code.invalid_expiry_month_past':
    'Data de expirare a cardului dvs. este în trecut.',
  'errors.code.invalid_expiry_year':
    'Anul de expirare al cardului dvs. nu este valid.',
  'errors.code.invalid_expiry_year_past':
    'Anul de expirare al cardului dvs. este în trecut.',
  'errors.code.invalid_number': 'Numărul cardului dvs. nu este valid.',
  'errors.code.processing_error':
    'A apărut o eroare la procesarea cardului dvs. Încercați din nou în câteva momente.',
  'errors.declines.call_issuer':
    'Cardul dvs. a fost respins. Puteți suna la banca dvs. pentru detalii.',
  'errors.declines.card_not_supported': 'Cardul dvs. nu este acceptat.',
  'errors.declines.card_velocity_exceeded':
    'Cardul dvs. a fost respins din cauza încercărilor prea frecvente.',
  'errors.declines.currency_not_supported':
    'Cardul dvs. nu este acceptat pentru această monedă.',
  'errors.declines.generic_decline': 'Cardul dvs. a fost respins.',
  'errors.declines.incorrect_pin': 'Codul PIN este incorect.',
  'errors.declines.insufficient_funds': 'Cardul dvs. are fonduri insuficiente.',
  'errors.declines.invalid_account': 'Contul nu este valid.',
  'errors.declines.invalid_amount': 'Suma nu este validă.',
  'errors.declines.not_permitted': 'Numărul cardului nu este înscris/permis',
  'errors.declines.pin_try_exceeded':
    'Numărul permis de încercări de introducere a codului PIN a fost depășit.',
  'errors.declines.transaction_not_allowed':
    'Cardul dvs. nu acceptă acest tip de achiziție.',
  'errors.code.processing_error_intransient':
    'Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu.',
  'errors.declines.generic_decline_link':
    'Metoda dvs. de plată a fost respinsă.',
  'errors.declines.generic_decline_non_cards': 'Plata dvs. a fost respinsă.'
};
