import { traceId } from '@fpc/reactutils/TraceIdContext';

export const HeadersBuilder = () => {
  let headers: Headers = new Headers();
  headers.set('Accept', 'application/json, text/plain, */*');
  headers.set('Content-Type', 'application/json');
  headers.set('Traceparent', traceId);
  return headers;
};

export const HeadersInitBuilder = () => {
  let headers: HeadersInit = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    Traceparent: traceId
  };
  return headers;
};
