const assets = process.env.ASSETS;

export const FontUrls = {
  regular: `${assets}/fonts/ford/antenna-regular.woff2`,
  condLight: `${assets}/fonts/ford/antenna-cond-light.woff2`,
  condRegular: `${assets}/fonts/ford/antenna-cond-regular.woff2`
};

export const Fonts = `
  @font-face {
    font-family: 'FordAntenna';
    src: url('${FontUrls.regular}') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-bold-italic';
    src: url('${assets}/fonts/ford/antenna-bold-italic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-bold';
    src: url('${assets}/fonts/ford/antenna-bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-cond-bold';
    src: url('${assets}/fonts/ford/antenna-cond-bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-cond-light';
    src: url('${FontUrls.condLight}') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-cond-medium';
    src: url('${assets}/fonts/ford/antenna-cond-medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-cond-regular';
    src: url('${FontUrls.condRegular}') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-light-italic';
    src: url('${assets}/fonts/ford/antenna-light-italic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-light';
    src: url('${assets}/fonts/ford/antenna-light.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-medium-italic';
    src: url('${assets}/fonts/ford/antenna-medium-italic.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-medium';
    src: url('${assets}/fonts/ford/antenna-medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'FordAntenna-regular-italic';
    src: url('${assets}/fonts/ford/antenna-regular-italic.woff2')
      format('woff2');
  }
`;
