import {
  BannerMessageDiv,
  InfoBannerDiv,
  InfoIconDiv,
  InfoIconForBanner,
  TitleSpan
} from '@fpc/common/components/BannerStyles';

interface BannerProps {
  title: string;
  message: string;
}

export function Banner(props: BannerProps) {
  return (
    <InfoBannerDiv data-testid={'informational-banner'}>
      <InfoIconDiv>
        <InfoIconForBanner />
      </InfoIconDiv>
      <BannerMessageDiv>
        <TitleSpan>{props.title}</TitleSpan>
        <span>{props.message}</span>
      </BannerMessageDiv>
    </InfoBannerDiv>
  );
}
