import { MouseEvent, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { Style } from '../Styles';

export interface TabItem {
  id: string;
  tabTitle: string;
  content: string | ReactElement;
}

interface TabsProps {
  tabs: TabItem[];
  ariaTitle?: string;
  defaultTabId?: string;
}

interface TabHeadingButtonProps {
  id: string;
  isSelected: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactElement | string;
}

const TabHeadingButton = styled.button<TabHeadingButtonProps>`
  && {
    cursor: pointer;
    padding: 1em 1.5em;
    font-size: 1em;
    line-height: 1.5em;
    font-family: ${Style.fontFamilyCondRegular};
    color: ${Style.color.primary};
    text-transform: uppercase;
    background-color: ${Style.color.white};
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    border-bottom: 3px solid
      ${(props) => (props.isSelected ? Style.color.primary : `rgb(0, 0, 0, 0)`)};
  }

  &&:hover {
    color: ${Style.color.hover};
  }

  &&:focus-visible {
    outline: 1px solid ${Style.color.primary};
    outline-offset: -7px;
  }
`;

export function Tabs(props: TabsProps) {
  const [currentTab, setCurrentTab] = useState(props.defaultTabId ?? '1');

  const handleTabClick = (event: MouseEvent<HTMLButtonElement>) => {
    setCurrentTab(event.currentTarget.id);
  };

  return (
    <>
      <nav
        style={{ display: 'flex', flexDirection: 'row' }}
        aria-label={props.ariaTitle}
      >
        {props.tabs.map((tab) => (
          <TabHeadingButton
            key={tab.id}
            id={tab.id}
            isSelected={currentTab === tab.id}
            onClick={handleTabClick}
          >
            {tab.tabTitle}
          </TabHeadingButton>
        ))}
      </nav>
      <div style={{ textAlign: 'start' }}>
        {props.tabs.map((tab) => (
          <div key={tab.id}>{currentTab === tab.id && <>{tab.content}</>}</div>
        ))}
      </div>
    </>
  );
}
