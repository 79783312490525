import i18n from '@fpc/common/i18n';
import { translationKeys } from '@fpc/common';
import { isNotProd } from '../../flags';
import { PAYU_SUPPORTED_CARDS_BY_CURRENCY } from './PayuCardConfig';
import { validateSupportedCards } from './PayuCardsValidation';

export interface TokenGenerationResponse {
  token: string;
  encryptedCvv: string;
}

export interface TokenGenerationErrorResponse {
  errorMessage: string;
}

const Paymentsos: any = window;
let cardNumber: any;
let payuProd: string = 'live';

function appendPayuScript(
  publicKey: string,
  handleCardChange: (event: PayuFieldObject) => void,
  handleExpChange: (event: PayuFieldObject) => void,
  handleCvvChange: (event: PayuFieldObject) => void,
  currency: string,
  callback: () => void
) {
  const script = document.createElement('script');
  script.src = `${process.env.PAYU_JS_URL}`;
  script.async = true;
  document.body.appendChild(script);
  script.onload = () => {
    renderPayuHostedFields(
      publicKey,
      handleCardChange,
      handleExpChange,
      handleCvvChange,
      currency
    );
    if (callback) {
      callback();
    }
  };
}

function renderPayuHostedFields(
  publicKey: string,
  handleCardChange: (event: PayuFieldObject) => void,
  handleExpChange: (event: PayuFieldObject) => void,
  handleCvvChange: (event: PayuFieldObject) => void,
  currency: string
) {
  const placeholders = {
    cardNumber: '1234 1234 1234 5256',
    expDate: 'MM / YY',
    cvv: '123'
  };
  const style = {
    base: {
      color: '#000',
      fontWeight: 100,
      fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':focus': {
        color: '#000'
      },

      '::placeholder': {
        color: '#d5d5d5'
      },

      ':focus::placeholder': {
        color: '#d5d5d5'
      }
    },
    invalid: {
      color: '#FA755A',
      ':focus': {
        color: '#FA755A'
      },
      '::placeholder': {
        color: '#FFCCA5'
      }
    }
  };
  const fonts = [
    {
      src: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'
    }
  ];
  const formElements = new Paymentsos.POS.Fields(publicKey, {
    fonts
  });

  const renderCardNumberInput = () => {
    cardNumber = formElements.create('cardNumber', {
      placeholders,
      style,
      includeCardImage: false
    });

    cardNumber.mount('#card-number');

    cardNumber.on('blur', (event: any) => {
      if (event.error !== undefined) {
        handlePayuCardError(event.error);
        return;
      }
      if (!validateSupportedCards(event, currency)) {
        showCardErrorMessage(currency);
        return;
      }
      clean('ccn-help');
    });
    cardNumber.on('change', (event: PayuFieldObject) => {
      handleCardChange(event);
    });
  };

  const renderExpiryDateInput = () => {
    const expNumber = formElements.create('creditCardExpiry', {
      placeholders,
      style
    });

    expNumber.mount('#card-expiry');

    expNumber.on('blur', (event: PayuFieldObject) => {
      if (event.error === undefined) {
        clean('exp-help');
      } else {
        handlePayuExpError(event.error);
      }
    });
    expNumber.on('change', (event: PayuFieldObject) => {
      handleExpChange(event);
    });
  };

  const renderCvvInput = () => {
    const cvv = formElements.create('cvv', {
      placeholders,
      style
    });

    cvv.mount('#card-cvv');

    cvv.on('blur', (event: PayuFieldObject) => {
      if (event.error === undefined && !event.empty) {
        clean('cvv-help');
      } else {
        handlePayuCvvError(event.error);
      }
    });
    cvv.on('change', (event: PayuFieldObject) => {
      handleCvvChange(event);
    });
  };

  renderCardNumberInput();
  renderExpiryDateInput();
  renderCvvInput();
}

function showCardErrorMessage(currency: string) {
  const element = document.getElementById('ccn-help');
  // @ts-ignore
  element.textContent = i18n.t<string>(
    translationKeys.checkout.unsupportedCardErrorMessage,
    {
      supportedCards: PAYU_SUPPORTED_CARDS_BY_CURRENCY[
        currency.toUpperCase()
      ].slice(0, -1),
      lastSupportedCard:
        PAYU_SUPPORTED_CARDS_BY_CURRENCY[currency.toUpperCase()].slice(-1)[0]
    }
  );
}
function handlePayuCardError(error: any) {
  if (error.pan === 'Card number did not pass luhn validation') {
    const element = document.getElementById('ccn-help');
    // @ts-ignore
    element.textContent = i18n.t<string>(
      translationKeys.checkout.invalidCCNMessage
    );
  } else if (error.pan === undefined) {
    const element = document.getElementById('ccn-help');
    // @ts-ignore
    element.textContent = i18n.t<string>(
      translationKeys.checkout.emptyCCNMessage
    );
  }
}

function clean(tagId: string) {
  const element = document.getElementById(tagId);
  // @ts-ignore
  if (element.textContent != null) {
    // @ts-ignore
    element.textContent = '';
  }
}

function handlePayuExpError(error: PayuError) {
  if (error.expiry === 'Card expiration date is invalid') {
    const element = document.getElementById('exp-help');
    // @ts-ignore
    element.textContent = i18n.t<string>(
      translationKeys.checkout.invalidExpDateMessage
    );
  }
}

function handlePayuCvvError(error?: PayuError) {
  const element = document.getElementById('cvv-help');
  // @ts-ignore
  element.textContent = i18n.t<string>(
    translationKeys.checkout.invalidCVVMessage
  );
}

export interface PayuFieldObject {
  bin: string;
  brand: string;
  complete: boolean;
  empty: boolean;
  error?: PayuError;
}

interface PayuError {
  cvv: string | undefined;
  expiry: string | undefined;
  pan: string | undefined;
}

async function tokenGeneration(additionalData: {
  holder_name: string;
}): Promise<string | TokenGenerationErrorResponse> {
  try {
    const result = await Paymentsos.POS.createToken(cardNumber, {
      additionalData,
      environment: isNotProd() ? 'test' : payuProd
    });
    return Promise.resolve(result);
  } catch (e: any) {
    console.log(e);
    const error: TokenGenerationErrorResponse = { errorMessage: e.message };
    return Promise.resolve(error);
  }
}
export { appendPayuScript, tokenGeneration };
