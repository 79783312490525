import { createContext } from 'react';
import { traceparent } from 'tctx';

const TraceIdContext = createContext<string>('');

export const traceId = traceparent.make().toString();

interface TraceIdProviderProps {
  children?: React.ReactNode;
}

export const TraceIdProvider = ({ children }: TraceIdProviderProps) => {
  return (
    <TraceIdContext.Provider value={traceId}>
      {children}
    </TraceIdContext.Provider>
  );
};
