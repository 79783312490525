export function getFirstUrlQueryDelimiter(rawUrl: string) {
  if (!rawUrl || rawUrl === '') {
    return null;
  }
  const url = new URL(rawUrl, window.location.origin);
  if (url.search) {
    return '&';
  } else {
    return '?';
  }
}
