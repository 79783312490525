import { enUS, TRANSLATION_MISSING } from './en-US';

export const deAT: typeof enUS = {
  common: {
    default: 'Standard',
    endingIn: 'endet mit',
    close: 'Schlie\u00dfen',
    makeDefaultCard: 'Zur Standardkarte machen',
    makeDefaultInfoHover:
      'Diese Karte wird automatisch zu Ihrer Standardkarte, da sie die erste Karte in Ihrem Wallet ist.',
    processing: 'Verarbeitung...',
    saveCardCheck: 'Karte f\u00fcr zuk\u00fcnftige Transaktionen speichern',
    saveCardSuccess:
      'Die Zahlungsmethode wurde zu Ihrem Konto hinzugef\u00fcgt.',
    technicalErrorPayment:
      'Es tut uns leid! Es gab ein technisches Problem und Ihre Zahlung wurde nicht verarbeitet. Versuchen Sie es bitte erneut.',
    timeoutSorry: 'Es tut uns leid ...',
    timeoutError: 'Wir haben im Moment technische Probleme.',
    timeoutTryAgain: 'Erneut versuchen',
    savePaymentMethodCheck:
      'Zahlungsmethode f\u00fcr k\u00fcnftige Transaktionen speichern',
    makeDefaultPaymentMethod: 'Als Standardzahlungsmethode festlegen',
    savePaymentMethodButton: 'Zahlungsmethode speichern',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Dies wird automatisch zu Ihrer Standardzahlungsmethode, da es sich um die erste Zahlungsmethode in Ihrem Wallet handelt.',
    savePaymentMethodSuccess:
      'Die Zahlungsmethode wurde zu Wallet hinzugef\u00fcgt.'
  },
  checkout: {
    payButton: 'bezahlen  {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Es tut uns leid! Es gab ein technisches Problem und Ihre Zahlung wurde nicht verarbeitet. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut',
    technicalErrorPaymentDetachFail:
      'Es tut uns leid! Es gab ein technisches Problem und Ihre Zahlung wurde nicht verarbeitet. Ihre Karte wurde in Ihrem Wallet gespeichert. Versuchen Sie es bitte erneut.',
    tosTextBefore:
      'Indem Sie unten auf die Schaltfl\u00e4che \u201eBezahlen\u201c klicken, stimmen Sie den ',
    tosLinkText: 'Zahlungsbedingungen und Datenschutzbestimmungen',
    tosTextAfter: '.',
    useNewCard: 'Neue Karte verwenden',
    useNewCardDropdownText: 'Neue Karte hinzuf\u00fcgen',
    useNewCardTextBefore: 'Mit gespeicherter Karte bezahlen oder ',
    useNewCardLinkText: 'neue Zahlungsmethode verwenden',
    useNewCardTextAfter: '.',
    cardSelect: 'Auswahl Kreditkarte',
    checkoutFaster: 'Schneller bezahlen mit FordPay.',
    checkout: 'Bezahlen',
    nameOnCard: 'Name auf der Karte',
    fullName: 'Vollst\u00e4ndiger Name',
    cardNumber: 'Kartennummer',
    expirationDate: '. Ablaufdatum',
    securityCode: 'Sicherheitscode',
    invalidCCNMessage: 'Ihre Kartennummer ist ung\u00fcltig.',
    invalidCVVMessage: 'Ihr Sicherheitscode ist ung\u00fcltig.',
    invalidExpDateMessage: 'Ihr Ablaufdatum ist ung\u00fcltig.',
    emptyCCNMessage: 'Ihre Kartennummer ist nicht angegeben.',
    emptyCVVMessage: 'Ihr Sicherheitscode ist nicht angegeben.',
    emptyExpDateMessage: 'Ihr Ablaufdatum ist nicht angegeben.',
    unsupportedCardMessage:
      'Ihre Kartennummer wird nicht unterst\u00fctzt. Geben Sie bitte die Nummer einer VISA, MasterCard oder AMEX ein.',
    paymentNotProcessed: 'Ihre Zahlung konnte nicht verarbeitet werden',
    redirectStatusFailure:
      'Ihre Zahlung konnte nicht verarbeitet werden. Versuchen Sie es bitte erneut.\u00a0',
    continue: '{{formattedCurrency}} Weitermachen',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Indem Sie unten auf die Schaltfl\u00e4che \u201eWeitermachen\u201c klicken, stimmen Sie den ',
    authCheckoutStatement:
      'Bezahlen Sie mit der gespeicherten Karte, verwenden Sie eine neue Zahlungsmethode oder nutzen Sie \u201ePay Per Bank\u201c.',
    useNewCardTextBeforePayByBank: 'Bezahlen Sie mit der gespeicherten Karte, ',
    usePayByBank: ', oder nutzen Sie ',
    payByBankLinkText: 'Pay Per Bank',
    payByBankHeader: 'Verwenden Sie \u201eBezahlen per Bank\u201c.',
    use: 'verwenden',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Neue Zahlungsmethode verwenden',
    useNewPaymentDropdownText: 'neue Zahlungsmethode verwenden',
    useNewPaymentTextBefore:
      'Zur Kasse gehen mit gespeicherter Zahlungsmethode oder ',
    useNewPaymentLinkText: 'neue Zahlungsmethode verwenden.',
    useNewPaymentTextBeforePayByBank:
      'Zur Kasse gehen mit gespeicherter Zahlungsart, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Sie haben derzeit keine Zahlungsmethoden hinterlegt. Legen Sie los, indem Sie Ihre erste Zahlungsmethode hinzuf\u00fcgen.',
    addPaymentMethod: 'Zahlungsmethode hinzuf\u00fcgen',
    makeDefault: 'Zur Standardmethode machen',
    changeDefaultPMSuccess:
      'Ihre Standard-Zahlungsmethode wurde ge\u00e4ndert.',
    makeDefaultSuccessPM: 'Zahlungsmethode als Standard hinzugef\u00fcgt.',
    makeDefaultErrorPM:
      'Fehler beim Einstellen der Zahlungsmethode als Standard',
    removePMSuccess: 'Die Zahlungsmethode wurde aus Ihrem Konto entfernt.',
    removePMError: 'Fehler beim Entfernen der Zahlungsmethode.',
    removePM: 'Zahlungsmethode entfernen',
    removePMQuestion: 'Zahlungsmethode entfernen?',
    removePMText:
      'Sind Sie sicher, dass Sie die Zahlungsmethode {{cardNumber}} aus Ihrer Wallet entfernen m\u00f6chten? Alle aktiven Abonnements, die mit dieser Zahlungsmethode verkn\u00fcpft sind, schlagen fehl, wenn die Zahlungsmethode entfernt wird.',
    defaultCard: 'Standardkarte',
    defaultPM: 'Standardzahlungsmethode\u00a0',
    defaultCardQuestion: 'Standardkarte?',
    defaultPMQuestion: 'Standardzahlungsmethode?',
    defaultCardText:
      'Sind Sie sicher, dass Sie Ihre Standardkarte auf {{cardNumber}} \u00e4ndern m\u00f6chten?',
    defaultPMText:
      'Sind Sie sicher, dass Sie Ihre Standardzahlungsmethode auf {{cardNumber}} \u00e4ndern m\u00f6chten?',
    navigationLabel: 'Navigation Kontoverwaltung',
    removeDefaultPMSuccess:
      'Standardzahlungsmethode aus der Ford.com Wallet entfernt',
    removeDefaultCardError: 'Fehler beim Entfernen der Standardkarte.',
    removeDefaultPMError: 'Fehler beim Entfernen der Standardzahlungsmethode.',
    removeDefaultCard: 'Entfernen Sie Ihre Standardkarte',
    removeDefaultPM: 'Standardzahlungsmethode entfernen?\u00a0',
    removeDefaultPMText:
      'Zahlungsmethode {{cardNumber}} ist Ihre Standardzahlungsmethode. Bitte w\u00e4hlen Sie unten eine neue Standardzahlungsmethode aus. Alle aktiven Abonnements, die mit dieser Zahlungsmethode verkn\u00fcpft sind, schlagen fehl, wenn die Zahlungsmethode entfernt wird.',
    removeDefaultPMButton:
      'Zahlungsmethode entfernen und Standard aktualisieren',
    removeDefaultSelectPM: 'Neue Standardzahlungsmethode ausw\u00e4hlen',
    title: 'Wallet',
    expiration: 'G\u00fcltig bis\u00a0{{date}}',
    expires: 'L\u00e4uft ab am {{Datum}}.',
    stripeDefaultError:
      'Es gab ein technisches Problem. Versuchen Sie es bitte erneut.\u00a0\u00a0',
    paymentMethod: 'Zahlungsmethode',
    tosTextBefore:
      'Indem Sie unten auf die Schaltfl\u00e4che \u201eKarte speichern\u201c klicken, stimmen Sie den ',
    tosTextBeforePM:
      'Durch Klicken auf die Schaltfl\u00e4che "Zahlungsmethode speichern" stimmen Sie ',
    tosLinkText: 'unseren Zahlungsbedingungen zu',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alle',
    amount: 'Betrag',
    date: 'Datum',
    description: 'Beschreibung',
    displayingAll: 'Anzeige aller Transaktionen.',
    displayingDays:
      'Anzeige der Transaktionen der vergangenen {{numberOfDays}} Tage.',
    displayingYear: 'Anzeige der Transaktionen {{{year}}.',
    filter: 'Die letzten {{numberOfDays}} Tage',
    loadAll: 'Alle laden',
    loadMore: '{{x}} weitere laden',
    noTransactions: 'Keine Transaktionen zur Anzeige vorhanden.',
    paymentMethod: 'Zahlungsmethode',
    refund: 'R\u00fcckerstattung',
    searchBar: 'Suche nach Beschreibung',
    timeFilter: 'Zeitrahmen f\u00fcr die Transaktionshistorie ausw\u00e4hlen',
    title: 'Transaktionen',
    pending: 'Ausstehend'
  },
  mit: {
    confirmPurchase: 'Best\u00e4tige den Kauf',
    paymentSuccessful: 'Zahlung erfolgreich',
    saveCardAlert:
      'Zur Verwendung dieser Karte f\u00fcr zuk\u00fcnftige Zahlungen muss sie in Ihrem Ford Pay Wallet gespeichert werden.',
    title: 'Auswahl der Zahlungsmethode',
    tosTextBefore:
      'Indem Sie unten auf die Schaltfl\u00e4che {{paymentButtonText}} klicken, stimmen Sie den ',
    tosLinkText: 'Zahlungsbedingungen zu',
    tosTextAfter: '.',
    useNewCard: 'Neue Karte verwenden',
    useNewCardDropDownItem: 'Neue Karte verwenden',
    useNewCardTextBefore: 'Gespeicherte Karte ausw\u00e4hlen oder ',
    useNewCardLinkText: 'neue Karte verwenden',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Neue Zahlungsmethode verwenden',
    useNewPaymentDropdownText: 'neue Zahlungsmethode verwenden',
    useNewPaymentTextBefore:
      'Zur Kasse gehen mit gespeicherter Zahlungsmethode oder ',
    useNewPaymentLinkText: 'neue Zahlungsmethode verwenden',
    successful: 'Erfolgreich',
    savePaymentMethodAlert:
      'Um diese Zahlungsmethode f\u00fcr zuk\u00fcnftige Zahlungen nutzen zu k\u00f6nnen, muss sie in Ihrem Wallet gespeichert werden.'
  }
};
