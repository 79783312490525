import { useContext, useState } from 'react';
import './bluesnap.css';
import { makeBluesnapGuestPayment } from '@fpc/api/paymentapp/MakeBluesnapGuestPayment';
import {
  BluesnapCheckoutContext,
  CHECKOUT_ELEMENT
} from '@fpc/reactutils/checkoutContextProvider';
import { BlueSnapPaymentForm } from './BlueSnapPaymentForm';
import { createThreeDSObj } from '@fpc/utils/threeDS';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import i18next from '@fpc/common/i18n';
import { translationKeys } from '@fpc/common';

interface BlueSnapGuestProps {
  bluesnapPaymentToken: string;
}

export function BlueSnapGuestCheckout(props: BlueSnapGuestProps) {
  const { tokens, redirectUrl, errorDispatch, transaction } = useContext(
    BluesnapCheckoutContext
  );
  const threeDSecureObj = createThreeDSObj(transaction);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  function onSubmitPayment() {
    setErrorMessage('');
    makeBluesnapGuestPayment(
      tokens.paymentInfoToken,
      props.bluesnapPaymentToken,
      tokens.bearerToken ?? undefined,
      tokens.digitalSignature ?? undefined
    )
      .then((res) => {
        const transactionId = res.transactionId;
        window.location.href = `${redirectUrl}?transactionId=${transactionId}`;
      })
      .catch(async (reject) => {
        setSubmitDisabled(false);
        if (reject.unrecoverable) {
          errorDispatch(ErrorCondition.Unrecoverable);
          dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
        } else if (reject.pspErrorCode) {
          setErrorMessage(
            i18next.t<string>(translationKeys.checkout.paymentNotProcessed)
          );
        } else {
          setErrorMessage(
            i18next.t<string>(translationKeys.common.technicalErrorPayment)
          );
        }
      });
  }

  return (
    <div data-testid="bluesnap-guest-checkout-container">
      <BlueSnapPaymentForm
        bluesnapPaymentToken={props.bluesnapPaymentToken}
        bluesnapThreeDS={threeDSecureObj}
        onSubmitPayment={onSubmitPayment}
        guestCheckout={true}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        isSubmitDisabled={isSubmitDisabled}
        setSubmitDisabled={setSubmitDisabled}
      />
    </div>
  );
}
