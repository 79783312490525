import { translationKeys } from '@fpc/common';
import i18n from '@fpc/common/i18n';
import { CARD_URLS } from '@fpc/checkout/bluesnap/BluesnapSupportedCards';
import {
  BluesnapAuthPaymentResponse,
  makeBluesnapAuthPayment
} from '@fpc/api/paymentapp/MakeBluesnapAuthPayment';
import {
  BluesnapPaymentObject,
  makeBluesnapGuestPayment
} from '@fpc/api/paymentapp/MakeBluesnapGuestPayment';
import { Dispatch, SetStateAction } from 'react';

function loadBluesnapScript(callback: () => void): void {
  const script = document.createElement('script');

  script.src = `${process.env.BLUESNAP_JS_URL}`;
  script.async = true;
  script.id = 'bluesnap-js';

  document.body.appendChild(script);

  script.onload = () => {
    if (callback) {
      callback();
    }
  };
}

function removeBluesnapScript(): void {
  let script = document.getElementById('bluesnap-js');
  if (script != null) {
    document.body.removeChild(script);
  }
}

function createHostedPaymentFields(
  bluesnap: any,
  hostedPaymentFieldsToken: string,
  bearerToken: string,
  paymentInfoToken: string,
  merchantCurrency: string,
  amount: number,
  merchantCountry: string,
  guestCheckout: boolean,
  redirectUrl: string,
  onTypeCallback: (cardType: string | null) => void,
  fordCustomerId?: string,
  bluesnapCustomerId?: number,
  bffBaseUrl?: string,
  digitalSignature?: string
): void {
  const bluesnapObject = {
    //insert your Hosted Payment Fields token
    token: hostedPaymentFieldsToken,
    onFieldEventHandler: {
      /*OPTIONAL*/ setupComplete: function () {},
      onFocus: function (tagId: string) {
        // Handle focus
        changeImpactedElement(
          tagId,
          'hosted-field-valid hosted-field-invalid',
          'hosted-field-focus'
        );
      },
      onBlur: function (tagId: string) {
        // Handle blur
        changeImpactedElement(tagId, 'hosted-field-focus', '');
      },
      onError: function (
        tagId: string,
        errorCode: string,
        errorDescription: string,
        eventOrigin: string
      ) {
        // Handle a change in validation
        handleOnErrorValidation(tagId, errorDescription);
      },
      onType: function (tagId: string, cardType: string, cardData: string) {
        let cardInSupportedCardList = CARD_URLS.find(
          (card) => card.card === cardType
        );
        if (!cardInSupportedCardList && cardType !== 'UNKNOWN') {
          handleOnErrorValidation(tagId, 'unsupportedCard');
        } else {
          if (cardData != null) {
            const element = document.getElementById(tagId + '-help');
            // @ts-ignore
            element.classList.add('helper-text-green');
            // @ts-ignore
            element.textContent = JSON.stringify(cardData);
          }
        }
        onTypeCallback(cardType);
      },
      onValid: function (tagId: string) {
        // Handle a change in validation
        changeImpactedElement(
          tagId,
          'hosted-field-focus hosted-field-invalid',
          'hosted-field-valid'
        );
        const element = document.getElementById(tagId + '-help');
        // @ts-ignore
        element.textContent = '';
      }
    },
    //styling is optional
    style: {
      // Styling all inputs
      input: {
        'font-family': 'Helvetica Neue,Helvetica,Arial,sans-serif',
        'padding-left': '.5em'
      }
      // Styling Hosted Payment Field input state
    },
    ccnPlaceHolder: '4111222233334444',
    cvvPlaceHolder: '123',
    expPlaceHolder: 'MM / YY',
    '3DS': true
  };
  bluesnap.hostedPaymentFieldsCreate(bluesnapObject);

  const sdkRequest = {
    token: hostedPaymentFieldsToken, // The token you created in Step 1.
    googlePay: true,
    applePay: true,
    paymentData: {
      currencyCode: merchantCurrency.toUpperCase(), // The three-letter ISO 4217 currency code for the payment.
      countryCode: merchantCountry, // The merchant’s two-letter ISO 3166 country code.
      total: {
        label: 'Payment Amount',
        amount: amount.toString()
      },
      theme: 'black' // OPTIONAL black or white default is 'black'
    },
    onEvent: {
      // this event handler will handle all the events arriving from the Payment Request Button
      paymentAuthorized: function (success: any, error: any) {
        // here you will create the transaction Server2Server call Auth / Capture
        // transaction result will decide which function to activate
        // according to the transaction result status we will activate either success() or error()
        let paymentPromise: Promise<
          BluesnapPaymentObject | BluesnapAuthPaymentResponse
        >;
        if (guestCheckout) {
          paymentPromise = makeBluesnapGuestPayment(
            paymentInfoToken,
            hostedPaymentFieldsToken,
            bearerToken,
            digitalSignature
          );
        } else {
          paymentPromise = makeBluesnapAuthPayment(
            paymentInfoToken,
            hostedPaymentFieldsToken,
            fordCustomerId!,
            bluesnapCustomerId!,
            bearerToken,
            bffBaseUrl
          );
        }
        paymentPromise
          .then((res) => {
            const transactionId = res.transactionId;
            success();
            window.location.href = `${redirectUrl}?transactionId=${transactionId}`;
          })
          .catch((err) => {
            // Get Error Details from err
            error('Transaction Failed.');
          });
      },
      error: function (event: any) {
        // handle error event
      },
      warning: function (event: any) {
        // handle warning event
      },
      complete: function () {
        // returned after complete finished.
      }
    },
    threeDS: false // Optional,
  };

  bluesnap.walletButtonSetup(sdkRequest);
}

function changeImpactedElement(
  tagId: string,
  removeClass: string,
  addClass: string
): void {
  removeClass = removeClass || '';
  if (removeClass !== '') {
    const removeClassList = removeClass.split(' ');
    // @ts-ignore
    document
      .querySelector('[data-bluesnap=' + tagId + ']')
      .classList.remove(removeClassList[0]);

    // @ts-ignore
    document
      .querySelector('[data-bluesnap=' + tagId + ']')
      .classList.remove(removeClassList[1]);
  }
  addClass = addClass || '';
  if (addClass !== '') {
    // @ts-ignore
    document
      .querySelector('[data-bluesnap=' + tagId + ']')
      .classList.add(addClass);
  }
}

function handleOnErrorValidation(tagId: string, errorDescription: string) {
  changeImpactedElement(
    tagId,
    'hosted-field-valid hosted-field-focus',
    'hosted-field-invalid'
  );
  const element = document.getElementById(tagId + '-help');
  // @ts-ignore
  element.classList.remove('helper-text-green');
  // @ts-ignore
  element.textContent = getValidationMessage(tagId, errorDescription);
}

function getValidationMessage(tagId: string, errorDescription: string): string {
  if (errorDescription === 'invalid') {
    if (tagId === 'ccn') {
      return i18n.t<string>(translationKeys.checkout.invalidCCNMessage);
    } else if (tagId === 'exp') {
      return i18n.t<string>(translationKeys.checkout.invalidExpDateMessage);
    } else if (tagId === 'cvv') {
      return i18n.t<string>(translationKeys.checkout.invalidCVVMessage);
    }
  } else if (errorDescription === 'empty') {
    if (tagId === 'ccn') {
      return i18n.t<string>(translationKeys.checkout.emptyCCNMessage);
    } else if (tagId === 'exp') {
      return i18n.t<string>(translationKeys.checkout.emptyExpDateMessage);
    } else if (tagId === 'cvv') {
      return i18n.t<string>(translationKeys.checkout.emptyCVVMessage);
    }
  } else if (errorDescription === 'unsupportedCard') {
    if (tagId === 'ccn') {
      return i18n.t<string>(translationKeys.checkout.unsupportedCardMessage);
    }
  }
  return '';
}

function areFieldsValid(): boolean {
  return (
    // @ts-ignore
    !document
      .querySelector('[data-bluesnap=ccn]')
      .classList.contains('hosted-field-invalid') &&
    // @ts-ignore
    !document
      .querySelector('[data-bluesnap=exp]')
      .classList.contains('hosted-field-invalid') &&
    // @ts-ignore
    !document
      .querySelector('[data-bluesnap=cvv]')
      .classList.contains('hosted-field-invalid')
  );
}

export {
  loadBluesnapScript,
  removeBluesnapScript,
  createHostedPaymentFields,
  changeImpactedElement,
  areFieldsValid
};
