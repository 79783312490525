import Select, {
  AriaOnChange,
  AriaOnChangeProps,
  AriaOnFocus,
  components,
  ControlProps
} from 'react-select';
import { Style } from '../Styles';
import { ReactComponent as FilterIcon } from '../icons/filter-16.svg';
import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import { ChevronIcon } from './ChevronIcon';
import { dropDownStyles } from './DropDownStyles';
import { filterDropDownStyles } from './FilterDropDownStyles';
import { ReactElement } from 'react';

export interface DropDownItem<T> {
  label: string | ReactElement;
  ariaLabel: string;
  value: T;
  isDisabled?: boolean;
}

function isDropDownItem(object: any): object is DropDownItem<any> {
  return 'label' in object && 'value' in object;
}

interface DropDownProps<T> {
  items: DropDownItem<T>[];
  cssClass?: string;
  valueCallback: (value: T) => void;
  ariaLabel: string;
  defaultValue?: DropDownItem<T>;
  placeholder?: string;
  isFilter?: boolean;
  value?: DropDownItem<T>;
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const chevron = props.selectProps.menuIsOpen ? (
    <ChevronIcon.Up />
  ) : (
    <ChevronIcon.Down />
  );
  return (
    <components.DropdownIndicator {...props}>
      {chevron}
    </components.DropdownIndicator>
  );
};

const FilterControl = (props: ControlProps) => (
  <components.Control {...props}>
    <FilterIcon aria-hidden={true} style={{ marginLeft: '1em' }} />
    {props.children}
  </components.Control>
);

export function DropDown<T>(props: DropDownProps<T>) {
  // Don't set a default value if provided a placeholder instead.
  const defaultValue =
    props.defaultValue ?? (props.placeholder ? null : props.items[0]);

  function onChange(option: unknown) {
    if (isDropDownItem(option)) {
      props.valueCallback(option.value);
    }
  }

  function onMenuOpen() {
    const background = document.getElementById('select-background');
    background!.style.display = 'block';
  }

  function onMenuClose() {
    const background = document.getElementById('select-background');
    background!.style.display = 'none';
  }

  const onFocus: AriaOnFocus<unknown> = ({ focused }) => {
    return (focused as DropDownItem<unknown>).ariaLabel;
  };

  const ariaOnChange: AriaOnChange<any, boolean> = (
    ariaProps: AriaOnChangeProps<any, boolean>
  ) => {
    return ariaProps.value?.ariaLabel ?? props.placeholder;
  };

  return (
    <>
      <div
        id={'select-background'}
        style={{
          display: 'none',
          position: 'fixed',
          zIndex: '50',
          paddingTop: '100%',
          left: '0',
          top: '0',
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0,0)'
        }}
      />
      <Select
        className={props.cssClass}
        data-testid={'react-select'}
        aria-label={props.ariaLabel}
        styles={props.isFilter ? filterDropDownStyles : dropDownStyles}
        defaultValue={defaultValue}
        options={props.items}
        // @ts-ignore
        isOptionDisabled={(option) => option.isDisabled}
        components={
          props.isFilter
            ? { DropdownIndicator, Control: FilterControl }
            : { DropdownIndicator }
        }
        ariaLiveMessages={{ onFocus, onChange: ariaOnChange }}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: Style.color.gray4
          }
        })}
        hideSelectedOptions={true}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        placeholder={props.placeholder}
        value={props.value}
      />
    </>
  );
}
