import styled from 'styled-components/macro';
import { Style } from '../Styles';

export const Table = styled.table`
  && {
    color: ${Style.color.primary};
    font-family: ${Style.fontFamily};
    line-height: 1.5em;
  }
`;

export const THead = styled.thead`
  && {
    color: ${Style.color.text};
    background-color: ${Style.color.primary};
    height: 2.5em;
  }
`;

export const Th = styled.th`
  && {
    margin: 2px 0;
    text-align: start;
    padding-inline: 1.25em;
    vertical-align: middle;
  }
`;

export const TBody = styled.tbody`
  && {
    tr:nth-child(odd) {
      background-color: ${Style.color.quaternary};
    }
  }
`;

export const Tr = styled.tr`
  height: 3.125em;
  vertical-align: top;

  @media (max-width: 360px) {
    height: 48px;
  }
`;

export const Td = styled.td`
  && {
    padding-inline-start: 1.75em;
    padding-block: 0.625em;

    &.truncated:hover {
      color: ${Style.color.hover};
    }
  }
`;
