import styled from 'styled-components/macro';
import { Style } from '@fpc/common/Styles';

export const WalletPaymentMethod = styled.button<{
  isDefault?: boolean;
  isPumaBank?: boolean;
}>`
  && p {
    color: ${(props) =>
      props.isDefault ? Style.color.quaternary : Style.color.primary};
  }

  && {
    all: unset;
    background-color: ${(props) => {
      const nonDefaultColor = props.isPumaBank
        ? Style.color.white
        : Style.color.quaternary;
      return props.isDefault ? Style.color.lightBlue : nonDefaultColor;
    }};
    cursor: pointer;
    position: relative;
    width: ${(props) => (props.isPumaBank ? '9.5em' : '24em')};
    height: ${(props) => (props.isPumaBank ? '6em' : '14em')};
    border-radius: ${(props) => (props.isPumaBank ? '0.2em' : '1.2em')};
    border: ${(props) =>
      props.isPumaBank
        ? `${Style.color.gray4} 0.06em solid`
        : 'transparent 0.125em solid'};
    font-size: 1em;
    line-height: 1.5em;
    box-shadow: ${(props) =>
      props.isPumaBank
        ? ''
        : 'rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.3) 0 20px 20px 0'};
    @media (max-width: 360px) {
      width: ${(props) => (props.isPumaBank ? '18em' : '20.625em')};
      height: ${(props) => (props.isPumaBank ? '8em' : '13.125em')};
      font-size: 0.75em;
    }
    @media (max-width: 320px) {
      width: ${(props) => (props.isPumaBank ? '18em' : '18.125em')};
      height: ${(props) => (props.isPumaBank ? '8em' : '11.25em')};
    }
    @media (max-width: 300px) {
      width: ${(props) => (props.isPumaBank ? '18em' : '16.875em')};
      height: ${(props) => (props.isPumaBank ? '8em' : '10.625em')};
    }
  }

  &&:focus {
    border: ${(props) =>
      props.isPumaBank
        ? `${Style.color.secondary} 0.06em solid`
        : `${Style.color.secondary} 0.125em solid`};
  }
`;

export const WalletPaymentMethodNew = styled(WalletPaymentMethod)`
  && {
    font-family: ${Style.fontFamily};
    border-width: 0.125em;
    border-style: dashed;
    border-color: #979797;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    &:hover {
      border-color: ${Style.color.hover};

      p {
        color: ${Style.color.hover};
      }

      svg {
        fill: ${Style.color.hover};
      }
    }
  }

  && > div {
    vertical-align: middle;
  }
`;

export const WalletBurgerTime = styled.div<{ isDefault: boolean }>`
  && {
    --burger-color: ${(props) => (props.isDefault ? '#F4F4F4' : '#00095B')};

    width: 20px;
    height: 20px;
    background-image: radial-gradient(
      circle,
      var(--burger-color) 2px,
      transparent 1px
    );
    background-size: 100% 33.33%;
  }
`;

export const DefaultText = styled.p`
  && {
    justify-self: end;
    padding-top: 2em;
    font-size: 1em;
    line-height: 1em;
    text-transform: uppercase;
    font-style: italic;
    @media (max-width: 320px) {
      padding-top: 1.5em;
      font-size: 0.75em;
    }
    @media (max-width: 300px) {
      padding-top: 1em;
    }
  }
`;

export const ContentContainer = styled.div`
  && {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    padding: 0.25em 1.875em;
    top: 0.75em;
    @media (max-width: 360px) {
      padding: 0.25em 1.5em;
    }
  }
`;

export const ExpiryDateContainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

export const ExpiryText = styled.p`
  && {
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const PaymentMethodOptions = styled.dialog<{
  left: number;
  top: number;
}>`
  && {
    --dialog-top-offset: calc(${(props) => props.top}px + 1em);
    --dialog-right-offset: calc(100vw - ${(props) => props.left}px - 21em);
    z-index: 200;
    border: 1px solid black;
    background-color: white;
    min-width: min(12em, 25%);
    margin: 0;
    padding: 0;
    position: absolute;
    left: auto;
    right: var(--dialog-right-offset);
    top: var(--dialog-top-offset);
    overflow: clip;

    @media (max-width: 360px) {
      --dialog-right-offset: calc(100vw - ${(props) => props.left}px - 17em);
    }
    @media (max-width: 340px) {
      --dialog-right-offset: calc(100vw - ${(props) => props.left}px - 16em);
    }
    @media (max-width: 320px) {
      --dialog-right-offset: calc(100vw - ${(props) => props.left}px - 15em);
    }
    @media (max-width: 300px) {
      --dialog-right-offset: calc(100vw - ${(props) => props.left}px - 14.5em);
    }
  }
`;

export const PaymentMethodTextWrapper = styled.div`
  && {
    padding-top: 2em;
    @media (max-width: 320px) {
      padding-top: 1em;
    }
    @media (max-width: 300px) {
      padding-top: 0.5em;
    }
  }
`;
