export default {
  'errors.code.expired_card': 'O seu cartão expirou.',
  'errors.code.incorrect_cvc':
    'O código de segurança do seu cartão está incorreto.',
  'errors.code.incorrect_number': 'O número do seu cartão está incorreto.',
  'errors.code.incorrect_zip':
    'O número do seu cartão e o código postal não correspondem.',
  'errors.code.invalid_cvc': 'O código de segurança do seu cartão é inválido.',
  'errors.code.invalid_expiry_month':
    'A data de validade do seu cartão é inválida.',
  'errors.code.invalid_expiry_month_past':
    'A data de validade do seu cartão é no passado.',
  'errors.code.invalid_expiry_year':
    'O ano de validade do seu cartão é inválido.',
  'errors.code.invalid_expiry_year_past':
    'O ano de validade do seu cartão é no passado.',
  'errors.code.invalid_number': 'O número do seu cartão é inválido.',
  'errors.code.processing_error':
    'Ocorreu um erro ao processar o seu cartão. Tente novamente em breve.',
  'errors.declines.call_issuer':
    'O seu cartão foi recusado. Pode contactar o seu banco para mais informações.',
  'errors.declines.card_not_supported': 'O seu cartão não é suportado.',
  'errors.declines.card_velocity_exceeded':
    'O seu cartão foi recusado por tentativas repetidas com demasiada frequência.',
  'errors.declines.currency_not_supported':
    'O seu cartão não é válido para esta moeda.',
  'errors.declines.generic_decline': 'O seu cartão foi recusado.',
  'errors.declines.incorrect_pin': 'PIN incorreto',
  'errors.declines.insufficient_funds': 'O saldo do seu cartão é insuficiente.',
  'errors.declines.invalid_account': 'Conta inválida.',
  'errors.declines.invalid_amount': 'Quantia inválida.',
  'errors.declines.not_permitted': 'Cardmember não registado/não permitido',
  'errors.declines.pin_try_exceeded':
    'Número de tentativas permitidas para introduzir o PIN ultrapassado.',
  'errors.declines.transaction_not_allowed':
    'O seu cartão não suporta este tipo de compra.',
  'errors.code.processing_error_intransient':
    'Ocorreu um problema. Tente novamente mais tarde.',
  'errors.declines.generic_decline_link':
    'O seu método de pagamento foi recusado.',
  'errors.declines.generic_decline_non_cards': 'O seu pagamento foi recusado.'
};
