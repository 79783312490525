import { useEffect, useState } from 'react';
import {
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripeElementsOptionsMode,
  StripeExpressCheckoutElementConfirmEvent
} from '@stripe/stripe-js';
import { paymentRequestButtonStyle } from '@fpc/common/Styles';
import { RecurringPaymentRequest } from '@fpc/common/transactionInterfaces';

export interface ExpressCheckoutOptions {
  amount: number;
  currency: string;
  applePay: boolean;
  googlePay: boolean;
  isSetupMode?: boolean;
}

interface ExpressCheckoutButtonProps {
  stripe: Stripe;
  onPaymentConfirmCallback: (
    event: StripeExpressCheckoutElementConfirmEvent,
    elements: StripeElements
  ) => void;
  options: ExpressCheckoutOptions;
  finishedLoadingHandler?: (value: boolean) => void;
  recurringPaymentRequest?: RecurringPaymentRequest;
}

export function ExpressCheckoutButton(props: ExpressCheckoutButtonProps) {
  let options: StripeElementsOptionsClientSecret | StripeElementsOptionsMode =
    props.options.isSetupMode
      ? { mode: 'setup', currency: props.options.currency }
      : {
          mode: 'payment',
          amount: props.options.amount,
          currency: props.options.currency
        };
  const [elements] = useState<StripeElements>(props.stripe.elements(options));
  const [hasPaymentRequestButtonStyle, setHasPaymentRequestButtonStyle] =
    useState(false);
  const showExpressCheckout = props.options.googlePay || props.options.applePay;

  useEffect(() => {
    if (showExpressCheckout) {
      mountExpressCheckout();
    }
  }, []);

  function finishLoading() {
    if (props.finishedLoadingHandler) {
      props.finishedLoadingHandler(true);
    }
  }
  function mountExpressCheckout() {
    const expressCheckout = elements.create('expressCheckout', {
      buttonType: {
        googlePay: 'plain',
        applePay: 'plain'
      },
      buttonTheme: {
        googlePay: 'white',
        applePay: 'white'
      },
      wallets: {
        applePay: props.options.applePay ? 'auto' : 'never'
      }
    });

    expressCheckout.mount('#express-checkout-button');

    expressCheckout.on('ready', ({ availablePaymentMethods }) => {
      if (
        availablePaymentMethods &&
        ((availablePaymentMethods.applePay && props.options.applePay) ||
          (availablePaymentMethods.googlePay && props.options.googlePay))
      ) {
        setHasPaymentRequestButtonStyle(true);
      }
      finishLoading();
    });

    expressCheckout.on('click', (event) => {
      if (props.recurringPaymentRequest) {
        event.resolve({
          applePay: { recurringPaymentRequest: props.recurringPaymentRequest }
        });
      } else {
        event.resolve();
      }
    });

    expressCheckout.on('confirm', async (event) => {
      props.onPaymentConfirmCallback(event, elements);
    });
  }
  if (showExpressCheckout) {
    return (
      <>
        <div
          id={'express-checkout-button'}
          data-testid={'express-checkout-button'}
          style={hasPaymentRequestButtonStyle ? paymentRequestButtonStyle : {}}
        >
          {/*Inject Express Checkout Button here*/}
        </div>
        {hasPaymentRequestButtonStyle && <hr style={{ margin: '1em 0' }} />}
      </>
    );
  }
  return <></>;
}
