import { useContext, useEffect, useState } from 'react';
import { PayuPaymentRequest } from '@fpc/api/paymentapp/MakePayuPayment';
import {
  CHECKOUT_ELEMENT,
  PayuCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import {
  Button,
  buttonPayNow,
  paymentButtonProcessing,
  translationKeys
} from '@fpc/common';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import {
  appendPayuScript,
  PayuFieldObject,
  tokenGeneration
} from './PayuFields';
import * as PayuService from './PayuService';
import { IconsContainer, TextInput } from './Style';
import i18n from '@fpc/common/i18n';
import {
  getDefaultCardBrandIcons,
  validateSupportedCards
} from './PayuCardsValidation';
import CardBrandIcons from './CardBrandIcons';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import PaymentErrorMessage from './components/PaymentErrorMessage';
import i18next from 'i18next';
import { PAYU_SUPPORTED_CARDS_BY_CURRENCY } from './PayuCardConfig';

export function Payu() {
  const { transaction, tokens, redirectUrl, errorDispatch } =
    useContext(PayuCheckoutContext);
  const [userName, setUserName] = useState('');
  const publicKey = transaction.pspAuthKey.publicKey;
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [isPay, setPay] = useState(false);
  const [isValidName, setValidName] = useState(false);
  const [isValidCardNumber, setIsValidCardNumber] = useState(false);
  const [isValidExp, setIsValidExp] = useState(false);
  const [isValidCvv, setIsValidCvv] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');

  const paymentInfoToken = JSON.parse(tokens.paymentInfoToken).paymentInfo;

  const [icons, setIcons] = useState<string[]>([]);

  const updateDefaultCardIcons = (): void => {
    const cardBrands: string[] = getDefaultCardBrandIcons(transaction.currency);
    setIcons(cardBrands);
  };

  const updateCardIconsOnCardNumberChange = (event: PayuFieldObject): void => {
    const supportedCardIcons =
      PAYU_SUPPORTED_CARDS_BY_CURRENCY[transaction.currency.toUpperCase()];
    if (
      event.brand !== undefined &&
      supportedCardIcons.includes(event.brand.toUpperCase())
    ) {
      setIcons([event.brand]);
    } else {
      updateDefaultCardIcons();
    }
  };

  useEffect(() => {
    appendPayuScript(
      publicKey,
      handleCardNumberChange,
      handleExpChange,
      handleCvvChange,
      transaction.currency,
      () => {}
    );

    updateDefaultCardIcons();
  }, []);

  useEffect(() => {
    if (isValidName && isValidCvv && isValidExp && isValidCardNumber) {
      setPay(true);
    } else {
      setPay(false);
    }
  }, [isValidCardNumber, isValidExp, isValidCvv, isValidName]);

  const handleCardNumberChange = (event: PayuFieldObject) => {
    updateCardIconsOnCardNumberChange(event);

    if (event.error === undefined && !event.empty) {
      setIsValidCardNumber(validateSupportedCards(event, transaction.currency));
    } else {
      setIsValidCardNumber(false);
    }
  };

  const handleExpChange = (event: PayuFieldObject) => {
    if (event.error === undefined && !event.empty) {
      setIsValidExp(true);
    } else {
      setIsValidExp(false);
    }
  };

  const handleCvvChange = (event: PayuFieldObject) => {
    if (event.error === undefined && !event.empty) {
      setIsValidCvv(true);
    } else {
      setIsValidCvv(false);
    }
  };

  async function handleSubmit(event: any) {
    event.preventDefault();
    setPay(false);
    setPaymentProcessing(true);
    setPaymentErrorMessage('');

    const additionalData = {
      holder_name: userName // This field is mandatory
    };

    const tokenResult: any = await tokenGeneration(additionalData);

    const paresdToken = JSON.parse(tokenResult);
    const paymentRequest: PayuPaymentRequest = {
      encryptedCvv: paresdToken.encrypted_cvv,
      token: paresdToken.token,
      paymentInfo: paymentInfoToken,
      merchantSiteUrl: redirectUrl
    };
    try {
      const result = await PayuService.makePayment(
        paymentRequest,
        tokens.digitalSignature!
      );
      window.location.href = result.redirectionUrl;
    } catch (ex: any) {
      setPay(true);
      setPaymentProcessing(false);
      if (ex.unrecoverable) {
        errorDispatch(ErrorCondition.Unrecoverable);
        dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
      } else {
        if (ex.statusCode === 409) {
          setPaymentErrorMessage('Payment Already Processed');
        } else {
          setPaymentErrorMessage(
            i18next.t<string>(translationKeys.common.technicalErrorPayment)
          );
        }
      }
    }
  }

  function handleChange(event: any) {
    event.preventDefault();
    setUserName(event.target.value);
    if (event.target.value !== '') {
      setValidName(true);
    } else {
      setValidName(false);
    }
  }

  function handlekeypress(event: any) {
    const pattern = /^[a-zA-Z\s]{0,50}$/;
    if (pattern.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  function handleBlur(event: any) {
    event.preventDefault();
    const element = document.getElementById('name-help-text');

    if (event.target.value == '') {
      if (element) {
        element.innerHTML = i18n.t<string>(
          translationKeys.checkout.emptyNameMessage
        );
      }
    } else {
      if (element) {
        element.innerHTML = '';
      }
    }
  }

  return (
    <div
      className="checkout-page"
      data-testid={'payu-checkout-form'}
      style={{ backgroundColor: '#fff', padding: '10px' }}
    >
      <form id="payment-form">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '10px',
            gridAutoRows: 'minmax(50px, auto)'
          }}
        >
          <InputGridArea
            style={{ gridArea: '1 / 1 / auto / -1', paddingTop: '30px' }}
          >
            <InputLabel htmlFor="cardholder-name">
              {i18n.t<string>(translationKeys.checkout.fullName)}
            </InputLabel>
            <div
              className="input-group"
              style={{ display: '', position: 'relative' }}
            >
              <CheckoutInput
                className="form-control"
                id="cardholder-name"
                data-payu="chname"
                style={{
                  padding: '0.9em 0',
                  borderRadius: '0.25rem'
                }}
              >
                <TextInput
                  id="cardholder-name"
                  placeholder={i18n.t<string>(
                    translationKeys.checkout.nameOnCard
                  )}
                  data-testid={'cardholder-name'}
                  value={userName}
                  onChange={handleChange}
                  onKeyDown={handlekeypress}
                  onBlur={handleBlur}
                />
              </CheckoutInput>
              <span
                className="helper-text"
                id="name-help-text"
                data-testid="name-help-text"
              />
            </div>
          </InputGridArea>
          <InputGridArea style={{ gridArea: '2 / 1 / auto / -1' }}>
            <InputLabel htmlFor="card-number">
              {i18n.t<string>(translationKeys.checkout.cardNumber)}
            </InputLabel>
            <div
              className="input-group"
              style={{ display: '', position: 'relative' }}
            >
              <CheckoutInput
                className="form-control"
                style={{
                  borderRadius: '.25rem',
                  padding: '0.9em 0.5em',
                  display: 'flex'
                }}
              >
                <div
                  id="card-number"
                  data-payu="ccn"
                  data-testid="card-number"
                  style={{ width: '75%' }}
                ></div>

                <IconsContainer data-testid="card-icons">
                  {icons && <CardBrandIcons icons={icons} />}
                </IconsContainer>
              </CheckoutInput>
            </div>
            <span className="helper-text" id="ccn-help" />
          </InputGridArea>

          <InputGridArea style={{ gridArea: '3 / 1 / auto / 1' }}>
            <InputLabel htmlFor="card-expiry">
              {i18n.t<string>(translationKeys.checkout.expirationDate)}
            </InputLabel>
            <CheckoutInput
              className="form-control"
              id="card-expiry"
              data-payu="exp"
              style={{
                borderBottomLeftRadius: '.25rem',
                borderTopLeftRadius: '.25rem',
                borderBottomRightRadius: '.25rem',
                borderTopRightRadius: '.25rem',
                padding: '0.9em 0.5em'
              }}
            />
            <span className="helper-text" id="exp-help" />
          </InputGridArea>

          <InputGridArea style={{ gridArea: '3 / 2 / auto / 2' }}>
            <InputLabel htmlFor="card-cvv">
              {i18n.t<string>(translationKeys.checkout.securityCode)}
            </InputLabel>
            <CheckoutInput
              className="form-control bluesnap-checkout-input-border"
              id="card-cvv"
              data-payu="cvv"
              style={{
                borderBottomLeftRadius: '.25rem',
                borderTopLeftRadius: '.25rem',
                borderBottomRightRadius: '.25rem',
                borderTopRightRadius: '.25rem',
                padding: '0.9em 0.5em'
              }}
            />
            <span className="helper-text" id="cvv-help" />
          </InputGridArea>
        </div>
        {paymentErrorMessage !== '' && (
          <PaymentErrorMessage errorMessage={paymentErrorMessage} />
        )}
        <div style={{ gridArea: '4 / 1 / auto / -1' }}>
          <Button
            id="pay-button"
            disabled={!isPay}
            data-testid={'pay-button'}
            onClick={handleSubmit}
            style={{ marginTop: '1em' }}
          >
            {isPaymentProcessing
              ? paymentButtonProcessing()
              : buttonPayNow(transaction.amount, transaction.currency)}
          </Button>
        </div>
      </form>
    </div>
  );
}
