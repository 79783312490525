export default {
  'errors.code.expired_card': 'Ditt kort har gått ut.',
  'errors.code.incorrect_cvc': 'Kortets säkerhetskod är felaktig.',
  'errors.code.incorrect_number': 'Kortnumret är felaktigt.',
  'errors.code.incorrect_zip': 'Kort- och postnummer matchar inte.',
  'errors.code.invalid_cvc': 'Kortets säkerhetskod är ogiltig.',
  'errors.code.invalid_expiry_month': 'Kortets utgångsdatum är ogiltigt.',
  'errors.code.invalid_expiry_month_past':
    'Kortets utgångsdatum har redan passerats.',
  'errors.code.invalid_expiry_year': 'Kortets utgångsdatum är ogiltigt.',
  'errors.code.invalid_expiry_year_past':
    'Kortets utgångsdatum har redan passerats.',
  'errors.code.invalid_number': 'Ditt kortnummer är ogiltigt.',
  'errors.code.processing_error':
    'Ett fel uppstod när ditt kort behandlades. Försök igen om en stund.',
  'errors.declines.call_issuer':
    'Ditt kort nekades. Du kan ringa din bank för att få mer information.',
  'errors.declines.card_not_supported': 'Ditt kort stöds inte.',
  'errors.declines.card_velocity_exceeded':
    'Ditt kort nekades på grund av för många upprepade försök.',
  'errors.declines.currency_not_supported':
    'Ditt kort stöds inte för denna valuta.',
  'errors.declines.generic_decline': 'Kortet avvisades.',
  'errors.declines.incorrect_pin': 'Felaktig PIN-kod.',
  'errors.declines.insufficient_funds': 'Ditt kort har otillräckligt saldo.',
  'errors.declines.invalid_account': 'Ogiltigt konto.',
  'errors.declines.invalid_amount': 'Ogiltigt belopp.',
  'errors.declines.not_permitted':
    'Kortmedlemmen är inte registrerad/tillåts ej',
  'errors.declines.pin_try_exceeded':
    'Tillåtet antal försök att ange PIN-koden överskridet.',
  'errors.declines.transaction_not_allowed':
    'Ditt kort stödjer inte denna typ av köp.',
  'errors.code.processing_error_intransient':
    'Något gick fel. Försök igen senare.',
  'errors.declines.generic_decline_link': 'Din betalningsmetod nekades.',
  'errors.declines.generic_decline_non_cards': 'Din betalning nekades.'
};
