export default {
  'errors.code.expired_card': 'Ihre Karte ist ungültig.',
  'errors.code.incorrect_cvc': 'Die Prüfziffer Ihrer Karte ist falsch.',
  'errors.code.incorrect_number': 'Ihre Kartennummer ist falsch.',
  'errors.code.incorrect_zip':
    'Ihre Kartennummer stimmt nicht mit der Postleitzahl überein.',
  'errors.code.invalid_cvc': 'Die Prüfziffer Ihrer Karte ist falsch.',
  'errors.code.invalid_expiry_month': 'Das Ablaufdatum Ihrer Karte ist falsch.',
  'errors.code.invalid_expiry_month_past':
    'Das Ablaufdatum Ihrer Karte liegt in der Vergangenheit.',
  'errors.code.invalid_expiry_year': 'Das Ablaufjahr Ihrer Karte ist ungültig.',
  'errors.code.invalid_expiry_year_past':
    'Das Ablaufjahr Ihrer Karte liegt in der Vergangenheit.',
  'errors.code.invalid_number': 'Ihre Kartennummer ist ungültig.',
  'errors.code.processing_error':
    'Es ist während der Bearbeitung Ihrer Karte ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  'errors.declines.call_issuer':
    'Ihre Karte wurde abgelehnt. Ihre Bank kann Ihnen weitere Informationen zu möglichen Gründen zur Verfügung stellen.',
  'errors.declines.card_not_supported': 'Ihre Karte wird nicht unterstützt.',
  'errors.declines.card_velocity_exceeded':
    'Ihre Karte wurde abgelehnt, da zu viele wiederholte Kaufversuche in geringen Abständen unternommen wurden.',
  'errors.declines.currency_not_supported':
    'Ihre Karte wird in dieser Währung nicht unterstützt.',
  'errors.declines.generic_decline': 'Ihre Karte wurde abgelehnt.',
  'errors.declines.incorrect_pin': 'Der PIN-Code ist falsch.',
  'errors.declines.insufficient_funds':
    'Das Guthaben auf Ihrer Karte ist nicht ausreichend.',
  'errors.declines.invalid_account': 'Ungültiges Konto.',
  'errors.declines.invalid_amount': 'Ungültiger Betrag.',
  'errors.declines.not_permitted':
    'Karteninhaber nicht registriert/nicht zulässig.',
  'errors.declines.pin_try_exceeded':
    'Zulässige Anzahl an Versuchen zur Eingabe des PIN-Codes überschritten.',
  'errors.declines.transaction_not_allowed':
    'Dieser Einkauf wird nicht von Ihrer Karte unterstützt.',
  'errors.code.processing_error_intransient':
    'Es ist ein Fehler aufgetreten. Versuchen Sie es bitte später erneut.',
  'errors.declines.generic_decline_link':
    'Ihre Zahlungsmethode wurde abgelehnt.',
  'errors.declines.generic_decline_non_cards': 'Ihre Zahlung wurde abgelehnt.'
};
