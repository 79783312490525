export const isLocal = process.env.NODE_ENV === 'development';

export function isNotProd(): boolean {
  return process.env.NODE_ENV !== 'production';
}

export const isLowerEnvironment =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'qa' ||
  process.env.NODE_ENV === 'test';
