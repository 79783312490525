import { enUS, TRANSLATION_MISSING } from './en-US';

export const fiFI: typeof enUS = {
  common: {
    default: 'oletus',
    endingIn: 'vanhenee',
    close: 'Sulje',
    makeDefaultCard: 'Aseta oletuskortiksi',
    makeDefaultInfoHover:
      'T\u00e4st\u00e4 tulee automaattisesti oletuskorttisi, sill\u00e4 se on ensimm\u00e4inen kortti lompakossasi.',
    processing: 'K\u00e4sitell\u00e4\u00e4n\u2026',
    saveCardCheck: 'Tallenna kortti tulevia maksuja varten',
    saveCardSuccess: 'Kortti lis\u00e4tty Ford.com-lompakkoon.',
    technicalErrorPayment:
      'Anteeksi! Maksua ei suoritettu teknisen virheen vuoksi. Yrit\u00e4 uudelleen.',
    timeoutSorry: 'Pahoittelut\u2026',
    timeoutError: 'Sivustollamme on hieman teknisi\u00e4 ongelmia.',
    timeoutTryAgain: 'Yrit\u00e4 uudelleen',
    savePaymentMethodCheck: 'Tallenna maksutapa tulevia maksuja varten',
    makeDefaultPaymentMethod: 'Aseta oletusmaksutavaksi',
    savePaymentMethodButton: 'Tallenna maksutapa',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'T\u00e4st\u00e4 tulee automaattisesti oletusmaksutapasi, koska se on ensimm\u00e4inen maksutapa lompakossasi.',
    savePaymentMethodSuccess: 'Maksutapa on lis\u00e4tty Walletiin.'
  },
  checkout: {
    payButton: 'Maksa {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Anteeksi! Tapahtui tekninen virhe, mink\u00e4 vuoksi maksuasi ei voitu suorittaa. P\u00e4ivit\u00e4 sivu ja yrit\u00e4 uudelleen. ',
    technicalErrorPaymentDetachFail:
      'Anteeksi! Maksua ei suoritettu teknisen virheen vuoksi. Korttisi tallennettiin lompakkoosi. Yrit\u00e4 uudelleen.',
    tosTextBefore: 'Klikkaamalla Maksa-painiketta alla hyv\u00e4ksyt ',
    tosLinkText: 'maksuehdot ja tietosuojak\u00e4yt\u00e4nt\u00f6',
    tosTextAfter: '.',
    useNewCard: 'K\u00e4yt\u00e4 uutta korttia',
    useNewCardDropdownText: 'Lis\u00e4\u00e4 uusi kortti',
    useNewCardTextBefore:
      'K\u00e4yt\u00e4 maksaessasi tallennettua korttia tai ',
    useNewCardLinkText: 'k\u00e4yt\u00e4 uutta maksutapaa',
    useNewCardTextAfter: '.',
    cardSelect: 'Valitse luottokortti',
    checkoutFaster: 'Maksa nopeammin FordPaylla.',
    checkout: 'Kassa',
    nameOnCard: 'Kortissa oleva nimi',
    fullName: 'Koko nimi',
    cardNumber: 'Korttinumero',
    expirationDate: 'Vanh. Pvm',
    securityCode: 'Turvakoodi',
    invalidCCNMessage: 'Korttisi numero ei kelpaa.',
    invalidCVVMessage: 'Turvakoodisi ei kelpaa.',
    invalidExpDateMessage: 'Vanhenemisp\u00e4iv\u00e4si ei kelpaa.',
    emptyCCNMessage: 'Korttisi numerokentt\u00e4 on tyhj\u00e4.',
    emptyCVVMessage: 'Turvakoodikentt\u00e4 on tyhj\u00e4.',
    emptyExpDateMessage: 'Vanhentumisp\u00e4iv\u00e4kentt\u00e4 on tyhj\u00e4.',
    unsupportedCardMessage:
      'Korttisi numeroa ei tueta. Anna VISA, MasterCard tai AMEX.',
    paymentNotProcessed: 'Maksuasi ei voitu k\u00e4sitell\u00e4',
    redirectStatusFailure:
      'Maksuasi ei voitu k\u00e4sitell\u00e4. Yrit\u00e4 uudelleen.',
    continue: 'Jatkaa {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Napsauttamalla alla olevaa Jatka-painiketta hyv\u00e4ksyt',
    authCheckoutStatement:
      'Tarkista tallennetulla kortilla, k\u00e4yt\u00e4 uutta maksutapaa tai k\u00e4yt\u00e4 Pay By Bank -maksua.',
    useNewCardTextBeforePayByBank: 'Tarkista tallennetulla kortilla, ',
    usePayByBank: ', tai k\u00e4yt\u00e4 ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'K\u00e4yt\u00e4 Pay By Bank',
    use: 'K\u00e4yt\u00e4 ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'K\u00e4yt\u00e4 uutta maksutapaa',
    useNewPaymentDropdownText: 'k\u00e4yt\u00e4 uutta maksutapaa',
    useNewPaymentTextBefore: 'Tarkista tallennetulla maksutavalla tai ',
    useNewPaymentLinkText: 'k\u00e4yt\u00e4 uutta maksutapaa',
    useNewPaymentTextBeforePayByBank: 'Tarkista tallennetulla maksutavalla, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Sinulla ei t\u00e4ll\u00e4 hetkell\u00e4 ole maksutapoja lompakossasi. Aloita lis\u00e4\u00e4m\u00e4ll\u00e4 ensimm\u00e4inen maksutapa lompakkoosi.',
    addPaymentMethod: 'Lis\u00e4\u00e4 maksutapa',
    makeDefault: 'Aseta oletukseksi',
    changeDefaultPMSuccess: 'Oletusmaksutapasi on muuttunut.',
    makeDefaultSuccessPM: 'Maksutapa lis\u00e4tty oletusarvoksi.',
    makeDefaultErrorPM: 'Virhe maksutavan asettamisessa oletusarvoksi.',
    removePMSuccess: 'Maksutapa poistettu Ford.com-lompakosta',
    removePMError: 'Virhe maksutavan poistamisessa.',
    removePM: 'Poista maksutapa',
    removePMQuestion: 'Poista maksutapa?',
    removePMText:
      'Oletko varma, ett\u00e4 haluat poistaa maksutavan {{cardNumber}} lompakostasi? Kaikki t\u00e4h\u00e4n maksutapaan liittyv\u00e4t aktiiviset tilaukset ep\u00e4onnistuvat, jos maksutapa poistetaan.',
    defaultCard: 'Oletuskortti',
    defaultPM: 'Oletusmaksutapa\u00a0',
    defaultCardQuestion: 'Oletuskortti?',
    defaultPMQuestion: 'Oletusmaksutapa?',
    defaultCardText: 'Haluatko vaihtaa kortin {{cardNumber}} oletuskortiksesi?',
    defaultPMText:
      'Oletko varma, ett\u00e4 haluat vaihtaa oletusmaksutapasi {{cardNumber}}:ksi?',
    navigationLabel: 'Tilinhallinnan navigointi',
    removeDefaultPMSuccess: 'Oletusmaksutapa poistettu Ford.com-lompakosta',
    removeDefaultCardError: 'Virhe poistettaessa oletuskorttia.',
    removeDefaultPMError: 'Virhe oletusmaksutavan poistamisessa.',
    removeDefaultCard: 'Poista oletuskortti',
    removeDefaultPM: 'Haluatko poistaa oletusmaksutapasi?',
    removeDefaultPMText:
      'Maksutapa {{cardNumber}} on oletusmaksutapasi. Valitse uusi oletusmaksutapa alla. Kaikki t\u00e4h\u00e4n maksutapaan liittyv\u00e4t aktiiviset tilaukset ep\u00e4onnistuvat, jos maksutapa poistetaan.',
    removeDefaultPMButton: 'Poista maksutapa ja p\u00e4ivit\u00e4 oletus',
    removeDefaultSelectPM: 'Valitse uusi oletusmaksutapa',
    title: 'Lompakko',
    expiration: 'Vanh.\u00a0{{date}}',
    expires:
      'Vanhenemisp\u00e4iv\u00e4 {{p\u00e4iv\u00e4m\u00e4\u00e4r\u00e4}}',
    stripeDefaultError:
      'Tapahtui tekninen virhe. Yrit\u00e4 uudelleen.\u00a0\u00a0',
    paymentMethod: 'Maksutapa',
    tosTextBefore:
      'Klikkaamalla alla olevaa Tallenna kortti -painiketta hyv\u00e4ksyt ',
    tosTextBeforePM:
      'Klikkaamalla alla olevaa "Tallenna maksutapa" -painiketta hyv\u00e4ksyt',
    tosLinkText: 'maksuehdot ja tietosuojak\u00e4yt\u00e4nt\u00f6',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Kaikki',
    amount: 'Summa',
    date: 'Pvm',
    description: 'Kuvaus',
    displayingAll: 'N\u00e4ytet\u00e4\u00e4n kaikki tilitapahtumat.',
    displayingDays:
      'N\u00e4ytet\u00e4\u00e4n tapahtumat viimeiselt\u00e4 {{p\u00e4ivien m\u00e4\u00e4r\u00e4}} p\u00e4iv\u00e4lt\u00e4.',
    displayingYear: 'N\u00e4ytet\u00e4\u00e4n tapahtumat vuodelta {{vuosi}}.',
    filter: 'Edelliset {{numberOfDays}} p\u00e4iv\u00e4\u00e4',
    loadAll: 'N\u00e4yt\u00e4 kaikki',
    loadMore: 'N\u00e4yt\u00e4 {{x}} lis\u00e4\u00e4',
    noTransactions: 'Ei n\u00e4ytett\u00e4vi\u00e4 tilitapahtumia.',
    paymentMethod: 'Maksutapa',
    refund: 'Hyvitys',
    searchBar: 'Hae kuvauksen mukaan',
    timeFilter: 'Valitse tilitapahtumien ajanjakso',
    title: 'Tilitapahtumat',
    pending: 'Odottaa'
  },
  mit: {
    confirmPurchase: 'Vahvista ostos',
    paymentSuccessful: 'Maksu onnistui',
    saveCardAlert:
      'Jotta voit k\u00e4ytt\u00e4\u00e4 t\u00e4t\u00e4 korttia tuleviin maksuihin, se on tallennettava Ford.com-lompakkoosi.',
    title: 'Valitse maksutapa',
    tosTextBefore:
      'Klikkaamalla alla olevaa {{maksa-painiketta}} hyv\u00e4ksyt ',
    tosLinkText: 'maksuehdot ja tietosuojak\u00e4yt\u00e4nt\u00f6',
    tosTextAfter: '.',
    useNewCard: 'K\u00e4yt\u00e4 uutta korttia',
    useNewCardDropDownItem: 'K\u00e4yt\u00e4 uutta korttia',
    useNewCardTextBefore: 'Valitse tallennettu kortti tai ',
    useNewCardLinkText: 'k\u00e4yt\u00e4 uutta korttia',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'K\u00e4yt\u00e4 uutta maksutapaa',
    useNewPaymentDropdownText: 'k\u00e4yt\u00e4 uutta maksutapaa',
    useNewPaymentTextBefore: 'Tarkista tallennetulla maksutavalla tai ',
    useNewPaymentLinkText: 'k\u00e4yt\u00e4 uutta maksutapaa',
    successful: 'Onnistunut',
    savePaymentMethodAlert:
      'Jotta voit k\u00e4ytt\u00e4\u00e4 t\u00e4t\u00e4 maksutapaa tulevissa maksuissa, se on tallennettava lompakkoosi.'
  }
};
