import { ErrorState } from '@fpc/common/ErrorHandler';
import { LoadingSpinner, TechnicalError } from '@fpc/common';
import { FordPayUIStyle, loadingSpinnerBig } from '@fpc/common/Styles';
import { ReactElement } from 'react';

interface CheckoutWrapperProps {
  error: ErrorState;
  resetFromError: () => void;
  loading: boolean;
  checkoutComponent: ReactElement;
}

export function CheckoutLoadWrapper(props: CheckoutWrapperProps) {
  let checkoutComponent;
  if (props.error.present) {
    checkoutComponent = (
      <TechnicalError
        resetFromError={props.resetFromError}
        unrecoverable={props.error.unrecoverable}
      />
    );
  } else if (props.loading) {
    checkoutComponent = <LoadingSpinner style={loadingSpinnerBig} />;
  } else {
    checkoutComponent = props.checkoutComponent;
  }

  return <FordPayUIStyle>{checkoutComponent}</FordPayUIStyle>;
}
