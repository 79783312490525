import { enUS, TRANSLATION_MISSING } from './en-US';

export const itCH: typeof enUS = {
  common: {
    default: 'predefinita',
    endingIn: 'che termina con',
    close: 'Chiudere',
    makeDefaultCard: 'Salvare come carta predefinita',
    makeDefaultInfoHover:
      'Questa diventer\u00e0 automaticamente la sua carta predefinita perch\u00e9 \u00e8 la prima carta nel suo wallet.',
    processing: 'Elaborazione...',
    saveCardCheck: 'Salvare carta per transazioni future',
    saveCardSuccess: 'Carta aggiunta al Wallet di Ford.com',
    technicalErrorPayment:
      'Siamo spiacenti! Si \u00e8 verificato un problema tecnico e il suo pagamento non \u00e8 andata a buon fine. Riprovare.',
    timeoutSorry: 'Siamo spiacienti...',
    timeoutError: 'Stiamo riscontrando problemi tecnici.',
    timeoutTryAgain: 'Riprovare',
    savePaymentMethodCheck:
      'Salvare la modalit\u00e0 di pagamento per transazioni future',
    makeDefaultPaymentMethod:
      'Impostare come modalit\u00e0 di pagamento predefinita',
    savePaymentMethodButton: 'Salva metodo di pagamento',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Questo diventer\u00e0 automaticamente il tuo metodo di pagamento predefinito, perch\u00e9 \u00e8 il primo metodo di pagamento nel tuo portafoglio.',
    savePaymentMethodSuccess:
      'Il metodo di pagamento \u00e8 stato aggiunto a Wallet.'
  },
  checkout: {
    payButton: 'Pagare {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Siamo spiacenti! Stiamo riscontrando un problema tecnico e il suo pagamento non \u00e8 andato a buon fine. Aggiornare la pagina e riprovare. ',
    technicalErrorPaymentDetachFail:
      'Siamo spiacenti! Si \u00e8 verificato un problema tecnico e il suo pagamento non \u00e8 andata a buon fine. La sua carta \u00e8 stata salvata nel suo wallet. Riprovare.',
    tosTextBefore: 'Cliccando sul pulsante "Paga" qui di seguito, accetta i ',
    tosLinkText:
      "Termini e le condizioni di pagamento e l'Informativa sulla privacy",
    tosTextAfter: '.',
    useNewCard: 'Utilizzare una Nuova Carta',
    useNewCardDropdownText: 'Aggiungere nuova carta',
    useNewCardTextBefore: 'Effettuare il pagamento con una carta salvata o ',
    useNewCardLinkText: 'utilizzare una nuova modalit\u00e0 di pagamento',
    useNewCardTextAfter: '.',
    cardSelect: 'Selezione Carta di Credito',
    checkoutFaster: 'Effettuare il pagamento velocemente con FordPay.',
    checkout: 'Effettuare il pagamento',
    nameOnCard: 'Nome sulla carta',
    fullName: 'Nome completo',
    cardNumber: 'Numero di carta',
    expirationDate: 'Scad. Data',
    securityCode: 'CCV',
    invalidCCNMessage: 'Numero di carta non valido.',
    invalidCVVMessage: 'CCV non valido.',
    invalidExpDateMessage: 'Data di scadenza non valida.',
    emptyCCNMessage: 'Numero di carta vuoto.',
    emptyCVVMessage: 'CCV vuoto.',
    emptyExpDateMessage: 'Data di scadenza vuota.',
    unsupportedCardMessage:
      'Numero di carta non supportato. Inserire VISA, MasterCard o AMEX.',
    paymentNotProcessed: 'Impossibile elaborare il pagamento',
    redirectStatusFailure:
      'Impossibile elaborare il pagamento. Riprovare.\u00a0',
    continue: 'Continua {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Cliccando sul pulsante "Continua" qui di seguito, accetta i ',
    authCheckoutStatement:
      'Effettua il pagamento con la carta salvata, utilizza un nuovo metodo di pagamento o utilizza il pagamento tramite banca.',
    useNewCardTextBeforePayByBank:
      'Effettua il pagamento con la carta salvata, ',
    usePayByBank: ', o utilizza ',
    payByBankLinkText: 'il pagamento tramite banca',
    payByBankHeader: 'Utilizza il pagamento tramite banca.',
    use: 'Utilizzo ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Utilizzare una nuova modalit\u00e0 di pagamento',
    useNewPaymentDropdownText:
      'utilizzare una nuova modalit\u00e0 di pagamento',
    useNewPaymentTextBefore:
      'Effettua il pagamento con il metodo di pagamento salvato o ',
    useNewPaymentLinkText: 'utilizzare una nuova modalit\u00e0 di pagamento',
    useNewPaymentTextBeforePayByBank:
      'Effettua il pagamento con il metodo di pagamento salvato, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Al momento non hai alcun metodo di pagamento nel tuo portafoglio. Inizia aggiungendo il primo metodo di pagamento al tuo portafoglio.',
    addPaymentMethod: 'Aggiungere Modalit\u00e0 di Pagamento',
    makeDefault: 'Salvare come Predefinita',
    changeDefaultPMSuccess:
      'Il tuo metodo di pagamento predefinito \u00e8 cambiato.',
    makeDefaultSuccessPM: 'Metodo di pagamento aggiunto come predefinito.',
    makeDefaultErrorPM:
      "Errore durante l'impostazione del metodo di pagamento come predefinito.",
    removePMSuccess: 'Metodo di pagamento rimosso dal portafoglio Ford.com',
    removePMError: 'Errore durante la rimozione del metodo di pagamento.',
    removePM: 'Rimuovi il metodo di pagamento',
    removePMQuestion: 'Rimuovere il metodo di pagamento?\u00a0',
    removePMText:
      'Sei sicuro di voler rimuovere il metodo di pagamento {{cardNumber}} dal tuo portafoglio? Tutti gli abbonamenti attivi associati a questo metodo di pagamento falliranno se il metodo di pagamento viene rimosso.',
    defaultCard: 'Carta Predefinita',
    defaultPM: 'Metodo di pagamento predefinito',
    defaultCardQuestion: 'Carta Predefinita?',
    defaultPMQuestion: 'Metodo di pagamento predefinito?\u00a0',
    defaultCardText:
      '\u00c8 sicuro di volere cambiare la sua carta predefinita con {{cardNumber}}?',
    defaultPMText:
      'Sei sicuro di voler cambiare il tuo metodo di pagamento predefinito in {{cardNumber}}?',
    navigationLabel: 'Navigazione gestione account',
    removeDefaultPMSuccess:
      'Metodo di pagamento predefinito rimosso dal portafoglio Ford.com',
    removeDefaultCardError: 'Errore di rimozione della carta predefinita.',
    removeDefaultPMError:
      'Errore durante la rimozione del metodo di pagamento predefinito.',
    removeDefaultCard: 'Rimuovere la sua Carta Predefinita',
    removeDefaultPM: 'Rimuovere il tuo metodo di pagamento predefinito?',
    removeDefaultPMText:
      'Il metodo di pagamento {{cardNumber}} \u00e8 il tuo metodo di pagamento predefinito. Scegli un nuovo metodo di pagamento predefinito qui sotto. Tutti gli abbonamenti attivi associati a questo metodo di pagamento falliranno se il metodo di pagamento viene rimosso.',
    removeDefaultPMButton:
      'Rimuovi il metodo di pagamento e aggiorna il predefinito',
    removeDefaultSelectPM: 'Seleziona un nuovo metodo di pagamento predefinito',
    title: 'Wallet',
    expiration: 'Scad.\u00a0{{date}}',
    expires: 'Scadenza: {{date}}',
    stripeDefaultError:
      'Si \u00e8 verificato un problema tecnico. Riprovare.\u00a0',
    paymentMethod: 'Modalit\u00e0 di Pagamento',
    tosTextBefore: 'Cliccando sul pulsante "Paga" qui di seguito, accetta i ',
    tosTextBeforePM:
      'Cliccando sul pulsante "Salva metodo di pagamento" qui sotto, accetti di',
    tosLinkText:
      "Termini e le condizioni di pagamento e l'Informativa sulla privacy",
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Tutti',
    amount: 'Importo',
    date: 'Data',
    description: 'Descrizione',
    displayingAll: 'Visualizzazione di tutte le transazioni.',
    displayingDays:
      'Visualizzazione delle transazioni a partire dagli ultimi {{numberOfDays}} giorni.',
    displayingYear: 'Visualizzazione delle transazioni a partire dal {{year}}.',
    filter: 'Ultimi {{numberOfDays}} Giorni',
    loadAll: 'Caricare Tutti',
    loadMore: 'Caricare altri {{x}}',
    noTransactions: 'Nessuna transazione da visualizzare.',
    paymentMethod: 'Modalit\u00e0 di Pagamento',
    refund: 'Rimborso',
    searchBar: 'Ricerca per descrizione',
    timeFilter:
      'Selezionare un lasso di temo per la cronologia delle transazioni',
    title: 'Transazioni',
    pending: 'In attesa di'
  },
  mit: {
    confirmPurchase: "Confermare l'acquisto",
    paymentSuccessful: 'Pagamento effettuato correttamente',
    saveCardAlert:
      'Per utilizzare questa carta per pagamenti futuri, \u00e8 necessario salvarla nel Wallet di Ford.com.',
    title: 'Selezione Modalit\u00e0 di Pagamento',
    tosTextBefore:
      'Cliccando sul pulsante {{paymentButtonText}} qui di seguito, accetta i ',
    tosLinkText:
      "Termini e le condizioni di pagamento e l'Informativa sulla privacy",
    tosTextAfter: '.',
    useNewCard: 'Utilizzare una Nuova Carta',
    useNewCardDropDownItem: 'Utilizzare nuova carta',
    useNewCardTextBefore: 'Selezionare una carta salvata o ',
    useNewCardLinkText: 'utilizzare una nuova carta',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Utilizzare una nuova modalit\u00e0 di pagamento',
    useNewPaymentDropdownText:
      'utilizzare una nuova modalit\u00e0 di pagamento',
    useNewPaymentTextBefore:
      'Effettua il pagamento con il metodo di pagamento salvato o ',
    useNewPaymentLinkText: 'utilizzare una nuova modalit\u00e0 di pagamento',
    successful: 'Riuscito',
    savePaymentMethodAlert:
      'Per utilizzare questo metodo di pagamento per pagamenti futuri, \u00e8 necessario salvarlo nel tuo portafoglio.'
  }
};
