import { Style } from '../Styles';
import { StylesConfig } from 'react-select';
import {
  dropDownContainerStyles,
  dropDownControlStyles,
  dropDownIndicatorsContainerStyles,
  dropDownMenuStyles,
  dropDownOptionStyles,
  dropDownPlaceholderStyles,
  dropDownSingleValueStyles,
  dropDownValueContainerStyles
} from '@fpc/common/components/DropDownStyles';

export const filterDropDownStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided,
    ...dropDownContainerStyles,
    boxShadow: state.isFocused ? Style.boxShadow3 : 'none'
  }),
  valueContainer: () => ({
    ...dropDownValueContainerStyles,
    paddingLeft: '1em',
    paddingRight: '.5em',
    minWidth: '8.5em'
  }),
  option: (provided, state) => ({
    ...provided,
    ...dropDownOptionStyles,
    backgroundColor: state.isFocused ? `${Style.color.lightBlue}20` : 'white',
    '&:hover': {
      backgroundColor: `${Style.color.lightBlue}20`
    }
  }),
  control: (provided, state) => ({
    ...provided,
    ...dropDownControlStyles,
    borderBottomRightRadius: state.menuIsOpen ? '0px' : '4px',
    borderBottomLeftRadius: state.menuIsOpen ? '0px' : '4px',
    border:
      state.isFocused && !state.menuIsOpen
        ? `2px ${Style.color.hover} solid`
        : `1px ${Style.color.gray4} solid`,
    justifyContent: 'space-between'
  }),
  singleValue: (provided) => ({
    ...provided,
    ...dropDownSingleValueStyles,
    margin: 0
  }),
  placeholder: (provided) => ({
    ...provided,
    ...dropDownPlaceholderStyles
  }),
  menu: (provided) => ({
    ...provided,
    ...dropDownMenuStyles
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided) => ({
    ...provided,
    ...dropDownIndicatorsContainerStyles,
    paddingRight: '.5em'
  })
};
