import { ReactElement } from 'react';

export const CARD_URLS = [
  {
    card: 'VISA',
    url: 'https://pay.bluesnap.com/web-sdk/5/assets/cards/visa.png'
  },
  {
    card: 'MASTERCARD',
    url: 'https://pay.bluesnap.com/web-sdk/5/assets/cards/mastercard.png'
  },
  {
    card: 'AMEX',
    url: 'https://pay.bluesnap.com/web-sdk/5/assets/cards/amex.png'
  }
];

interface BluesnapSupportedCardsProps {
  cardType: string | null;
}

export function BluesnapSupportedCards(props: BluesnapSupportedCardsProps) {
  function getSupportedCards(): ReactElement {
    let images = CARD_URLS.map((card) => (
      <img
        src={card.url}
        alt={card.card}
        style={styles.cardImage}
        key={card.card}
      />
    ));
    return <>{images}</>;
  }

  function getSelectedCard(cardType: string) {
    let card = CARD_URLS.find((card) => card.card === cardType);
    if (card) {
      return <img alt={card.card} src={card.url} style={styles.cardImage} />;
    } else {
      return (
        <div className="unknown-card-img" data-testid="unknown-card-img"></div>
      );
    }
  }

  function getCardImages(cardType: string | null): ReactElement {
    if (!cardType || cardType === 'UNKNOWN') {
      return getSupportedCards();
    } else {
      return <>{getSelectedCard(cardType)}</>;
    }
  }

  return (
    <div
      className="input-group-addon supported-cards-container"
      data-testid="supported-cards-container"
      style={{
        verticalAlign: 'middle',
        padding: '0 10px',
        borderLeft: 0,
        alignItems: 'center',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '3rem',
        fontSize: '1em',
        gap: '5px'
      }}
    >
      {getCardImages(props.cardType)}
    </div>
  );
}

const styles = {
  cardImage: {
    width: '30px',
    height: '20px',
    border: '1px solid #cfcfcf'
  }
};
