export default {
  'errors.code.expired_card': 'Kortet er utløpt.',
  'errors.code.incorrect_cvc': 'Kortets sikkerhetskode er ikke korrekt.',
  'errors.code.incorrect_number': 'Kortnummeret er ikke korrekt.',
  'errors.code.incorrect_zip':
    'Kortet ditt kunne ikke autoriseres ved å bruke det oppgitte postnummeret. Oppdater postnummeret, eller kontakt kortutstederen din for mer informasjon.',
  'errors.code.invalid_cvc': 'Kortets sikkerhetskode er ugyldig.',
  'errors.code.invalid_expiry_month': 'Kortets utløpsdato er ugyldig.',
  'errors.code.invalid_expiry_month_past': 'Kortets utløpsdato er passert.',
  'errors.code.invalid_expiry_year': 'Kortets utløpsår er ugyldig.',
  'errors.code.invalid_expiry_year_past': 'Kortets utløpsår er passert.',
  'errors.code.invalid_number': 'Kortnummer er ugyldig.',
  'errors.code.processing_error':
    'En feil oppstod ved behandling av ditt kort. Prøv igjen om en liten stund.',
  'errors.code.processing_error_intransient':
    'Noe gikk galt. Prøv igjen senere.',
  'errors.declines.call_issuer':
    'Kortet er blitt avvist. Du kan ringe banken for å få detaljert informasjon.',
  'errors.declines.card_not_supported': 'Kortet ditt støttes ikke.',
  'errors.declines.card_velocity_exceeded':
    'Kortet ble avvist fordi det ble foretatt for mange forsøk for ofte.',
  'errors.declines.currency_not_supported':
    'Kortet støttes ikke for denne valutaen.',
  'errors.declines.generic_decline': 'Kortet er avvist.',
  'errors.declines.generic_decline_link': 'Betalingsmetoden din ble avvist.',
  'errors.declines.generic_decline_non_cards': 'Betalingen ble avvist.',
  'errors.declines.incorrect_pin': 'Feil PIN-nummer.',
  'errors.declines.insufficient_funds': 'Kortet ditt har ikke nok penger.',
  'errors.declines.invalid_account': 'Ugyldig konto.',
  'errors.declines.invalid_amount': 'Ugyldig beløp.',
  'errors.declines.not_permitted':
    'Kortmedlemmet er ikke registrert / har ikke tillatelse',
  'errors.declines.pin_try_exceeded':
    'Tillatt antall PIN-nummerforsøk ble overskredet.',
  'errors.declines.transaction_not_allowed':
    'Kortet ditt støtter ikke denne typen kjøp.'
};
