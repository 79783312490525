import { PaymentResponse } from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import { initializeStripe } from '@fpc/api/stripe/Stripe';
import { StripeError } from '@stripe/stripe-js';
import { getGeneralStripeError } from '@fpc/api/stripe/StripeErrors';

export async function callHandleNextAction(
  response: PaymentResponse,
  successCallback: (
    lastPaymentError: boolean,
    isActionRequiredForPromptPay?: boolean
  ) => void,
  errorCallback: (error: StripeError) => void
) {
  const stripeWithNextAction = await initializeStripe(
    response.merchantAccountId
  );
  if (stripeWithNextAction) {
    const { error, paymentIntent } =
      await stripeWithNextAction.handleNextAction({
        clientSecret: response.paymentIntentClientSecret
      });
    if (paymentIntent) {
      const isActionRequiredForPromptPay =
        paymentIntent.payment_method_types?.includes('promptpay') &&
        paymentIntent.status === 'requires_action' &&
        paymentIntent.next_action?.type === 'promptpay_display_qr_code';
      successCallback(
        paymentIntent.last_payment_error !== null,
        isActionRequiredForPromptPay
      );
    } else if (error) {
      errorCallback(error);
    } else {
      errorCallback(getGeneralStripeError());
    }
  } else {
    errorCallback(getGeneralStripeError());
  }
}
