import { LoadingSpinner } from '@fpc/common';

export function buttonContentsWithSpinner(input: string) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '-1em',
        marginLeft: '-1em'
      }}
    >
      <span style={{ paddingRight: '1em' }}>{input}</span>
      <LoadingSpinner size={'1.7em'} invert />
    </div>
  );
}
