import { statusReject } from '@fpc/api/statusReject';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export interface BluesnapPaymentObject {
  transactionId: string;
}

export async function makeBluesnapGuestPayment(
  paymentInfoToken: string,
  bluesnapPaymentToken: string,
  bearerToken?: string,
  digitalSignature?: string | null
): Promise<BluesnapPaymentObject> {
  let targetServer = process.env.PAYMENT_APP_SERVER;
  let headers = HeadersBuilder();
  let endpoint, paymentInfoTokenPayload;
  if (digitalSignature) {
    targetServer = `${process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER}`;
    headers.set('Authorization', digitalSignature);
    endpoint = '/api/dss/guest-payment';
    paymentInfoTokenPayload = JSON.parse(paymentInfoToken).paymentInfo;
  } else {
    headers.set('Authorization', 'Bearer ' + bearerToken);
    endpoint = '/api/guest-payment';
    paymentInfoTokenPayload = paymentInfoToken;
  }
  return fetch(`${targetServer}${endpoint}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      paymentInfoToken: paymentInfoTokenPayload,
      bluesnapPaymentToken: bluesnapPaymentToken
    })
  })
    .then((guestPaymentResponse) => {
      if (guestPaymentResponse.ok) {
        return guestPaymentResponse.json();
      } else if (guestPaymentResponse.status === 401) {
        return Promise.reject(statusReject(guestPaymentResponse));
      } else {
        return guestPaymentResponse.json().then((data) => {
          return Promise.reject(data);
        });
      }
    })
    .catch((err) => {
      console.warn('Error submitting payment request: ', err);
      return Promise.reject(err);
    });
}
