import { AttachPaymentMethod } from '@fpc/api/paymentapp';
import { statusReject } from '@fpc/api/statusReject';
import { HeadersBuilder } from '@fpc/utils/headerBuilder';

export async function attachPaymentMethod(attachPayment: AttachPaymentMethod) {
  let requestUrl = attachPayment.bffBaseUrl
    ? `${attachPayment.bffBaseUrl}${process.env.CUSTOMER_APP_APIGEE_BASE_PATH}/v2/customers/payment-methods/attach`
    : `${process.env.CUSTOMER_APP_SERVER}/v1/customers/payment-methods/attach`;
  let headers: Headers = HeadersBuilder();
  let request;
  let body = {
    paymentMethodId: attachPayment.paymentMethodId,
    connectCustomerId: attachPayment.connectCustomerId,
    merchantAccountId: attachPayment.merchantAccountId
  };
  if (
    attachPayment.bearerToken &&
    attachPayment.customerAuthTokenType &&
    attachPayment.customerAuthToken &&
    !attachPayment.bffBaseUrl
  ) {
    headers.set('x-token', attachPayment.customerAuthToken);
    headers.set('x-token-type', attachPayment.customerAuthTokenType);
    headers.set('Authorization', `Bearer ${attachPayment.bearerToken}`);
  }

  request = new Request(requestUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  });

  return fetch(request)
    .then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          return;
        });
      } else if (response.status === 401) {
        return Promise.reject(statusReject(response));
      } else {
        return response.json().then((data) => {
          return Promise.reject(data);
        });
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
