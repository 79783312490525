import styled from 'styled-components';

export const TextInput = styled.input`
  && {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border: none;
    font-size: 1em;
    outline: 0px;
    font-weight: 100;
    color: #000;
  }

  &&::placeholder {
    color: #d5d5d5;
  }
`;

export const IconsContainer = styled.div`
  && {
    color: #000;
    width: 25%;
    text-align: end;
    overflow: hidden;
  }

  @media (max-width: 320px) {
    display: none;
  }
`;
