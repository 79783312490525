import { Style } from '../Styles';
import styled from 'styled-components/macro';
import { ReactComponent as Check } from '../icons/check.svg';

interface CheckBoxProps {
  label: string;
  onChange: Function;
  isChecked: boolean;
  uniqueId: string;
  disabled?: boolean;
}

const CheckboxContainer = styled.div`
  && {
    display: flex;
    text-align: start;
  }
`;

const CheckboxLabel = styled.label<{ color: string }>`
  && {
    color: ${(props) => props.color};
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0px;
    cursor: pointer;
    margin-right: 0.75em;
  }

  &&:focus-within span {
    border-color: ${Style.color.hover};
  }
`;

const CheckboxInput = styled.input`
  && {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

const Checkmark = styled(Check)<{ hidden: boolean; color: string }>`
  && {
    ${(props) => {
      return props.hidden ? 'display: none;' : '';
    }}
    height: 60%;
    width: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
  }

  && rect {
    fill: ${(props) => props.color};
  }
`;

const Box = styled.span<{ color: string }>`
  && {
    padding: 0.15em;
    appearance: none;
    display: inline-block;
    height: 1em;
    width: 1em;
    border-color: ${(props) => props.color};
    border-width: 2px;
    border-radius: 0.125em;
    border-style: solid;
    margin-left: 4px;
    margin-right: 0.5em;
    position: relative;
    top: 0.15em;

    &:hover {
      border-color: ${Style.color.hover};
    }
  }
`;

export const CheckboxShadowbox = styled.div<{ isFirstCard?: boolean }>`
  background: #f9f9f9;
  padding: 0.75em 1.25em;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  display: flex;
  flex-direction: ${(props) => (props.isFirstCard ? 'row' : 'column')};
  gap: 1em;
`;

export function Checkbox(props: CheckBoxProps) {
  const color = props.disabled ? Style.color.disabled : Style.color.primary;
  return (
    <CheckboxContainer>
      <CheckboxLabel htmlFor={props.uniqueId} color={color}>
        <CheckboxInput
          id={props.uniqueId}
          data-testid={props.uniqueId}
          type="checkbox"
          checked={props.isChecked}
          onChange={() => props.onChange(!props.isChecked)}
          disabled={props.disabled}
        />
        <Box color={color} aria-hidden={true}>
          <Checkmark
            hidden={!props.isChecked}
            color={color}
            aria-hidden={true}
          />
        </Box>
        {props.label}
      </CheckboxLabel>
    </CheckboxContainer>
  );
}
