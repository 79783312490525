import {
  ACCOUNT_ID_PARAM,
  PAYMENT_RESPONSE_PARAM,
  PAYMENT_INTENT_CLIENT_SECRET_PARAM,
  PAYMENT_INTENT_PARAM,
  PAYMENT_INTENT_SECRET_PARAM,
  PAYMENT_TYPE_PARAM,
  REDIRECT_STATUS_PARAM
} from '../checkout';
import { getFirstUrlQueryDelimiter } from '@fpc/utils/urlQueryDelimiter';
import { SUCCESS_STATUS } from '@fpc/common';
import { Buffer } from 'buffer';
import { MultiMerchantPaymentResponse } from '@fpc/api/paymentapp/MakeAuthenticatedPayment';

export function buildManualRedirectUrl(
  redirectUrl: string,
  merchantAccountId: string | undefined,
  paymentIntentClientSecret: string,
  paymentType: string,
  guest: boolean = false,
  redirectStatus: string = SUCCESS_STATUS
) {
  const paymentIntent = paymentIntentClientSecret.slice(
    0,
    paymentIntentClientSecret.indexOf('_secret_')
  );

  const queryDelimiter = getFirstUrlQueryDelimiter(redirectUrl);

  return (
    `${redirectUrl}` +
    `${queryDelimiter}${ACCOUNT_ID_PARAM}=${merchantAccountId}` +
    `&${PAYMENT_INTENT_PARAM}=${paymentIntent}` +
    `&${PAYMENT_INTENT_SECRET_PARAM}=${paymentIntentClientSecret}` +
    (guest
      ? `&${PAYMENT_INTENT_CLIENT_SECRET_PARAM}=${paymentIntentClientSecret}`
      : '') +
    `&${REDIRECT_STATUS_PARAM}=${redirectStatus}` +
    `&${PAYMENT_TYPE_PARAM}=${paymentType}`
  );
}

export function buildManualRedirectUrlForMultiMerchant(
  redirectUrl: string,
  response: MultiMerchantPaymentResponse
) {
  const jsonString = JSON.stringify(response);
  const base64Encoded = Buffer.from(jsonString).toString('base64');
  const queryDelimiter = getFirstUrlQueryDelimiter(redirectUrl);

  return (
    `${redirectUrl}` +
    `${queryDelimiter}${PAYMENT_RESPONSE_PARAM}=${base64Encoded}`
  );
}
