import { StripeCheckoutProps } from './StripeCheckoutWrapper';
import { validateCheckout } from '../CheckoutCommon';
import { Dispatch, useEffect, useState } from 'react';
import { CHECKOUT_ELEMENT } from '@fpc/reactutils/checkoutContextProvider';
import { getAuthTransaction, PaymentMethodInfo } from '@fpc/api/paymentapp';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from '../features/multi-merchant/types/PaymentInfoToken';
import { ACSS_DEBIT_METHOD } from '@fpc/checkout/index';
import { AuthTransactionRequest } from '@fpc/api/paymentapp/GetAuthTransaction';

export function useStripeCheckoutContext(
  props: StripeCheckoutProps,
  errorDispatch: Dispatch<ErrorCondition>
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [fordCustomerId, setFordCustomerId] = useState('');
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null);
  const [cards, setCards] = useState<PaymentMethodInfo[] | null>(null);
  const [transaction, setTransaction] = useState<
    StripeTransactionDetails | MultiMerchantStripeTransactionDetails | null
  >(null);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<string>('');
  const [noCreditCards, setNoCreditCards] = useState<boolean>(false);
  const [achNoPay] = useState<boolean>(false);
  const isAcssDebit =
    props.transactionDetails.paymentMethodTypesExcluded?.includes(
      ACSS_DEBIT_METHOD.toUpperCase()
    );
  const { isValidCheckout, isAuthCheckout } = validateCheckout(props);

  useEffect(() => {
    if (!isValidCheckout) {
      setLoading(false);
    }
  }, [isValidCheckout]);

  useEffect(() => {
    errorDispatch(ErrorCondition.Reset);
    getCheckoutContext();
  }, [props.bearerToken, props.customerAuthToken, props.paymentInfoToken]);

  function getCheckoutContext() {
    if (isAuthCheckout) {
      const authTxReq: AuthTransactionRequest = {
        ...props,
        bearerToken: props.bearerToken!,
        customerAuthToken: props.customerAuthToken!,
        bffBaseUrl: props.bffBaseUrl ?? '',
        merchantAccountId: isAcssDebit
          ? props.transactionDetails.merchantAccountId
          : undefined
      };

      getAuthTransaction(authTxReq)
        .then((response) => {
          setStripeCustomerId(response.pspCustomerId);
          setFordCustomerId(response.fordCustomerId);
          setTransaction(props.transactionDetails);
          setCards(response.paymentMethods);
          setLoading(false);
          setNoCreditCards(props.noCreditCards);
        })
        .catch((err) => {
          handleErrorDispatch(err, isAuthCheckout);
        });
    } else if (isValidCheckout) {
      setTransaction(props.transactionDetails);
      setLoading(false);
    }
  }

  function handleErrorDispatch(err: any, isAuth: boolean) {
    if (err.unrecoverable) {
      errorDispatch(ErrorCondition.Unrecoverable);
      dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
    } else if (isAuth) {
      setTransaction(props.transactionDetails);
      setLoading(false);
    }
  }

  function resetFromError() {
    errorDispatch(ErrorCondition.Reset);
    setLoading(true);
    setTransaction(null);
    setPaymentIntentClientSecret('');
    setCards(null);
    setStripeCustomerId(null);
    setFordCustomerId('');
    setNoCreditCards(false);
    getCheckoutContext();
  }

  return {
    loading,
    fordCustomerId,
    stripeCustomerId,
    cards,
    transaction,
    paymentIntentClientSecret,
    noCreditCards,
    resetFromError,
    achNoPay
  };
}
