import styled from 'styled-components/macro';
import { Style } from '../Styles';

export const Button = styled.button`
  && {
    --scale: 1;
    font-family: ${Style.fontFamilyCondLight};
    font-size: 1.125em;
    line-height: 1.5em;
    font-stretch: condensed;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0.5em 3em;
    position: relative;
    color: ${Style.color.text};
    z-index: 1;
    outline: none;
    outline-offset: 0.8em;
    cursor: pointer;
    margin: 0;
  }

  &&:hover,
  &&:focus {
    --scale: 1.07;
    font-family: ${Style.fontFamilyCondLight};
    font-size: 1.125em;
    line-height: 1.5em;
    font-stretch: condensed;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0.5em 3em;
    position: relative;
    color: ${Style.color.text};
    z-index: 1;
    outline: none;
    outline-offset: 0.8em;
    cursor: pointer;
    margin: 0;
  }

  &&:hover::after,
  &&:focus::after {
    background-color: ${Style.color.secondary};
  }

  &&::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2em;
    border: 0.1em solid transparent;
    background-color: ${Style.color.primary};
    z-index: -1;
    transform: scale(var(--scale));
    will-change: transform, background-color, border-color;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }

  &&:disabled {
    cursor: not-allowed;
    --scale: 1;
    font-family: ${Style.fontFamilyCondLight};
    font-size: 1.125em;
    line-height: 1.5em;
    font-stretch: condensed;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0.5em 3em;
    position: relative;
    color: ${Style.color.text};
    z-index: 1;
    outline: none;
    outline-offset: 0.8em;
    margin: 0;
  }

  &&:disabled::after {
    background-color: ${Style.color.disabled};
    box-shadow: none;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
`;
