import { getIcon } from '@fpc/common';

interface ICardBrandIcons {
  icons: string[];
}
const CardBrandIcons = (props: ICardBrandIcons) => {
  const { icons } = props;

  return (
    <>
      {icons.map((iconName) => {
        const Icon = getIcon(iconName);
        return (
          <>
            <Icon style={{ height: '100%' }} />
            &nbsp;&nbsp;
          </>
        );
      })}
    </>
  );
};

export default CardBrandIcons;
