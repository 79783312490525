import styled from 'styled-components/macro';

export const TooltipWrapper = styled.div`
  && {
    display: inline-block;
    position: relative;
  }
`;

// --tooltip-margin: ${(props) => {
//   return props.wrap ? '375%' : '30px';
// }};

export const TooltipTip = styled.div<{
  color: string;
  backgroundColor: string;
  isWrap: boolean;
}>`
  && {
    --tooltip-text-color: ${(props) => props.color};
    --tooltip-background-color: ${(props) => props.backgroundColor};
    --tooltip-wrap: ${(props) => {
      return props.isWrap ? 'normal' : 'nowrap';
    }};
    --tooltip-min-width: ${(props) => {
      return props.isWrap ? '12em' : 'fit-content';
    }};
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
    --tooltip-border-width: 1px;

    position: absolute;
    border-radius: 4px;
    border-color: var(--tooltip-text-color);
    border-width: var(--tooltip-border-width);
    border-style: solid;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--tooltip-text-color);
    background-color: var(--tooltip-background-color);
    font-size: 0.75em;
    z-index: 110;
    white-space: var(--tooltip-wrap);
    min-width: var(--tooltip-min-width);
  }

  &&.top {
    top: ${(props) => {
      return props.isWrap ? '-275%' : 'calc(var(--tooltip-margin) * -1)';
    }};
  }

  &&.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  &&.bottom {
    bottom: ${(props) => {
      return props.isWrap ? '-275%' : 'calc(var(--tooltip-margin) * -1)';
    }};
  }

  &&.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
`;

export const FillSpan = styled.span`
  && {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }

  &&.top {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }

  &&.right {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }

  &&.bottom {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }

  &&.left {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }
`;

export const BorderSpan = styled.span`
  && {
    --tooltip-arrow-border-size: calc(
      var(--tooltip-arrow-size) + calc(var(--tooltip-border-width) * 1.5)
    );

    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-border-size);
    margin-left: calc(var(--tooltip-arrow-border-size) * -1);
  }

  &&.top {
    top: 100%;
    border-top-color: var(--tooltip-text-color);
  }

  &&.right {
    left: calc(var(--tooltip-arrow-border-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-text-color);
  }

  &&.bottom {
    bottom: 100%;
    border-bottom-color: var(--tooltip-text-color);
  }

  &&.left {
    left: auto;
    right: calc(var(--tooltip-arrow-border-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-text-color);
  }
`;
