import { Payu } from './Payu';
import {
  CheckoutTokens,
  PayuCheckout,
  PayuCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import { CommonCheckoutProps } from '../CheckoutCommon';
import { PayUTransactionDetails } from '@fpc/common/transactionInterfaces';
import { useEffect, useMemo, useReducer } from 'react';
import {
  ErrorCondition,
  errorReducer,
  initialErrorState
} from '@fpc/common/ErrorHandler';

export interface PayUCheckoutProps extends CommonCheckoutProps {
  transactionDetails: PayUTransactionDetails;
}
export function PayuCheckoutWrapper(props: PayUCheckoutProps) {
  const transactionDetails = props.transactionDetails;
  const redirectUrl = props.redirectUrl;
  const [_, errorDispatch] = useReducer(errorReducer, initialErrorState);

  const checkoutTokens: CheckoutTokens = {
    paymentInfoToken: props.paymentInfoToken,
    paymentObjectType: props.paymentObjectType,
    customerAuthTokenType: props.customerAuthTokenType,
    bearerToken: props.bearerToken,
    customerAuthToken: props.customerAuthToken,
    digitalSignature: props.digitalSignature
  };

  const transaction: PayuCheckout = useMemo(
    () => ({
      transaction: transactionDetails,
      tokens: checkoutTokens,
      redirectUrl: redirectUrl,
      errorDispatch: errorDispatch
    }),
    [props, transactionDetails, redirectUrl]
  );

  useEffect(() => {
    errorDispatch(ErrorCondition.Reset);
  }, [props]);
  return (
    <PayuCheckoutContext.Provider value={transaction}>
      <Payu />
    </PayuCheckoutContext.Provider>
  );
}
