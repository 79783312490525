import { statusReject } from '@fpc/api/statusReject';
import { HeadersInitBuilder } from '@fpc/utils/headerBuilder';

export interface BluesnapAuthPaymentResponse {
  transactionId: string;
}

export async function makeBluesnapAuthPayment(
  paymentInfoToken: string,
  bluesnapPaymentToken: string,
  customerId: string,
  vaultedShopperId: number,
  bearerToken?: string,
  bffBaseUrl?: string | null
): Promise<BluesnapAuthPaymentResponse> {
  const authPayUrl = bffBaseUrl
    ? `${bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}/api/v2`
    : `${process.env.PAYMENT_APP_SERVER}/api`;
  const authHeader = !bffBaseUrl
    ? { Authorization: 'Bearer ' + bearerToken }
    : undefined;

  const paymentInfoObj = !bffBaseUrl
    ? { paymentInfoToken: paymentInfoToken }
    : {
        paymentInfoJson: paymentInfoToken
      };

  return fetch(`${authPayUrl}/auth-payment`, {
    method: 'POST',
    headers: {
      ...HeadersInitBuilder(),
      ...authHeader
    },
    body: JSON.stringify({
      ...paymentInfoObj,
      bluesnapPaymentToken: bluesnapPaymentToken,
      customerId: customerId,
      vaultedShopperId: vaultedShopperId
    })
  })
    .then((authPaymentResponse) => {
      if (authPaymentResponse.ok) {
        return authPaymentResponse.json();
      } else if (authPaymentResponse.status === 401) {
        return Promise.reject(statusReject(authPaymentResponse));
      } else {
        return authPaymentResponse.json().then((data) => {
          return Promise.reject(data);
        });
      }
    })
    .catch((err) => {
      console.warn('Error submitting payment request: ', err);
      return Promise.reject(err);
    });
}
