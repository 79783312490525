export interface RejectStatus {
  statusCode: number;
  statusMessage: string;
  unrecoverable: boolean;
}

export function statusReject(res: Response): RejectStatus {
  const unrecoverable = res.status === 401;
  const statusText = unrecoverable ? 'Authorization Error' : 'Request Error';
  const statusMessage = res.statusText;
  if (res.status === 400) {
    return {
      statusCode: res.status,
      statusMessage: statusMessage,
      unrecoverable: unrecoverable
    };
  } else {
    return {
      statusCode: res.status,
      statusMessage: statusText,
      unrecoverable: unrecoverable
    };
  }
}
