import styled from 'styled-components/macro';
import { Style } from '../Styles';

export const UnstyledButton = styled.button`
  && {
    all: unset;
    cursor: pointer;
  }
`;

/** Adds an outline to the UnstyledButton component on focus. */
export const UnstyledButtonWithFocus = styled(UnstyledButton)<{
  focusColor?: string;
}>`
  && {
    height: 1.5em;
    width: 1.5em;
  }

  &&:focus {
    outline: 1px solid ${(props) => props.focusColor ?? Style.color.secondary};
    outline-offset: 1px;
  }
`;
