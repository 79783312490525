import { enUS, TRANSLATION_MISSING } from './en-US';

export const noNO: typeof enUS = {
  common: {
    default: 'standard',
    endingIn: 'avsluttes om',
    close: 'Lukk',
    makeDefaultCard: 'Angi som standardkort',
    makeDefaultInfoHover:
      'Dette blir automatisk standardkortet ditt, fordi det er det f\u00f8rste kortet i lommeboken.',
    processing: 'Behandler\u00a0\u2026',
    saveCardCheck: 'Lagre kort for fremtidige transaksjoner',
    saveCardSuccess: 'Kort lagt til i Ford.com-lommeboken.',
    technicalErrorPayment:
      'Beklager! Det oppstod et teknisk problem, og betalingen din kunne ikke behandles. Pr\u00f8v p\u00e5\u00a0nytt.',
    timeoutSorry: 'Beklager\u00a0\u2026',
    timeoutError: 'Vi har tekniske problemer for \u00f8yeblikket.',
    timeoutTryAgain: 'Pr\u00f8v p\u00e5 nytt',
    savePaymentMethodCheck:
      'Lagre betalingsm\u00e5te for fremtidige transaksjoner',
    makeDefaultPaymentMethod: 'Angi som standard betalingsm\u00e5te',
    savePaymentMethodButton: 'Lagre betalingsm\u00e5te',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Dette blir automatisk din standard betalingsm\u00e5te, fordi det er den f\u00f8rste betalingsm\u00e5ten i lommeboken din.',
    savePaymentMethodSuccess: 'Betalingsm\u00e5ten er lagt til i Wallet.'
  },
  checkout: {
    payButton: 'Betal {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Beklager! Vi hadde et teknisk problem, og betalingen din kunne ikke behandles. Oppdater siden og p\u00e5 nytt. ',
    technicalErrorPaymentDetachFail:
      'Beklager! Det oppstod et teknisk problem, og betalingen din kunne ikke behandles. Kortet ditt ble lagret i lommeboken. Pr\u00f8v p\u00e5\u00a0nytt.',
    tosTextBefore:
      'Ved \u00e5 trykke p\u00e5 \u00abBetal\u00bb-knappen nedenfor godtar du ',
    tosLinkText:
      'betalingsvilk\u00e5rene og -betingelsene, samt personvernserkl\u00e6ringen',
    tosTextAfter: '.',
    useNewCard: 'Bruk nytt kort',
    useNewCardDropdownText: 'Legg til nytt kort',
    useNewCardTextBefore: 'Betal med lagret kort i kassen eller ',
    useNewCardLinkText: 'bruk ny betalingsm\u00e5te',
    useNewCardTextAfter: '.',
    cardSelect: 'Valg av kredittkort',
    checkoutFaster: 'Betal raskere i kassen med FordPay.',
    checkout: 'Kasse',
    nameOnCard: 'Navn p\u00e5 kort',
    fullName: 'Fullt navn',
    cardNumber: 'Kortnummer',
    expirationDate: 'Utl. dato',
    securityCode: 'Sikkerhetskode',
    invalidCCNMessage: 'Kortnummeret er ikke gyldig.',
    invalidCVVMessage: 'Sikkerhetskoden er ikke gyldig.',
    invalidExpDateMessage: 'Utl\u00f8psdatoen er ikke gyldig.',
    emptyCCNMessage: 'Kortnummer st\u00e5r tomt.',
    emptyCVVMessage: 'Sikkerhetskode st\u00e5r tomt.',
    emptyExpDateMessage: 'Utl\u00f8psdato st\u00e5r tomt.',
    unsupportedCardMessage:
      'Kortnummeret st\u00f8ttes ikke. Oppgi VISA, MasterCard eller AMEX.',
    paymentNotProcessed: 'Betalingen kunne ikke behandles',
    redirectStatusFailure:
      'Betalingen kunne ikke behandles. Pr\u00f8v p\u00e5\u00a0nytt.\u00a0',
    continue: 'Fortsette {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Ved \u00e5 trykke p\u00e5 \u00abFortsette\u00bb-knappen nedenfor godtar du ',
    authCheckoutStatement:
      'Sjekk ut med lagret kort, bruk ny betalingsm\u00e5te, eller bruk Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Sjekk ut med lagret kort, ',
    usePayByBank: ', eller bruk ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Bruk Pay By Bank',
    use: 'Bruk ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Bruk ny betalingsm\u00e5te',
    useNewPaymentDropdownText: 'bruk ny betalingsm\u00e5te',
    useNewPaymentTextBefore: 'Afrekenen met opgeslagen betaalmethode of ',
    useNewPaymentLinkText: 'bruk ny betalingsm\u00e5te',
    useNewPaymentTextBeforePayByBank:
      'Afrekenen met opgeslagen betaalmethode, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Du har for \u00f8yeblikket ingen betalingsmetoder i lommeboken din. Begynn ved \u00e5 legge til den f\u00f8rste betalingsmetoden i lommeboken din.',
    addPaymentMethod: 'Legg til betalingsm\u00e5te',
    makeDefault: 'Angi som standard',
    changeDefaultPMSuccess: 'Standard betalingsmetode er endret.',
    makeDefaultSuccessPM: 'Betalingsmetode lagt til som standard.',
    makeDefaultErrorPM: 'Feil ved \u00e5 sette betalingsmetode som standard.',
    removePMSuccess: 'Betalingsmetode fjernet fra Ford.com-lommebok',
    removePMError: 'Feil ved fjerning av betalingsmetode.',
    removePM: 'Fjern betalingsmetode',
    removePMQuestion: 'Fjerne betalingsmetode?',
    removePMText:
      'Er du sikker p\u00e5 at du vil fjerne betalingsmetode {{cardNumber}} fra lommeboken din? Alle aktive abonnementer knyttet til denne betalingsm\u00e5ten vil mislykkes hvis betalingsm\u00e5ten fjernes.',
    defaultCard: 'Standardkort',
    defaultPM: 'Standard betalingsmetode',
    defaultCardQuestion: 'Standardkort?',
    defaultPMQuestion: 'Standard betalingsmetode?',
    defaultCardText:
      'Er du sikker p\u00e5 at du vil endre standardkortet til {{cardNumber}}?',
    defaultPMText:
      'Er du sikker p\u00e5 at du vil endre standard betalingsmetoden din til {{cardNumber}}?',
    navigationLabel: 'Navigasjon for kontoadministrasjon',
    removeDefaultPMSuccess:
      'Standard betalingsmetode fjernet fra Ford.com-lommebok',
    removeDefaultCardError:
      'Det oppstod en feil under fjernng av standardkort.',
    removeDefaultPMError: 'Feil ved fjerning av standard betalingsmetode.',
    removeDefaultCard: 'Fjern standardkortet ditt',
    removeDefaultPM: 'Fjerne din standard betalingsmetode?',
    removeDefaultPMText:
      'Betalingsmetode {{cardNumber}} er din standard betalingsmetode. Velg en ny standard betalingsmetode nedenfor.\u00a0Alle aktive abonnementer knyttet til denne betalingsm\u00e5ten vil mislykkes hvis betalingsm\u00e5ten fjernes.',
    removeDefaultPMButton: 'Fjern betalingsmetode og oppdater standard',
    removeDefaultSelectPM: 'Velg ny standard betalingsmetode',
    title: 'Lommebok',
    expiration: 'Utl\u00f8per\u00a0{{date}}',
    expires: 'Utl\u00f8per {{date}}',
    stripeDefaultError:
      'Det oppstod et teknisk problem. Pr\u00f8v p\u00e5\u00a0nytt.\u00a0',
    paymentMethod: 'Betalingsm\u00e5te',
    tosTextBefore:
      'Ved \u00e5 klikke p\u00e5 knappen \u00abLagre kort\u00bb nedenfor godtar du ',
    tosTextBeforePM:
      "Ved \u00e5 klikke p\u00e5 knappen 'Lagre betalingsmetode' nedenfor, godtar du",
    tosLinkText:
      'betalingsvilk\u00e5rene og -betingelsene, samt personvernserkl\u00e6ringen',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alle',
    amount: 'Bel\u00f8p',
    date: 'Dato',
    description: 'Beskrivelse',
    displayingAll: 'Viser alle transaksjoner.',
    displayingDays:
      'Viser transaksjoner fra de siste {{numberOfDays}}\u00a0dagene.',
    displayingYear: 'Viser transaksjoner fra {{year}}.',
    filter: 'Siste {{numberOfDays}}\u00a0dager',
    loadAll: 'Last inn alt',
    loadMore: 'Last inn {{x}} til',
    noTransactions: 'Ingen transaksjoner \u00e5 vise.',
    paymentMethod: 'Betalingsm\u00e5te',
    refund: 'Refusjon',
    searchBar: 'S\u00f8k ut fra beskrivelse',
    timeFilter: 'Velg tidsramme for transaksjonshistorikk',
    title: 'Transaksjoner',
    pending: 'Avventer'
  },
  mit: {
    confirmPurchase: 'Bekreft kj\u00f8p',
    paymentSuccessful: 'Vellykket betaling',
    saveCardAlert:
      'For \u00e5\u00a0bruke dette kortet til fremtidige betalinger m\u00e5 det lagres i Ford.com-lommeboken.',
    title: 'Valg av betalingsm\u00e5te',
    tosTextBefore:
      'Ved \u00e5 klikke p\u00e5 knappen {{paymentButtonText}} nedenfor godtar du ',
    tosLinkText:
      'betalingsvilk\u00e5rene og -betingelsene, samt personvernserkl\u00e6ringen',
    tosTextAfter: '.',
    useNewCard: 'Bruk nytt kort',
    useNewCardDropDownItem: 'Bruk nytt kort',
    useNewCardTextBefore: 'Velg lagret kort eller ',
    useNewCardLinkText: 'bruk nytt kort',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Bruk ny betalingsm\u00e5te',
    useNewPaymentDropdownText: 'bruk ny betalingsm\u00e5te',
    useNewPaymentTextBefore: 'Afrekenen met opgeslagen betaalmethode of ',
    useNewPaymentLinkText: 'bruk ny betalingsm\u00e5te',
    successful: 'Vellykket',
    savePaymentMethodAlert:
      'Om deze betaalmethode voor toekomstige betalingen te gebruiken, moet deze in uw portemonnee worden opgeslagen.'
  }
};
