import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret
} from '@stripe/stripe-js';
import {
  Button,
  buttonContinue,
  paymentButtonProcessing,
  StripeElementErrorDisplay,
  translationKeys
} from '@fpc/common';
import { cardElementStyle, STRIPE_APPEARANCE } from '@fpc/common/Styles';
import useStripePaymentElements from '@fpc/api/stripe/UseStripePaymentElements';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import i18next from '@fpc/common/i18n';
import { isNotProd } from '../../flags';
import { TermsAndConditions } from '@fpc/common/components/TermsAndConditions';
import {
  ACCOUNT_ID_PARAM,
  PAY_BY_BANK_METHOD,
  PAYMENT_TYPE_PARAM
} from '../index';
import { getFirstUrlQueryDelimiter } from '@fpc/utils/urlQueryDelimiter';
import { CanCloseButton } from '@fpc/common/components/CanCloseButton';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';

interface PayByBankProps {
  paymentIntentClientSecret: string;
  stripeConnect: Stripe;
  isReady: (value: boolean) => void;
  setIsOpen?: (value: boolean) => void;
  fordCustomerId: string;
}

export function PayByBank(props: PayByBankProps) {
  const {
    transaction,
    redirectUrl,
    errorDispatch,
    redirectStatus,
    paymentMethodDisplayOrder
  } = useContext(StripeCheckoutContext);
  const canClose = props.setIsOpen !== undefined;
  const [isSubmitProcessing, setSubmitProcessing] = useState(false);
  const [paymentMethodType, setPaymentMethodType] = useState('');
  const [message, setMessage] = useState('');
  const options: StripeElementsOptionsClientSecret = {
    clientSecret: props.paymentIntentClientSecret,
    appearance: STRIPE_APPEARANCE
  };
  const [elements] = useState<StripeElements>(
    props.stripeConnect.elements(options)
  );
  const { mountPaymentElements, isPaymentMounted, isFormComplete } =
    useStripePaymentElements(
      props.stripeConnect,
      options,
      elements,
      errorDispatch,
      paymentMethodDisplayOrder
    );

  useEffect(() => {
    mountPaymentElements('#pay-by-bank-payment-element', (event) => {
      setPaymentMethodType(event.value.type);
    });
  }, []);

  useEffect(() => {
    if (redirectStatus === 'failed') {
      setMessage(
        i18next.t<string>(translationKeys.checkout.redirectStatusFailure)
      );
    }
  }, []);

  useEffect(() => {
    if (isPaymentMounted) {
      props.isReady(true);
    }
  }, [isPaymentMounted, props]);

  const queryDelimiter = getFirstUrlQueryDelimiter(redirectUrl);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitProcessing(true);
    setMessage('');

    const stripePaymentMethod = await props.stripeConnect.createPaymentMethod({
      elements: elements
    });

    const { error } = await props.stripeConnect
      .confirmPayment({
        clientSecret: props.paymentIntentClientSecret,
        confirmParams: {
          payment_method: stripePaymentMethod.paymentMethod?.id,
          return_url:
            redirectUrl +
            `${queryDelimiter}${ACCOUNT_ID_PARAM}=${
              (transaction as StripeTransactionDetails).merchantAccountId
            }` +
            `&${PAYMENT_TYPE_PARAM}=${PAY_BY_BANK_METHOD}`
        }
      })
      .catch((err) => {
        if (isNotProd()) {
          console.warn(err);
        }
        return {
          error: {
            message: i18next.t<string>(
              translationKeys.common.technicalErrorPayment
            )
          }
        };
      });
    if (isNotProd()) {
      console.warn(error);
    }
    setMessage(error?.message ?? '');
    setSubmitProcessing(false);
  };

  function handleClose() {
    if (canClose) {
      props.setIsOpen!(false);
    }
  }

  return (
    <form
      hidden={!isPaymentMounted}
      onSubmit={handleSubmit}
      data-testid="pay-by-bank-container"
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h2 className={'new-payment-method'}>
          {i18next.t<string>(translationKeys.checkout.payByBankHeader)}
        </h2>
        <span style={{ flexGrow: '1' }} />
        {canClose && <CanCloseButton handleClose={handleClose} />}
      </div>
      <div
        id="pay-by-bank-payment-element"
        style={cardElementStyle}
        data-testid={'pay-by-bank-payment-element'}
      >
        {/*Payment Element gets injected here.*/}
      </div>
      <TermsAndConditions
        translationGroup={translationKeys.checkout}
        paymentMethod={paymentMethodType}
      />
      <StripeElementErrorDisplay message={message} />
      <Button
        disabled={!isFormComplete || isSubmitProcessing}
        id="submit"
        style={{ marginTop: '0.5em' }}
      >
        {isSubmitProcessing
          ? paymentButtonProcessing()
          : buttonContinue(transaction.amount, transaction.currency)}
      </Button>
    </form>
  );
}
