import { statusReject } from '../statusReject';
import {
  parseTransactionDetails,
  parseTransactionDetailsForMultiMerchant
} from './HandleTransactionDetails';
import {
  StripeTransactionDetails,
  TransactionDetails
} from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from 'checkout/features/multi-merchant/types/PaymentInfoToken';
import { HeadersInitBuilder } from '@fpc/utils/headerBuilder';

export function decodePaymentInfoToken(
  paymentInfoToken: string,
  paymentInfoType: string,
  bearerToken?: string,
  bffBaseUrl?: string | null,
  isPreAuth?: boolean
): Promise<
  | TransactionDetails
  | StripeTransactionDetails
  | MultiMerchantStripeTransactionDetails
> {
  let targetServer, authHeader;
  if (bffBaseUrl) {
    targetServer = `${bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}`;
    authHeader = '';
  } else {
    targetServer = process.env.PAYMENT_APP_SERVER;
    authHeader = 'Bearer ' + bearerToken;
  }
  return fetch(`${targetServer}/api/payment-info-decode`, {
    method: 'POST',
    headers: {
      ...HeadersInitBuilder(),
      Authorization: `${authHeader}`
    },
    body: JSON.stringify({
      paymentInfoData: paymentInfoToken
    })
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          // Check whether the returned data is a v2 Payment Info Token that supports multi-merchant flows
          if (data.version) {
            return parseTransactionDetailsForMultiMerchant(data);
          }
          return parseTransactionDetails(data, isPreAuth);
        });
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      console.warn('Error decoding Payment Info Token: ', err);
      return Promise.reject(err);
    });
}
