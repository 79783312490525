export default {
  'errors.code.expired_card': 'Platnost vaší karty vypršela.',
  'errors.code.incorrect_cvc': 'Bezpečnostní kód vaší karty je nesprávný.',
  'errors.code.incorrect_number': 'Číslo vaší karty je nesprávné.',
  'errors.code.incorrect_zip':
    'Číslo vaší karty a poštovní směrovací číslo se neshodují.',
  'errors.code.invalid_cvc': 'Bezpečnostní kód vaší karty je neplatný.',
  'errors.code.invalid_expiry_month':
    'Datum konce platnosti Vaší karty je neplatné.',
  'errors.code.invalid_expiry_month_past':
    'Datum konce platnosti vaší karty je v minulosti.',
  'errors.code.invalid_expiry_year':
    'Rok konce platnosti vaší karty je neplatný.',
  'errors.code.invalid_expiry_year_past':
    'Rok konce platnosti vaší karty je v minulosti.',
  'errors.code.invalid_number': 'Číslo Vaší karty je neplatné.',
  'errors.code.processing_error':
    'Při zpracování Vaší karty došlo k chybě. Zkuste to za chvilku znovu.',
  'errors.declines.call_issuer':
    'Vaše karta byla odmítnuta. Podrobnosti si můžete vyžádat od své banky.',
  'errors.declines.card_not_supported': 'Vaše karta není podporována.',
  'errors.declines.card_velocity_exceeded':
    'Vaše karta byla odmítnuta pro příliš často opakované pokusy.',
  'errors.declines.currency_not_supported':
    'Vaše karta není podporována pro tuto měnu.',
  'errors.declines.generic_decline': 'Vaše karta byla odmítnuta.',
  'errors.declines.incorrect_pin': 'Nesprávné PIN.',
  'errors.declines.insufficient_funds':
    'Vaše karta nedisponuje dostatečnými prostředky.',
  'errors.declines.invalid_account': 'Neplatný účet.',
  'errors.declines.invalid_amount': 'Neplatná částka.',
  'errors.declines.not_permitted': 'Člen s kartou neregistrovaný / nepovolený',
  'errors.declines.pin_try_exceeded':
    'Překročen přípustný počet pokusů o zadání čísla PIN.',
  'errors.declines.transaction_not_allowed':
    'Vaše karta nepodporuje tento druh nákupu.',
  'errors.code.processing_error_intransient':
    'Něco se pokazilo. Zkuste to prosím znovu.',
  'errors.declines.generic_decline_link':
    'Vaše platební metoda byla zamítnuta.',
  'errors.declines.generic_decline_non_cards': 'Vaše platba byla zamítnuta.'
};
