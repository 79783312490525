import { enUS, TRANSLATION_MISSING } from './en-US';

export const ptPT: typeof enUS = {
  common: {
    default: 'predefinido',
    endingIn: 'a terminar em',
    close: 'Encerrar',
    makeDefaultCard: 'Tornar cart\u00e3o predefinido',
    makeDefaultInfoHover:
      'Este tornar-se-\u00e1 automaticamente o seu cart\u00e3o predefinido, porque \u00e9 o primeiro cart\u00e3o na sua carteira.',
    processing: 'Em processamento...',
    saveCardCheck: 'Guardar cart\u00e3o para transa\u00e7\u00f5es futuras',
    saveCardSuccess: 'Cart\u00e3o adicionado \u00e0 carteira Ford.com',
    technicalErrorPayment:
      'Lamentamos! Houve um problema t\u00e9cnico e o seu pagamento n\u00e3o foi processado. Tentar novamente.',
    timeoutSorry: 'Lamentamos...',
    timeoutError: 'Neste momento, estamos com dificuldades t\u00e9cnicas.',
    timeoutTryAgain: 'Tentar novamente',
    savePaymentMethodCheck:
      'Guardar m\u00e9todo de pagamento para transa\u00e7\u00f5es futuras',
    makeDefaultPaymentMethod: 'Tornar m\u00e9todo de pagamento predefinido',
    savePaymentMethodButton: 'Salvar forma de pagamento',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Este se tornar\u00e1 automaticamente seu m\u00e9todo de pagamento padr\u00e3o, pois \u00e9 o primeiro m\u00e9todo de pagamento em sua carteira.',
    savePaymentMethodSuccess:
      'A forma de pagamento foi adicionada \u00e0 Carteira.'
  },
  checkout: {
    payButton: 'Pague {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Lamentamos! Tivemos um problema t\u00e9cnico, e o seu pagamento n\u00e3o foi processado. Atualizar a p\u00e1gina e tentar novamente. ',
    technicalErrorPaymentDetachFail:
      'Lamentamos! Houve um problema t\u00e9cnico e o seu pagamento n\u00e3o foi processado. O seu cart\u00e3o foi guardado na carteira. Tentar novamente.',
    tosTextBefore: 'Ao clicar no bot\u00e3o "Pagar" abaixo, concorda com os ',
    tosLinkText:
      'Termos e Condi\u00e7\u00f5es de Pagamento e Pol\u00edtica de Privacidade',
    tosTextAfter: '.',
    useNewCard: 'Usar novo cart\u00e3o',
    useNewCardDropdownText: 'Adicionar novo cart\u00e3o',
    useNewCardTextBefore: 'Finalizar a compra com o cart\u00e3o guardado ou ',
    useNewCardLinkText: 'usar novo m\u00e9todo de pagamento',
    useNewCardTextAfter: '.',
    cardSelect: 'Selecionar cart\u00e3o de cr\u00e9dito',
    checkoutFaster: 'Finalizar a compra mais rapidamente com o FordPay.',
    checkout: 'Finalizar a compra',
    nameOnCard: 'Nome no cart\u00e3o',
    fullName: 'Nome completo',
    cardNumber: 'N\u00famero do cart\u00e3o',
    expirationDate: 'Data de Validade',
    securityCode: 'C\u00f3digo de seguran\u00e7a',
    invalidCCNMessage:
      'O n\u00famero do cart\u00e3o n\u00e3o \u00e9 v\u00e1lido.',
    invalidCVVMessage:
      'O c\u00f3digo de seguran\u00e7a n\u00e3o \u00e9 v\u00e1lido.',
    invalidExpDateMessage: 'A data de validade n\u00e3o \u00e9 v\u00e1lida.',
    emptyCCNMessage: 'O n\u00famero de cart\u00e3o est\u00e1 vazio.',
    emptyCVVMessage: 'O c\u00f3digo de seguran\u00e7a est\u00e1 vazio.',
    emptyExpDateMessage: 'A data de validade est\u00e1 vazia.',
    unsupportedCardMessage:
      'O n\u00famero do cart\u00e3o n\u00e3o \u00e9 suportado. Insira VISA, MasterCard ou AMEX.',
    paymentNotProcessed: 'N\u00e3o foi poss\u00edvel processar o pagamento',
    redirectStatusFailure:
      'N\u00e3o foi poss\u00edvel processar o pagamento. Tentar novamente.\u00a0',
    continue: 'Continuar {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Ao clicar no bot\u00e3o "Continuar" abaixo, concorda com os ',
    authCheckoutStatement:
      'Finalize com cart\u00e3o salvo, use nova forma de pagamento ou use Pagar por Banco.',
    useNewCardTextBeforePayByBank: 'Finalize com cart\u00e3o salvo, ',
    usePayByBank: ' ou use ',
    payByBankLinkText: 'Pagar por Banco',
    payByBankHeader: 'Use Pagar por Banco',
    use: 'Usar ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Usar novo m\u00e9todo de pagamento',
    useNewPaymentDropdownText: 'usar novo m\u00e9todo de pagamento',
    useNewPaymentTextBefore: 'Finalize com forma de pagamento salva ou ',
    useNewPaymentLinkText: 'usar novo m\u00e9todo de pagamento',
    useNewPaymentTextBeforePayByBank: 'Finalize com forma de pagamento salva, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Atualmente n\u00e3o tem nenhum m\u00e9todo de pagamento na sua carteira. Comece por adicionar o primeiro m\u00e9todo de pagamento \u00e0 sua carteira.',
    addPaymentMethod: 'Adicionar m\u00e9todo de pagamento',
    makeDefault: 'Tornar predefinido',
    changeDefaultPMSuccess:
      'O seu m\u00e9todo de pagamento predefinido foi alterado.',
    makeDefaultSuccessPM:
      'M\u00e9todo de pagamento adicionado como padr\u00e3o.',
    makeDefaultErrorPM:
      'Erro ao definir o m\u00e9todo de pagamento como padr\u00e3o.',
    removePMSuccess:
      'Erro ao definir o m\u00e9todo de pagamento como padr\u00e3o.',
    removePMError: 'Erro ao remover o m\u00e9todo de pagamento.',
    removePM: 'Remover m\u00e9todo de pagamento',
    removePMQuestion: 'Remover m\u00e9todo de pagamento?',
    removePMText:
      'Tem certeza de que deseja remover o m\u00e9todo de pagamento {{cardNumber}} da sua carteira?\u00a0Todas as assinaturas ativas associadas a esta forma de pagamento falhar\u00e3o se a forma de pagamento for removida.',
    defaultCard: 'Cart\u00e3o predefinido',
    defaultPM: 'M\u00e9todo de pagamento padr\u00e3o',
    defaultCardQuestion: 'Cart\u00e3o predefinido?',
    defaultPMQuestion: 'M\u00e9todo de pagamento padr\u00e3o?',
    defaultCardText:
      'Tem a certeza que quer alterar o seu cart\u00e3o predefinido para {{cardNumber}}?',
    defaultPMText:
      'Tem certeza de que deseja alterar o seu m\u00e9todo de pagamento padr\u00e3o para {{cardNumber}}?',
    navigationLabel: 'Navega\u00e7\u00e3o da gest\u00e3o de contas',
    removeDefaultPMSuccess:
      'M\u00e9todo de pagamento padr\u00e3o removido da carteira Ford.com',
    removeDefaultCardError: 'Erro ao eliminar o cart\u00e3o predefinido.',
    removeDefaultPMError:
      'Erro ao remover o m\u00e9todo de pagamento padr\u00e3o.',
    removeDefaultCard: 'Eliminar o seu cart\u00e3o predefinido',
    removeDefaultPM: 'Remover seu m\u00e9todo de pagamento padr\u00e3o?',
    removeDefaultPMText:
      'O m\u00e9todo de pagamento {{cardNumber}} \u00e9 o seu m\u00e9todo de pagamento padr\u00e3o. Escolha um novo m\u00e9todo de pagamento padr\u00e3o abaixo. Todas as assinaturas ativas associadas a esta forma de pagamento falhar\u00e3o se a forma de pagamento for removida.',
    removeDefaultPMButton:
      'Remover m\u00e9todo de pagamento e atualizar padr\u00e3o',
    removeDefaultSelectPM:
      'Selecionar novo m\u00e9todo de pagamento padr\u00e3o',
    title: 'Carteira',
    expiration: 'Validade\u00a0{{date}}',
    expires: 'Expira em {{date}}',
    stripeDefaultError:
      'Houve um problema t\u00e9cnico. Tentar novamente.\u00a0',
    paymentMethod: 'M\u00e9todo de pagamento',
    tosTextBefore:
      'Ao clicar no bot\u00e3o "Guardar cart\u00e3o" abaixo, concorda com os ',
    tosTextBeforePM:
      "Ao clicar no bot\u00e3o 'Salvar m\u00e9todo de pagamento' abaixo, voc\u00ea concorda com",
    tosLinkText:
      '\nTermos e Condi\u00e7\u00f5es de Pagamento e Pol\u00edtica de Privacidade',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Todas',
    amount: 'Valor',
    date: 'Data',
    description: 'Descri\u00e7\u00e3o',
    displayingAll: 'Exibir todas as transa\u00e7\u00f5es.',
    displayingDays:
      'Exibir transa\u00e7\u00f5es dos \u00faltimos {{numberOfDays}} dias.',
    displayingYear: 'Exibir transa\u00e7\u00f5es de {{year}}.',
    filter: '\u00daltimos {{numberOfDays}} dias',
    loadAll: 'Carregar todos',
    loadMore: 'Carregar mais {{x}}',
    noTransactions: 'N\u00e3o h\u00e1 transa\u00e7\u00f5es a exibir.',
    paymentMethod: 'M\u00e9todo de pagamento',
    refund: 'Reembolsar',
    searchBar: 'Pesquisar por descri\u00e7\u00e3o',
    timeFilter:
      'Selecione o per\u00edodo de tempo para o hist\u00f3rico de transa\u00e7\u00f5es',
    title: 'Transa\u00e7\u00f5es',
    pending: 'Pendente'
  },
  mit: {
    confirmPurchase: 'Confirmar compra',
    paymentSuccessful: 'Pagamento bem-sucedido',
    saveCardAlert:
      'Para usar este cart\u00e3o para pagamentos futuros, este deve ser guardado na sua carteira em Ford.com.',
    title: 'Sele\u00e7\u00e3o do m\u00e9todo de pagamento',
    tosTextBefore:
      'Ao clicar no bot\u00e3o {{paymentButtonText}} abaixo, concorda com os ',
    tosLinkText:
      'Termos e Condi\u00e7\u00f5es de Pagamento e Pol\u00edtica de Privacidade',
    tosTextAfter: '.',
    useNewCard: 'Usar novo cart\u00e3o',
    useNewCardDropDownItem: 'Usar novo cart\u00e3o',
    useNewCardTextBefore: 'Selecionar cart\u00e3o guardado ou ',
    useNewCardLinkText: 'usar novo cart\u00e3o',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Usar novo m\u00e9todo de pagamento',
    useNewPaymentDropdownText: 'usar novo m\u00e9todo de pagamento',
    useNewPaymentTextBefore: 'Finalize com forma de pagamento salva ou ',
    useNewPaymentLinkText: 'usar novo m\u00e9todo de pagamento',
    successful: 'Bem-sucedido',
    savePaymentMethodAlert:
      'Para utilizar este m\u00e9todo de pagamento em pagamentos futuros, ele deve ser salvo em sua carteira.'
  }
};
