import {
  makePayuPayment,
  PayuPaymentRequest,
  PayuPaymentResponse
} from '@fpc/api/paymentapp/MakePayuPayment';

export async function makePayment(
  request: PayuPaymentRequest,
  bearerToken: string
): Promise<PayuPaymentResponse | any> {
  return makePayuPayment(request, bearerToken).catch((ex) => {
    return Promise.reject(ex);
  });
}
