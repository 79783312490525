import { Style } from '@fpc/common/Styles';

export function JPMorganDisclaimerGB() {
  return (
    <div data-testid="pay-by-bank-disclaimer" style={{ textAlign: 'left' }}>
      <p>
        JPMorgan Chase Bank, N.A. London Branch (JPMorgan) is authorised by the
        Prudential Regulation Authority and subject to regulation by the
        Financial Conduct Authority and limited regulation by the Prudential
        Regulation Authority, and is authorised to provide payment initiation
        services. This means that you can use JPMorgan to make a secure payment
        directly from your bank account to Ford Retail Ltd. You must review and
        confirm the payment request below and then select your bank.
      </p>
      <p>
        Once you click “Continue" JPMorgan will send the payment request to your
        bank to complete this payment. You will be redirected to your bank to
        authenticate yourself and authorise the payment. You will not be able to
        cancel the payment order once it has been confirmed.
      </p>
      <p>
        Please read our{' '}
        <a
          href={
            'https://www.jpmorgan.com/content/dam/jpm/global/disclosures/us/payment-initiation-service-uk.pdf'
          }
          target="_blank"
          rel="noreferrer"
          style={{ color: Style.color.primary }}
        >
          Terms of Use
        </a>{' '}
        for further information.
      </p>
      <p>
        You can find out more about JPMorgan, including how to contact us or
        make a complaint, on our{' '}
        <a
          href={
            'https://www.jpmorgan.com/content/dam/jpm/global/disclosures/us/account-information-and-payment-initiation-service-uk-only.pdf'
          }
          target="_blank"
          rel="noreferrer"
          style={{ color: Style.color.primary }}
        >
          website
        </a>
        . Fuller details of how we use your personal data is set out in our{' '}
        <a
          href={
            'https://www.jpmorgan.com/content/dam/jpm/global/disclosures/us/account-information-and-payment-initiation-service-uk.pdf'
          }
          target="_blank"
          rel="noreferrer"
          style={{ color: Style.color.primary }}
        >
          Privacy Statement
        </a>
        .
      </p>
    </div>
  );
}
