import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElementConfirmEvent
} from '@stripe/stripe-js';
import { useContext } from 'react';
import {
  CHECKOUT_ELEMENT,
  StripeCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import {
  AuthPaymentRequest,
  makeAuthenticatedPayment
} from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import { buildManualRedirectUrl } from '@fpc/utils/buildManualRedirectUrl';
import {
  ExpressCheckoutButton,
  ExpressCheckoutOptions
} from '@fpc/common/components/ExpressCheckoutButton';
import { isLocal } from '../../flags';
import { PAYMENT_INITIATOR } from '@fpc/common';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';

interface AuthPaymentRequestProps {
  stripe: Stripe;
  elements: StripeElements;
  fordCustomerId: string;
  isReadyHandler: (isReady: boolean) => void;
}

export function AuthPaymentRequestButton(props: AuthPaymentRequestProps) {
  const {
    transaction,
    tokens,
    stripeCustomerId,
    isPreAuth,
    redirectUrl,
    errorDispatch,
    bffBaseUrl
  } = useContext(StripeCheckoutContext);

  const expressCheckoutOptions: ExpressCheckoutOptions = {
    amount: transaction.amount,
    currency: transaction.currency,
    googlePay: transaction.alternativePaymentMethodTypes.includes('GOOGLE_PAY'),
    applePay: transaction.alternativePaymentMethodTypes.includes('APPLE_PAY')
  };

  function handleErrorDispatch(err: any) {
    if (err.unrecoverable) {
      errorDispatch(ErrorCondition.Unrecoverable);
      dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
    }
    console.warn(
      'There was an error trying to make payment with wallet pay',
      err
    );
  }

  const onExpressPaymentCallback = async (
    event: StripeExpressCheckoutElementConfirmEvent,
    elements: StripeElements
  ): Promise<void> => {
    props.isReadyHandler(false);
    const { paymentMethod, error } = await props.stripe.createPaymentMethod({
      elements: elements
    });

    if (paymentMethod) {
      const authPaymentRequest: AuthPaymentRequest = {
        checkoutTokens: tokens,
        stripeCustomerId: stripeCustomerId!,
        paymentMethodId: paymentMethod.id,
        paymentMethodType: paymentMethod.type,
        isSavePaymentMethod: false,
        setPaymentMethodAsDefault: false,
        fordCustomerId: props.fordCustomerId,
        paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
        isPreAuth,
        bffBaseUrl: bffBaseUrl ?? ''
      };
      makeAuthenticatedPayment(authPaymentRequest)
        .then((confirmResult) => {
          window.location.href = buildManualRedirectUrl(
            redirectUrl,
            (transaction as StripeTransactionDetails).merchantAccountId,
            confirmResult.paymentIntentClientSecret,
            paymentMethod.type
          );
        })
        .catch((err) => {
          handleErrorDispatch(err);
          event.paymentFailed({ reason: 'fail' });
          props.isReadyHandler(true);
        });
    } else {
      if (isLocal) {
        console.warn(error);
      }
      event.paymentFailed({ reason: 'fail' });
      props.isReadyHandler(true);
    }
  };

  return (
    <ExpressCheckoutButton
      stripe={props.stripe}
      options={expressCheckoutOptions}
      onPaymentConfirmCallback={onExpressPaymentCallback}
      finishedLoadingHandler={props.isReadyHandler}
    />
  );
}
