export default {
  'errors.code.expired_card': 'La tarjeta ha caducado.',
  'errors.code.incorrect_cvc':
    'El código de seguridad de tu tarjeta es incorrecto.',
  'errors.code.incorrect_number': 'El número de tu tarjeta es incorrecto.',
  'errors.code.incorrect_zip':
    'El número de tu tarjeta no se corresponde con el código postal.',
  'errors.code.invalid_cvc':
    'El código de seguridad de tu tarjeta no es válido.',
  'errors.code.invalid_expiry_month':
    'La fecha de caducidad de tu tarjeta no es válida.',
  'errors.code.invalid_expiry_month_past':
    'La fecha de caducidad de tu tarjeta ya ha pasado.',
  'errors.code.invalid_expiry_year':
    'El año de caducidad de la tarjeta no es válido.',
  'errors.code.invalid_expiry_year_past':
    'El año de caducidad de la tarjeta ya ha pasado.',
  'errors.code.invalid_number': 'El número de tu tarjeta no es válido.',
  'errors.code.processing_error':
    'Ha ocurrido un error durante el procesamiento de la tarjeta. Vuelve a intentarlo en unos minutos.',
  'errors.declines.call_issuer':
    'Se ha rechazado tu tarjeta. Ponte en contacto con tu banco para conocer más detalles.',
  'errors.declines.card_not_supported': 'Tu tarjeta no se admite.',
  'errors.declines.card_velocity_exceeded':
    'Tu tarjeta ha sido denegada por intentos repetidos de compra con demasiada frecuencia.',
  'errors.declines.currency_not_supported':
    'No se acepta tu tarjeta para esta divisa.',
  'errors.declines.generic_decline': 'Tu tarjeta ha sido rechazada.',
  'errors.declines.incorrect_pin': 'PIN incorrecto.',
  'errors.declines.insufficient_funds':
    'Tu tarjeta no tiene fondos suficientes.',
  'errors.declines.invalid_account': 'Cuenta no válida.',
  'errors.declines.invalid_amount': 'Importe no válido.',
  'errors.declines.not_permitted':
    'Titular de tarjeta no inscrito o no permitido',
  'errors.declines.pin_try_exceeded':
    'Se ha sobrepasado el número permitido de intentos para introducir el PIN.',
  'errors.declines.transaction_not_allowed':
    'Tu tarjeta no admite este tipo de compra.',
  'errors.code.processing_error_intransient':
    'Algo salió mal. Vuelve a intentarlo más tarde.',
  'errors.declines.generic_decline_link': 'Se ha rechazado tu método de pago.',
  'errors.declines.generic_decline_non_cards': 'Se ha rechazado tu pago.'
};
