import { getIcon, translationKeys } from '../index';
import i18next from '@fpc/common/i18n';
import { ReactElement } from 'react';

const nbspCharCode = 160;

export function makeJSXFromPaymentMethod(
  paymentMethodType: string,
  isDefault: boolean,
  lastFourDigits: string,
  bankAccountName: string
): ReactElement {
  const Icon = getIcon(paymentMethodType);
  const defaultPaymentMethod = isDefault
    ? `(${i18next.t<string>(translationKeys.common.default)})`
    : '';
  return (
    <>
      <Icon style={{ height: '26px', width: '39.617px' }} />
      <span
        style={{
          paddingLeft: '8px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        data-testid="row-description"
      >
        {bankAccountName} {i18next.t<string>(translationKeys.common.endingIn)}
      </span>
      <span data-testid="last-four">
        {String.fromCharCode(nbspCharCode) + lastFourDigits}
      </span>
      <span data-testid="default-text" style={{ paddingLeft: '5px' }}>
        {defaultPaymentMethod}
      </span>
    </>
  );
}
